import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { withPermissions } from "../../../layout/Permissions";

const Contact = ({ userName, userEmail, sendEmail, validationMessage, resetMessage }) => {

    const [name, setName] = useState(userName ? userName : '')
    const [email, setEmail] = useState(userEmail ? userEmail : '')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const recaptchaRef = React.createRef();

    useEffect(() => {
        resetMessage();
    }, [resetMessage])

    const handleSubmit = (e) => {
        e.preventDefault();
        recaptchaRef.current.execute();
        const values = { name, email, subject, message }
        sendEmail(values)
    }

    return (
        <div className="container">
            <form className="form form-horizontal striped-rows"
                onSubmit={(e) => handleSubmit(e)}>
                <div className="form-body">
                    <div className="row">
                        <div className="form-group has-danger col-6">
                            <label htmlFor="name" className="label-control">Votre nom</label>
                            {/* <div className="pl-0"> */}
                                <input
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            {/* </div> */}
                        </div>
                        <div className="form-group has-danger col-6">
                            <label htmlFor="email" className="label-control">Votre adresse e-mail </label>
                            {/* <div className="pl-0"> */}
                                <input
                                    type="text"
                                    id="email"
                                    className="form-control"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="form-group has-danger">
                        <label htmlFor="subject" className="label-control">Objet</label>
                        {/* <div className="pl-0"> */}
                            <input
                                type="text"
                                id="subject"
                                className="form-control"
                                onChange={(e) => setSubject(e.target.value)}
                                value={subject}
                            />
                        {/* </div> */}
                    </div>
                    <div className="form-group has-danger">
                        <label htmlFor="message" className="label-control">Message</label>
                        {/* <div className="pl-0"> */}
                            <textarea
                                id="message"
                                className="form-control"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                            />
                        {/* </div> */}
                    </div>
                    <p>{validationMessage}</p>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LeRTl4dAAAAAEMF9wPxNAnRIjXGp6rqTo2q-TUX"
                    />
                    <div className="text-right">
                        <button type="submit" className="btn btn-primary">
                            Envoyer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )

};

export default withPermissions(Contact);