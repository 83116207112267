import React from "react";
import {Link} from "react-router-dom";
import {history} from "../../store";
import {
  MENU_BOTTOM, MENU_DARK, MENU_DEFAULT, MENU_LIGHT,
  MENU_TOP,
} from "../../utils/transformer";
import './Menu.scss';


const Menu = ({pathname, localConfigurator}) => {

  function onRedirect (path) {
    history.push(path);
  }

  function getMenuStyleClass () {
    if (localConfigurator && localConfigurator.theme && localConfigurator.theme.menuStyle) {
      switch (localConfigurator.theme.menuStyle) {
        case MENU_DARK:
          return 'menu-dark';
        case MENU_LIGHT:
          return 'menu-light';
        default:
          return MENU_DEFAULT;

      }

    }

    return MENU_DEFAULT;
  }

  return <div className={`main-menu menu-fixed menu-accordion menu-shadow ${getMenuStyleClass()}`}>
    <div className="main-menu-content">
      {localConfigurator && localConfigurator.logo && localConfigurator.theme.logoPosition === MENU_TOP && (
        <Link to="/" className="menu-navbar-brand"><img className="brand-logo img-fluid" src={localConfigurator.logo}
          alt={localConfigurator.title} /></Link>
      )}
      {localConfigurator && localConfigurator.secondaryLogo && localConfigurator.theme.secondaryLogoPosition === MENU_TOP && (
        <Link to="/" className="menu-navbar-brand"><img className="brand-logo img-fluid"
          src={localConfigurator.secondaryLogo}
          alt={localConfigurator.title} /></Link>
      )}
      <ul className="navigation">
        <li className="navigation-header"><span>Principal</span><i className=" feather icon-minus"
          data-toggle="tooltip" data-placement="right"
          data-original-title="General" />
        </li>
        <li className={`nav-item ${pathname === '/' ? 'active' : ''}`}><Link onClick={() => onRedirect('/')} to="/">
          <i className="icon-home" />
          <span className="menu-title">Accueil</span></Link>
        </li>
        <li className={`nav-item ${pathname === '/calculs' ? 'active' : ''}`}><Link to="/calculs">
          <i className="fa fa-folder-open-o" />
          <span className="menu-title">Mes calculs</span></Link>
        </li>
        {((localConfigurator.documentation?.about.menu && localConfigurator.documentation?.about.menu.isActive) ||
          (localConfigurator.documentation?.fdesMeres?.menu && localConfigurator.documentation?.fdesMeres.menu.isActive) ||
          (localConfigurator.documentation?.videoTutorial.menu && localConfigurator.documentation?.videoTutorial.menu.isActive) ||
          (localConfigurator.documentation?.guide.menu && localConfigurator.documentation?.guide.menu.isActive) ||
          (localConfigurator.documentation?.methodology.menu && localConfigurator.documentation?.methodology.menu.isActive)) &&
          <li className="navigation-header"><span>Documentation</span><i className=" feather icon-minus"
            data-toggle="tooltip" data-placement="right"
            data-original-title="Documentation" />
          </li>
        }
        {localConfigurator.documentation?.about.menu && localConfigurator.documentation?.about.menu.isActive ?
          <li className={`nav-item ${pathname === '/about' ? 'active' : ''}`}><Link to="/about">
            <i className="feather icon-info" />
            <span className="menu-title">{localConfigurator.documentation?.about.menu?.label ? localConfigurator.documentation?.about.menu?.label : "À propos"}</span></Link>
          </li>
          : <></>
        }
        {localConfigurator.documentation?.fdesMeres.menu && localConfigurator.documentation?.fdesMeres.menu.isActive ?
          <li className={`nav-item ${pathname === '/references' ? 'active' : ''}`}><Link to="/references">
            <i className="feather icon-file-text" />
            <span className="menu-title">{localConfigurator.documentation?.fdesMeres.menu?.label ? localConfigurator.documentation?.fdesMeres.menu?.label : "FDES mères"}</span></Link>
          </li>
          : <></>
        }
        {localConfigurator.documentation?.videoTutorial.menu && localConfigurator.documentation?.videoTutorial.menu.isActive ?
          <li className={`nav-item ${pathname === '/videos' ? 'active' : ''}`}><Link to="/videos">
            <i className="feather icon-play-circle" />
            <span className="menu-title">{localConfigurator.documentation?.videoTutorial.menu?.label ? localConfigurator.documentation?.videoTutorial.menu?.label : "Tutoriels"}</span></Link>
          </li>
          : <></>
        }
        {localConfigurator.documentation?.guide.menu && localConfigurator.documentation?.guide.menu.isActive ?
          <li className={`nav-item ${pathname === '/guides' ? 'active' : ''}`}><Link to="/guides">
            <i className="icon-book-open" />
            <span className="menu-title">{localConfigurator.documentation?.guide.menu?.label ? localConfigurator.documentation?.guide.menu?.label : "Guides"}</span></Link>
          </li>
          : <></>
        }
        {localConfigurator.documentation?.methodology.menu && localConfigurator.documentation?.methodology.menu.isActive ?
          <li className={`nav-item ${pathname === '/methods' ? 'active' : ''}`}><Link to="/methods">
            <i className="icon-notebook" />
            <span className="menu-title">{localConfigurator.documentation?.methodology.menu?.label ? localConfigurator.documentation?.methodology.menu?.label : "Méthodologie"}</span></Link>
          </li>
          : <></>
        }
        <li className="navigation-header"><span>Assistance</span><i className=" feather icon-minus"
          data-toggle="tooltip" data-placement="right"
          data-original-title="Assistance" />
        </li>
        <li className={`nav-item ${pathname === '/faq' ? 'active' : ''}`}><Link to="/faq">
          <i className="fa fa-question-circle-o" />
          <span className="menu-title">FAQ</span></Link>
        </li>
        {localConfigurator && localConfigurator.email && localConfigurator.email.adminContactEmail && (
          <li className={`nav-item ${pathname === '/contact' ? 'active' : ''}`}><Link to="/contact">
            <i className="fa fa-envelope-o" />
            <span className="menu-title">Contact</span></Link>
          </li>
        )}
      </ul>
      {localConfigurator && localConfigurator.logo && localConfigurator.theme.logoPosition === MENU_BOTTOM && (
        <Link to="/" className="menu-navbar-brand"><img className="brand-logo img-fluid" src={localConfigurator.logo}
          alt={localConfigurator.title} /></Link>
      )}
      {localConfigurator && localConfigurator.secondaryLogo && localConfigurator.theme.secondaryLogoPosition === MENU_BOTTOM && (
        <a href={localConfigurator.secondaryLogoUrl} target="_blank" rel="noopener noreferrer" className="menu-navbar-brand">
          <img className="brand-logo img-fluid" src={localConfigurator.secondaryLogo}
            alt={localConfigurator.title} /></a>
      )}
    </div>
  </div >
};

export default Menu;
