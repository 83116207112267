import {connect} from 'react-redux';
import FamilyProduct from '../components/FamilyProduct';
import {getFamilyProducts, deleteFamilyProduct} from "../actions";
import {getConfiguratorsSend} from '../../configurator/actions';

export default connect(
    (state) => ({
        familyProducts: state.familyProduct.familyProducts,
        configurator: state.configurator,
    }),
    (dispatch) => ({
        getFamilyProductsRequest: () => {
            dispatch(getFamilyProducts());
        },
        deleteFamilyProduct: (familyProductId) => {
            dispatch(deleteFamilyProduct(familyProductId));
        },
        getConfiguratorsRequest: () => {
            dispatch(getConfiguratorsSend())
        },
    })

)(FamilyProduct);