import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {Link} from 'react-router-dom';
import DeleteModal from "../../../common/components/DeleteModal";

const FamilyProductRaw = ({familyProduct, deleteFamilyProduct, isChild, paddingRem, defaultOpen}) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    function createMarkup () {
        return {__html: familyProduct && familyProduct.description ? familyProduct.description : ''};
    }
    return (
        <>
            <DeleteModal
                isOpen={deleteModalIsOpen}
                onClose={setDeleteModalIsOpen}
                onSubmit={() => deleteFamilyProduct(familyProduct._id)}
            />
            <tr className={!isChild ? "bg-blue-grey bg-lighten-4" : ""} onClick={() => setIsOpen(!isOpen)}>
                <td className={isChild ? `ml-1 pl-${paddingRem}` : "pl-1"}>{familyProduct.name}</td>
                <td dangerouslySetInnerHTML={createMarkup()} />
                <td className="table-logo">{familyProduct.image && (
                    <img className="img-fluid" alt={familyProduct.name} src={familyProduct.image} />)}</td>
                <td>
                    <div className="d-flex"><Link
                        to={`/family-product/create/${familyProduct._id}`}>
                        <Button className="mr-25" variant="info"><i className="feather icon-plus" /> </Button></Link>{' '}<Link
                            to={`/family-product/edit/${familyProduct._id}`}>
                            <Button className="mr-25" variant="success"><i className="feather icon-edit-1" /> </Button></Link>{' '}
                        <Button variant="danger" onClick={() => setDeleteModalIsOpen(true)}><i className="feather icon-trash-2" /></Button>
                    </div>
                </td>
                {familyProduct && familyProduct.children && familyProduct.children.length > 0 ?
                    <td className="group" colSpan={12}>{isOpen ? <i className="feather  icon-chevron-up mr-1" /> :
                        <i className="feather  icon-chevron-down mr-1" />}</td> : <td></td>
                }
            </tr>
            {
                isOpen && familyProduct && familyProduct.children && familyProduct.children.length > 0 && (
                    familyProduct.children.map(familyProductChild => <FamilyProductRaw key={familyProductChild._id + 'child'} familyProduct={familyProductChild} deleteFamilyProduct={deleteFamilyProduct} isChild paddingRem={paddingRem + 2} defaultOpen={isOpen} />)
                )
            }
        </>
    )

};

export default FamilyProductRaw;
