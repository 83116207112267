import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';
import DeleteModal from "../../../common/components/DeleteModal";

const ManageGroupRaw = ({ group, deleteGroupRequest, isChild }) => {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    return (
        <>
            <DeleteModal
                isOpen={deleteModalIsOpen}
                onClose={setDeleteModalIsOpen}
                onSubmit={() => deleteGroupRequest(group._id)}
            />
            <tr className={isChild ? "bg-blue-grey bg-lighten-4" : ""}>
                {isChild && (<td />)}
                <td colSpan={isChild ? 1 : 2}>{group.title}</td>
                <td />
                <td />
                {/* <td dangerouslySetInnerHTML={createMarkup()} />
                <td className="table-logo">{group.image && (
                    <img className="img-fluid" alt={group.name} src={group.image}/>)}</td> */}
                <td>
                    <div className="d-flex float-right">
                        <Link to={`/manage-group/edit/${group._id}`}>
                            <Button className="mr-25" variant="success" onClick={() => setDeleteModalIsOpen(false)}><i className="feather icon-edit-1" /> </Button></Link>{' '}
                        {!group.isDefault && <Button variant="danger" onClick={() => setDeleteModalIsOpen(true)}><i className="feather icon-trash-2" /></Button>}
                    </div>
                </td>
            </tr>
        </>
    )

};

export default ManageGroupRaw;
