import {createSelector} from 'reselect';
import {flattenFamilyProducts} from "../../utils/transformer";

const getLocalConfigurator = (state) => state.configurator.localConfigurator;
const getFamilyProducts = (state) => state.familyProduct.familyProducts;

export const getGuideDownloadLinks = createSelector(getLocalConfigurator, getFamilyProducts, (localConfigurator, familyProducts) => {

    let links = [];
    const familyProductAvailableIds = localConfigurator.productFamilies ? localConfigurator.productFamilies.map(x => x.id) : [];
    if(localConfigurator && localConfigurator.documentation && localConfigurator.documentation.guide && localConfigurator.documentation.guide.file){
        links.push({name:localConfigurator.documentation.guide.label || 'main', file:localConfigurator.documentation.guide.file})
    }
    if(familyProducts && familyProducts.length > 0){
        flattenFamilyProducts(familyProducts).map(familyProduct =>{

            if(familyProductAvailableIds.includes(familyProduct._id) && familyProduct.documentation && familyProduct.documentation.guide && familyProduct.documentation.guide.file !== ''){
                links.push({name:familyProduct.documentation.guide.label || familyProduct.name, file:familyProduct.documentation.guide.file})
            }
            return undefined;
        })
    }
    return links;
});


export const getMethodologyDownloadLinks = createSelector(getLocalConfigurator, getFamilyProducts, (localConfigurator, familyProducts) => {

    let links = [];
    const familyProductAvailableIds = localConfigurator.productFamilies ? localConfigurator.productFamilies.map(x => x.id) : [];
    if(localConfigurator && localConfigurator.documentation && localConfigurator.documentation.methodology && localConfigurator.documentation.methodology.file){
        links.push({name:localConfigurator.documentation.methodology.label || 'main', file:localConfigurator.documentation.methodology.file})
    }
    if(familyProducts && familyProducts.length > 0){
        flattenFamilyProducts(familyProducts).map(familyProduct =>{

            if(familyProductAvailableIds.includes(familyProduct._id) && familyProduct.documentation && familyProduct.documentation.tutorial && familyProduct.documentation.tutorial.file !== ''){
                links.push({name:familyProduct.documentation.tutorial.label || familyProduct.name, file:familyProduct.documentation.tutorial.file})
            }
            return undefined;
        })
    }
    return links;
});

export const getVideoTutorialsLinks = createSelector(getLocalConfigurator, getFamilyProducts, (localConfigurator, familyProducts) => {

    let links = [];
    const familyProductAvailableIds = localConfigurator.productFamilies ? localConfigurator.productFamilies.map(x => x.id) : [];

    if(familyProducts && familyProducts.length > 0){
        flattenFamilyProducts(familyProducts).map(familyProduct => {

            if(familyProductAvailableIds.includes(familyProduct._id) && familyProduct.documentation && familyProduct.documentation.videoTutorial && familyProduct.documentation.videoTutorial.video !== ''){
                links.push(familyProduct.documentation.videoTutorial)
            }
            return undefined;
        })
    }
    return links;
});
