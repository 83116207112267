import React, {useEffect, useState} from 'react';
import FormLogin from "./FormLogin";
import SendRecoverPasswordForm from "./SendRecoverPasswordForm";
import './Login.scss';
import Loader from './Loader';

const Login = ({isOpen, error, onClose, sendFormLogin, sendRecoverPasswordForm, loginAttempt, resetLoginAttempt, resentValidation, localConfigurator, sendRecoverPasswordFormIsLoading}) => {
  const [recoverPasswordShow, setRecoverPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRecoverPasswordShow(false);
    resetLoginAttempt();
  }, [isOpen, resetLoginAttempt]);

  useEffect(() => {
    setIsLoading(false);
    return setIsLoading(false);
  }, [error, isOpen])

  const handleSendFormLogin = (values) => {
    setIsLoading(true);
    sendFormLogin(values);
  }

  return (
    isOpen ? (<>
      <div className="modal-container">
        <Loader component='Login' isActive={isLoading === true || sendRecoverPasswordFormIsLoading === true} />
        <div className="background" onClick={() => onClose()} />
        <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
          <div className="card border-grey border-lighten-3 m-0">
            <div className="card-header border-0">
              <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                <span>{!recoverPasswordShow ? 'Connexion' : 'Réinitialiser le mot de passe'}</span></h6>
            </div>
            <div className="card-content">
              <div className="card-body">
                {!localConfigurator.auth || (localConfigurator.auth && localConfigurator.auth.find(o => o.strategy === "local")) ?
                  !recoverPasswordShow ?
                    <FormLogin error={error} sendFormLogin={handleSendFormLogin}
                      localConfigurator={localConfigurator}
                      loginAttempt={loginAttempt} resentValidation={resentValidation} />
                    : <SendRecoverPasswordForm sendRecoverPasswordForm={sendRecoverPasswordForm} /> : <></>
                }
                {!recoverPasswordShow && (!localConfigurator.auth || (localConfigurator.auth && localConfigurator.auth.find(o => o.strategy === "local"))) ?
                  <p className="float-left">
                    <button type="button" onClick={() => setRecoverPasswordShow(true)}
                      className="btn btn-outline-secondary btn-sm">Mot de passe
                      oublié
                    </button>
                  </p>
                  : localConfigurator.auth && localConfigurator.auth.find(o => o.strategy === "local") ?
                    <p className="float-left">
                      <button type="button" onClick={() => setRecoverPasswordShow(false)} className="btn btn-outline-secondary btn-sm">
                        Se connecter
                      </button>
                    </p> : <></>
                }
              </div>
            </div>
            <div className="card-footer">
              <div className="float-sm-left text-center m-0">
                {
                  localConfigurator.auth && localConfigurator.auth.find(o => o.strategy === "saml") ?
                    <a className="nav-link nav-menu-main btn btn-outline-primary"
                      href={localConfigurator.auth.find(o => o.strategy === "saml").loginUrl}>{localConfigurator.auth.find(o => o.strategy === "saml").label} </a> : <></>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>) : <></>
  );
};

export default Login;
