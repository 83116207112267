import React from 'react';
import * as Yup from "yup";
import {Formik} from "formik";
import {Link} from "react-router-dom";
import Loader from "../../../common/components/Loader";
import {fileNameStrategyKeyToLabel, fdesTemplateType} from "../../../utils/transformer";

const ModelForm = ({action, model, saveModelForm, updateModelForm, familyProductId, modelFormIsLoading}) => {

  function onChangeFile (event, setFieldValue, fieldName) {
    setFieldValue(fieldName, event.target.files[0]);
  }

  return (
    <div className="card">
      <div className="card-content collapse show">
        <div className="card-body">
          <Loader isActive={modelFormIsLoading} />
          <Formik
            enableReinitialize
            initialValues={{
              id: model && model._id ? model._id : '',
              name: model && model.title ? model.title : '',
              description: model && model.description ? model.description : '',
              icon: '',
              paramForm: '',
              calculForm: '',
              calculFormUri: '',
              methodologyNote: '',
              standard: model && model.standard ? model.standard : 'NF_EN_15804-A1',
              reportReference: model && model.reportReference ? model.reportReference : '',
              oldReportReference: model && model.reportReference ? model.reportReference : '',
              changelog: model && model.changelog ? model.changelog : '',
              familyProductId: familyProductId,
              version: model && model.version ? model.version : '',
              parentEPDId: model && model.parentEPDId ? model.parentEPDId : '',
              parentEPDSerialId: model && model.parentEPDSerialId ? model.parentEPDSerialId : '',
              googleDriveSheetId: model && model.calculus && model.calculus.googleDrive && model.calculus.googleDrive.sheetId ? model.calculus.googleDrive.sheetId : '',
              reportTemplateType: model && model.reportTemplateType ? model.reportTemplateType : '',
              compatible: model && model.compatible ? "1" : "0",
              settings: {
                fileNameStrategy: model && model.settings && model.settings.fileNameStrategy && model.settings.fileNameStrategy ? model.settings.fileNameStrategy : 'FAMILY_LABEL+NAME',
              }
            }}
            onSubmit={(values, {setSubmitting}) => {
              setTimeout(() => {
                setSubmitting(false);
                action === "CREATE" ? saveModelForm(values) : updateModelForm(values);
              }, 500);
            }}
            validationSchema={Yup.object().shape({
              id: Yup.string(),
              name: Yup.string().required("Champ Requis"),
              description: Yup.string().required("Champ Requis"),
              changelog: Yup.string(),
              reportReference: Yup.string(),
              oldReportReference: Yup.string(),
              icon: Yup.mixed(),
              paramForm: Yup.mixed(),
              calculFormUri: Yup.string(),
              calculForm: Yup.mixed(),
              methodologyNote: Yup.mixed(),
              version: Yup.string().required("Champ Requis"),
              parentEPDId: Yup.string(),
              parentEPDSerialId: Yup.string(),
              googleDriveSheetId: Yup.string(),
              reportTemplateType: Yup.string(),
              compatible: Yup.string(),
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <form className="form form-horizontal striped-rows form-bordered"
                  onSubmit={handleSubmit}>
                  <h4 className="form-section">Formulaire</h4>
                  <div className="form-group row has-danger">
                    <label htmlFor="name" className="col-md-3 label-control">Nom du
                      formulaire</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {errors.name && touched.name &&
                        <div className="mt-1 danger">{errors.name}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="description"
                      className="col-md-3 label-control">Description du
                      formulaire</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="description"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                      {errors.description && touched.description &&
                        <div className="mt-1 danger">{errors.description}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="icon"
                      className="col-md-3 label-control">Icône du formulaire</label>
                    <div className="col-md-9">
                      <input
                        type="file"
                        id="icon"
                        className="form-control"
                        onChange={(e) => onChangeFile(e, setFieldValue, 'icon')}
                        onBlur={handleBlur}
                      />
                      {errors.icon && touched.icon &&
                        <div className="mt-1 danger">{errors.icon}</div>}

                      {model && model.icon !== "" && (
                        <div className="max-image">Image actuelle : <img className="img-fluid" alt="Logo" src={model.icon} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="paramForm"
                      className="col-md-3 label-control">Formulaire de
                      paramètres (fichier CSV)</label>
                    <div className="col-md-9">
                      <input
                        type="file"
                        id="paramForm"
                        className="form-control"
                        disabled={action === "UPDATE"}
                        onChange={(e) => onChangeFile(e, setFieldValue, 'paramForm')}
                        onBlur={handleBlur}
                      />
                      {errors.paramForm && touched.paramForm &&
                        <div className="mt-1 danger">{errors.paramForm}</div>}

                      {model &&
                        model.parametersCsv !== "" && (
                          <div>Fichier actuel : <a target="_blank" rel="noopener noreferrer"
                            href={model.parametersCsv}>{model.parametersCsv}</a></div>
                        )}
                    </div>
                  </div>
                  <h4 className="form-section">Documentation</h4>
                  <div className="form-group row has-danger">
                    <label htmlFor="methodologyNote"
                      className="col-md-3 label-control">Note méthodologique</label>
                    <div className="col-md-9">
                      <input
                        type="file"
                        id="methodologyNote"
                        className="form-control"
                        disabled={action === "UPDATE"}
                        onChange={(e) => onChangeFile(e, setFieldValue, 'methodologyNote')}
                        onBlur={handleBlur}
                      />
                      {errors.methodologyNote && touched.methodologyNote &&
                        <div className="mt-1 danger">{errors.methodologyNote}</div>}
                      {model &&
                        model.methodologyNote !== "" && (
                          <div>Fichier actuel : <a target="_blank" rel="noopener noreferrer"
                            href={model.methodologyNote}>{model.methodologyNote}</a></div>
                        )}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="fdesMereFile"
                      className="col-md-3 label-control">FDES mère</label>
                    <div className="col-md-9">
                      <input
                        type="file"
                        id="fdesMereFile"
                        className="form-control"
                        disabled={action === "UPDATE"}
                        onChange={(e) => onChangeFile(e, setFieldValue, 'fdesMereFile')}
                        onBlur={handleBlur}
                      />
                      {errors.fdesMereFile && touched.fdesMereFile &&
                        <div className="mt-1 danger">{errors.fdesMereFile}</div>}
                      {model &&
                        model.documentation && model.documentation.fdesMere && model.documentation.fdesMere.file !== "" && (
                          <div>Fichier actuel : <a target="_blank" rel="noopener noreferrer"
                            href={model.documentation.fdesMere.file}>{model.documentation.fdesMere.file}</a>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="guideFile"
                      className="col-md-3 label-control">Guide utilisateur</label>
                    <div className="col-md-9">
                      <input
                        type="file"
                        id="guideFile"
                        className="form-control"
                        disabled={action === "UPDATE"}
                        onChange={(e) => onChangeFile(e, setFieldValue, 'guideFile')}
                        onBlur={handleBlur}
                      />
                      {errors.guideFile && touched.guideFile &&
                        <div className="mt-1 danger">{errors.guideFile}</div>}
                      {model &&
                        model.documentation && model.documentation.guide && model.documentation.guide.file !== "" && (
                          <div>Fichier actuel : <a target="_blank" rel="noopener noreferrer"
                            href={model.documentation.guide.file}>{model.documentation.guide.file}</a>
                          </div>
                        )}
                    </div>
                  </div>
                  <h4 className="form-section">Calcul</h4>
                  <div className="form-group row has-danger">
                    <label htmlFor="standard"
                      className="col-md-3 label-control">Norme</label>
                    <div className="col-md-9">
                      <select
                        id="standard"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.standard}
                      >
                        <option value="NF_EN_15804-A1">NF EN 15804+A1</option>
                        <option value="NF_EN_15804-A2">NF EN 15804+A2</option>
                        <option value="EF_3.0_PALETTE">EF 3.0 Palette</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="calculForm"
                      className="col-md-3 label-control">Résultats précalculé (fichier CSV)</label>
                    <div className="col-md-9">
                      <input
                        type="file"
                        id="calculForm"
                        className="form-control"
                        disabled={action === "UPDATE"}
                        onChange={(e) => onChangeFile(e, setFieldValue, 'calculForm')}
                        onBlur={handleBlur}
                      />
                      {errors.calculForm && touched.calculForm &&
                        <div className="mt-1 danger">{errors.calculForm}</div>}

                      {model &&
                        model.resultCsv !== "" && (
                          <div>Fichier actuel : <a target="_blank" rel="noopener noreferrer"
                            href={model.resultCsv}>{model.resultCsv}</a></div>
                        )}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="calculFormUri"
                      className="col-md-3 label-control">Résultats précalculé (URL)</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="calculFormUri"
                        className="form-control"
                        disabled={action === "UPDATE"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.calculFormUri}
                      />
                      <div className="mt-1">* Format URL: http://XXX.csv, ça remplace l'upload du fichier,
                        le champ Formulaire de calcul ne sera pas prise en compte si renseigné
                      </div>
                      {errors.calculFormUri && touched.calculFormUri &&
                        <div className="mt-1 danger">{errors.calculFormUri}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="googleDriveSheetId" className="col-md-3 label-control">Résultat temps réel (ID Google Sheets)</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="googleDriveSheetId"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.googleDriveSheetId}
                      />
                      {errors.googleDriveSheetId && touched.googleDriveSheetId &&
                        <div className="mt-1 danger">{errors.googleDriveSheetId}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="version" className="col-md-3 label-control">Version du calcul</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="version"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.version}
                      />
                      {errors.version && touched.version &&
                        <div className="mt-1 danger">{errors.version}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="changelog" className="col-md-3 label-control">Nature de la modification</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="changelog"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.changelog}
                      />
                      {errors.changelog && touched.changelog &&
                        <div className="mt-1 danger">{errors.changelog}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="compatibility" className="col-md-3 label-control">Compatibilité du modèle</label>
                    <div className="col-md-9">
                      <label className="mr-4">Compatible
                        <input
                          type="radio"
                          id="compatible"
                          className="ml-1"
                          checked={values.compatible === "1"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={"1"}
                        />
                      </label>
                      <label>Non Compatible
                        <input
                          type="radio"
                          id="compatible"
                          className="ml-1"
                          checked={values.compatible === "0"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={"0"}
                        />
                      </label>
                      {errors.compatible && touched.compatible &&
                        <div className="mt-1 danger">{errors.compatible}</div>}
                    </div>
                  </div>
                  <h4 className="form-section">Export FDES</h4>
                  <div className="form-group row has-danger">
                    <label htmlFor="settings.fileNameStrategy" className="col-md-3 label-control">Nom du fichier
                      PDF/XML</label>
                    <div className="col-md-9">
                      <select className="form-control" name="settings.fileNameStrategy" id="settings.fileNameStrategy"
                        value={values.settings.fileNameStrategy} onBlur={handleBlur} onChange={handleChange}>
                        <option value="" />
                        {Object.entries(fileNameStrategyKeyToLabel).map(value =>
                          <option key={value[0]} value={value[0]}>{value[1]}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="reportTemplateType" className="col-md-3 label-control">Type de template</label>
                    <div className="col-md-9">
                      <select className="form-control" name="reportTemplateType" id="reportTemplateType"
                        value={values.reportTemplateType} onBlur={handleBlur} onChange={handleChange}>
                        <option value="" />
                        {Object.entries(fdesTemplateType).map(value =>
                          <option key={value[0]} value={value[0]}>{value[1]}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="reportReference" className="col-md-3 label-control">Identifiant du template PDF</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="reportReference"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reportReference}
                      />
                      {errors.reportReference && touched.reportReference &&
                        <div className="mt-1 danger">{errors.reportReference}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="parentEPDId" className="col-md-3 label-control">Identifiant de la FDES mère</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="parentEPDId"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parentEPDId}
                      />
                      {errors.parentEPDId && touched.parentEPDId &&
                        <div className="mt-1 danger">{errors.parentEPDId}</div>}
                    </div>
                  </div>
                  <div className="form-group row has-danger">
                    <label htmlFor="parentEPDSerialId" className="col-md-3 label-control">Clé nationale de la FDES mère</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="parentEPDSerialId"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parentEPDSerialId}
                      />
                      {errors.parentEPDSerialId && touched.parentEPDSerialId &&
                        <div className="mt-1 danger">{errors.parentEPDSerialId}</div>}
                    </div>
                  </div>
                  <div className="form-actions right">
                    <Link to={`/family-product/edit/${familyProductId}`}>
                      <button type="reset" className="btn btn-warning mr-1">
                        <i className="feather icon-x" /> Annuler
                      </button>
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      <i className="fa fa-check-square-o" /> Enregistrer
                    </button>
                  </div>
                </form>)
            }}
          </Formik>
        </div>
      </div>
    </div>
  )

};

export default ModelForm;
