import {connect} from 'react-redux';
import ModelForm from '../components/ModelForm';
import {saveFamilyProductFdes, updateFamilyProductFdes} from "../actions";

export default connect(
    (state) => ({
        modelFormIsLoading: state.loader.modelFormIsLoading,
    }),
    (dispatch) => ({
        saveModelForm: (values) => {
            dispatch(saveFamilyProductFdes(values))
        },
        updateModelForm: (values) => {
            dispatch(updateFamilyProductFdes(values))
        },
    })

)(ModelForm);