import {
    SAVE_REGISTER_FORM_SUCCESS,
    SAVE_VERIFICATION_FAILED,
    SAVE_VERIFICATION_SUCCESS
} from "../constants/action-types";
import produce from "immer";

const initialState = {
    stepRegisterForm: 'STEP_1',
    message: 'Votre compte a bien été créé. Dans quelques instants vous allez recevoir par mail un lien permettant son activation.',
    verify: {
        isLoading: true,
        isValid: false,
    }
};

const contextReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case SAVE_REGISTER_FORM_SUCCESS:
            draft.stepRegisterForm = 'STEP_2';
            if (action.payload && action.payload.configurators.length > 1) {
                // if user has more than one configurator
                if (action.payload.includes && action.payload.includes.configurators && action.payload.includes.configurators.length > 1) {
                    const list = action.payload.includes.configurators?.map(configurator => `${configurator.title} (${configurator.domain})`).join();
                    draft.message = `L’e-mail que vous venez de renseigner est déjà utilisé pour un ou plusieurs autres configurateurs de FDES développés par Apsivi. Ainsi, nous n’avons pas créé de nouveau compte pour vous. Vous pouvez utiliser le même compte pour vous connecter à l’ensemble des configurateurs concernés : ${list}. Le mot de passe à utiliser est celui du ou des configurateurs sur lesquels vous étiez déjà inscrit.`
                } else {
                    draft.message = `L’e-mail que vous venez de renseigner est déjà utilisé pour un ou plusieurs autres configurateurs de FDES développés par Apsivi. Ainsi, nous n'avons pas créé de nouveau compte pour vous. Vous pouvez utiliser le même compte pour vous connecter à l'ensemble des configurateurs concernés. Le mot de passe à utiliser est celui du ou des configurateurs sur lesquels vous étiez déjà inscrit.`
                }
            }
            break;
        case SAVE_VERIFICATION_SUCCESS:
            draft.verify.isLoading = false;
            draft.verify.isValid = true;
            break;
        case SAVE_VERIFICATION_FAILED:
            draft.verify.isLoading = false;
            draft.verify.isValid = false;
            break;
        default:
            return draft;
    }
});
export default contextReducer;