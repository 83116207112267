import {connect} from 'react-redux';
import ModuleResult from "../components/ModuleResult";
import {changeModuleResultForm} from "../actions";
import {getHistogramSteps, getIndicatorsTableForPreferenceStep, getStepsTableForPreferenceStep} from "../selectors";

export default connect(
    (state) => ({
        indicators: state.fdes.references.indicators,
        localConfigurator: state.configurator.localConfigurator,
        indicatorValue: state.fdes.module.fields.indicator,
        histogramResults: getHistogramSteps(state),
        tableResult: getIndicatorsTableForPreferenceStep(state),
        tableSteps: getStepsTableForPreferenceStep(state)
    }),
    (dispatch) => ({
        onChangeModuleResultForm: (value) => {
            dispatch(changeModuleResultForm(value))
        }
    })

)(ModuleResult);