import { connect } from "react-redux";
import UserSetting from "../pages/UserSetting";
import {getLocalEDECIntegration} from "../../../common/actions/user";

export default connect(
    (state) => ({
        localEDECIntegration: state.configurator.localEDECIntegration
    }),
    (dispatch) => ({
        getLocalEDECIntegration: () => {
            dispatch(getLocalEDECIntegration())
        },
    })
)(UserSetting)