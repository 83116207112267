import React from 'react';
import './Loader.scss';

const Loader = ({isActive}) => {

    return (<>
        {isActive && (<div className="loader-overlay"><div className="feather icon-refresh-cw icon-spin font-medium-2" /></div>)}
    </>)
};

export default Loader;
