import Users from "../pages/User";
import UserCreate from "../pages/UserCreate";
import UserEdit from "../containers/UserEdit";
import UserSetting from "../containers/UserSetting";


const routes = [
    {
        path: '/users',
        exact: true,
        element: Users
    },
    {
        path: '/users/create',
        exact: true,
        element: UserCreate
    },
    {
        path: '/account-setting',
        exact: true,
        element: UserSetting
    },
    {
        path: '/users/edit/:userId',
        exact: true,
        element: UserEdit
    },
];

export default routes;
