import React, {useEffect, useState} from 'react';
import WelcomeContent from "../modules/home/containers/WelcomeContent";
import FamilyProductView from "../modules/family-product/containers/FamilyProductView";
import {withPermissions} from "../layout/Permissions";

const Home = ({location}) => {

    const [referer, setReferer] = useState('');

    useEffect(() => {
        const definedReferer = new URLSearchParams(location.search).get("referer");

        setReferer(definedReferer);

    }, [location]);

  return (
        <>
        <WelcomeContent referer={referer}/>
        <FamilyProductView />
       </>
)

};

export default withPermissions(Home);
