import chroma from "chroma-js";

export const DEFAULT_ACTIVE_COLOR = "#00B5B8";


export const selectStyles = (activeColor) => {
    const hoverActiveColor = chroma(activeColor).alpha(0.1).css();

    return {
        control: (styles, {isFocused, isSelected, isHover}) => ({
            ...styles,
            borderColor: isFocused || isSelected || isHover ? activeColor : "hsl(0,0%,80%)",
            boxShadow: 'none',
            '&:hover': {
                border: `1px solid ${activeColor} !important`
            }
        }),
        option: (styles, {isFocused, isSelected, isDisabled}) => ({
            ...styles, backgroundColor: isDisabled
                ? null
                : isSelected
                    ? activeColor
                    : isFocused
                        ? hoverActiveColor
                        : null,
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? activeColor : chroma(activeColor).alpha(0.3).css()),
            },
        })
    }
};