import React, { useEffect, useState } from "react";
import ConfiguratorForm from "../containers/ConfiguratorForm";
import Breadcrumb from "../../../common/components/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { withPermissions } from "../../../layout/Permissions";
import { PERMISSION_SUPER_ADMIN } from "../../user/constants/permissions";

const ConfiguratorEdit = ({ configurators, getEDECConfiguratorRequest }) => {
  const [configurator, setConfigurator] = useState({});

  const params = useParams();

  useEffect(() => {
    if (params && params.configuratorId) {
      setConfigurator(
        configurators.find((x) => x.id === params.configuratorId)
      );
      getEDECConfiguratorRequest(params.configuratorId);
    }
  }, [params, configurators, getEDECConfiguratorRequest]);

  return (
    <>
      <Breadcrumb title="Modifier le configurateur">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Accueil</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/configurators">Configurateurs</Link>
          </li>
          <li className="breadcrumb-item active">Modifier un configurateur</li>
        </ol>
      </Breadcrumb>
      <ConfiguratorForm action="UPDATE" configurator={configurator} />
    </>
  );
};

export default withPermissions(ConfiguratorEdit, PERMISSION_SUPER_ADMIN);
