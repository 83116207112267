import React from 'react';
import Register from "../common/containers/Register";

const RegisterPage = () => {

  return (<Register/>)

};

export default RegisterPage;
