import React, {useEffect, useState} from 'react';
import ManageGroupForm from '../containers/ManageGroupForm';
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";

const ManageGroupEdit = ({match, groups}) => {
    const [group, setGroup] = useState({});

    const params = useParams()

    useEffect(() => {
        if (params && params.groupId) {
            setGroup(groups.find(x => x._id === params.groupId));
        }
    }, [params, groups]);

    return (
        <>
            <Breadcrumb title="Modifier le groupe">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/manage-group">Gestions des groupes</Link></li>
                    <li className="breadcrumb-item active">Modifier un groupe d'utilisateur</li>
                </ol>
            </Breadcrumb>
            <ManageGroupForm action="UPDATE" group={group} />
        </>
    )

};
export default withPermissions(ManageGroupEdit, PERMISSION_SUPER_ADMIN);

