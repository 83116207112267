import React, {useEffect, useState} from 'react';
import FamilyProductForm from "../containers/FamilyProductForm";
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";

const FamilyProductCreate = ({match, cleanModels}) => {
    const [parentId, setParentId] = useState('');

    const params = useParams()

    useEffect(() => {
        if (params && params.parentId) {
            setParentId(params.parentId)
        }
        cleanModels();
    }, [match, cleanModels, params]);

    return (
        <>
            <Breadcrumb title="Créer une famille de produit">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/family-product">Famille de produit</Link></li>
                    <li className="breadcrumb-item active">Créer une famille de produit</li>
                </ol>
            </Breadcrumb>
            <FamilyProductForm parentId={parentId} />
        </>
    )

};
export default withPermissions(FamilyProductCreate, PERMISSION_SUPER_ADMIN);

