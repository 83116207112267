import {connect} from 'react-redux';
import RegisterForm from '../components/RegisterForm';
import {saveRegisterForm} from "../actions/register";

export default connect(
    () => ({
    }),
    (dispatch) => ({
        saveRegisterForm: (values) => {
            dispatch(saveRegisterForm(values))
        },
    })

)(RegisterForm);