import React, { useEffect } from "react";
import ConfiguratorRaw from "./ConfiguratorRaw";

const Configurators = ({
  getConfiguratorsRequest,
  configurators,
  deleteConfigurator,
}) => {
  useEffect(() => {
    getConfiguratorsRequest();
  }, [getConfiguratorsRequest]);

  return (
    <div className="users-list-table">
      <div className="card">
        <div className="card-content">
          <div className="card-body">
            <div className="table-responsive">
              <table id="users-list-datatable" className="table">
                <thead>
                  <tr>
                    <th>Domaine</th>
                    <th>Nom</th>
                    <th>Couleur principale</th>
                    <th>Logo</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {configurators &&
                    configurators.length > 0 &&
                    configurators.map((configurator) => (
                      <ConfiguratorRaw
                        key={configurator.id}
                        configurator={configurator}
                        deleteConfigurator={deleteConfigurator}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurators;
