import React from 'react';
import CollapsableCard from "./CollapsableCard";
import Breadcrumb from "./Breadcrumb";
import {withPermissions} from "../../layout/Permissions";

const Guide = ({localConfigurator, guideDownloadLinks}) => {

    function createMarkup() {
        return {__html: localConfigurator && localConfigurator.documentation && localConfigurator.documentation.guide ? localConfigurator.documentation.guide.description : ''};
    }
    return (
        <>
            <Breadcrumb title="Guides">
            </Breadcrumb>
            <CollapsableCard title={localConfigurator && localConfigurator.documentation && localConfigurator.documentation.guide ? localConfigurator.documentation.guide.title : ''}>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card-text" dangerouslySetInnerHTML={createMarkup()}/>
                    </div>
                    {guideDownloadLinks && guideDownloadLinks.length > 0 && (
                        <div className="col-md-4">
                            <p className="h5 mb-2">Liste des guides d’utilisation :</p>
                            { guideDownloadLinks.map(guideLink =>
                                <p><a href={guideLink.file} target="_blank" rel="noopener noreferrer"><i className="feather icon-file"/> {guideLink.name === 'main' ? 'Guide principal':guideLink.name}</a></p>
                            )}
                        </div>
                    )}
                </div>
            </CollapsableCard>
        </>


    )

};

export default withPermissions(Guide);
