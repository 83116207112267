import React from 'react';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";

const Models = ({models, activeFamilyProductId}) => {
    return (
        <div className="users-list-table">
            <div className="card">
                <div className="card-content">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table id="users-list-datatable" className="table">
                                <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Version</th>
                                    <th>Date d'enregistrement</th>
                                    <th>Etat</th>
                                    <th>Nature de la modification</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {models && models.length > 0 && (
                                    models.map(model =>
                                        <tr>
                                            <td>{model.title}</td>
                                            <td>{model.version}</td>
                                            <td>{format(parseISO(model.createdAt),'dd/MM/yyyy HH:mm')}</td>
                                            <td>{model.active ? 'Actif':'Archivé'}</td>
                                            <td>{model.changelog}</td>
                                            <td>
                                                <div>
                                                    {model.active && (<Link
                                                    to={`/family-product/edit/${model.productFamily}/models/edit/${model._id}`}>
                                                    <Button variant="success mr-2" ><i className="feather icon-edit-1"/> </Button></Link>
                                                    )}
                                                    <Link
                                                    to={`/family-product/edit/${model.productFamily}/models/view/${model._id}`}>
                                                    <Button variant="info" ><i className="feather icon-eye"/> </Button></Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                                </tbody>
                            </table>
                            {activeFamilyProductId && (
                                <Link
                                    to={`/family-product/edit/${activeFamilyProductId}/models/create`}>
                                    <Button variant="success" >{models && models.length > 0 ? 'Remplacer par un nouveau modèle':'Créer un nouveau modèle'}</Button></Link>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Models;
