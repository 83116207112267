import {connect} from 'react-redux';
import AccessToken from '../components/AccessToken';
import {loginSaml} from "../actions/user";

export default connect(
  (state) => ({
    error: state.user.error,
  }),
  (dispatch) => ({
    loginSaml: (values) => {
      dispatch(loginSaml(values));
    },
  })

)(AccessToken);
