import {connect} from 'react-redux';
import Register from '../components/Register';

export default connect(
    (state) => ({
        step: state.context.stepRegisterForm,
        message: state.context.message,
        localConfigurator: state.configurator.localConfigurator
    }),
    () => ({

    })

)(Register);