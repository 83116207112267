import Fdes from "../containers/Fdes";
import FdesEdit from "../containers/FdesEdit";

const routes = [
    {
        path: '/fdes/:familyProductId',
        exact: true,
        element: Fdes
    },
    {
        path: '/fdes/:familyProductId/:calculId',
        exact: true,
        element: FdesEdit
    },
];

export default routes;
