import {connect} from 'react-redux';
import Contact from '../components/Contact';
import {resetMessage, sendEmail} from '../actions';

export default connect(
    (state) => ({
        userName: state.user.informations.firstName + ' ' + state.user.informations.lastName,
        userEmail: state.user.informations.email,
        validationMessage: state.assistance.message
    }),
    (dispatch) => ({
        sendEmail: (values) => {
            dispatch(sendEmail(values))
        },
        resetMessage: () => {
            dispatch(resetMessage())
        }
    })

)(Contact);