import { SEND_EMAIL, RESET_CONTACT_PAGE } from "../constants";

export function sendEmail(values) {
    return {
        type: SEND_EMAIL, values
    };
}

export function resetMessage() {
    return {
        type: RESET_CONTACT_PAGE
    }
}