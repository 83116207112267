export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_BY_USERID = 'GET_GROUPS_BY_USERID';
export const GET_GROUPS_BY_USERID_SUCCESS = 'GET_GROUPS_BY_USERID_SUCCESS';
export const SEARCH_USERS_BY_CONFIGURATOR = 'SEARCH_USERS_BY_CONFIGURATOR';
export const SEARCH_USERS_BY_CONFIGURATOR_SUCCESS = 'SEARCH_USERS_BY_CONFIGURATOR_SUCCESS';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUPS_SUCCESS';
export const SAVE_GROUP_FORM = 'SAVE_GROUP_FORM';
export const UPDATE_GROUP_FORM = 'UPDATE_GROUP_FORM';
export const DISABLE_GROUP_FORM_LOADER = 'DISABLE_GROUP_FORM_LOADER'
export const LOAD_MODEL_FOR_GROUP = 'LOAD_MODEL_FOR_GROUP';
export const LOAD_MODEL_FOR_GROUP_SUCCESS = 'LOAD_MODEL_FOR_GROUP_SUCCESS';
export const LOAD_MODEL_FOR_GROUP_FAILED = 'LOAD_MODEL_FOR_GROUP_FAILED';
export const CLEAN_MODELS = 'CLEAN_MODELS';
export const CLEAN_USERS = 'CLEAN_USERS';