import React, {useEffect} from 'react';
import UserRaw from "./UserRaw";

const Users = ({getUsersRequest, users,deleteUser}) => {


    useEffect(() => {
        getUsersRequest();
    }, [getUsersRequest]);

    return (
        <div className="users-list-table">
            <div className="card">
                <div className="card-content">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table id="users-list-datatable" className="table">
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Date de création</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {users && users.length > 0 && (
                                    users.map(user =>
                                        <UserRaw key={user.id} user={user} deleteUser={deleteUser} />
                                    )
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Users;
