import React, {useEffect, useState} from 'react';
import FamilyProductForm from "../containers/FamilyProductForm";
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";

const FamilyProductEdit = ({match, familyProducts, loadModelForFamilyProduct, location}) => {
    const [familyProduct, setFamilyProduct] = useState({});
    const [modeleActive, setModeleActive] = useState(false);

    const params = useParams()

    useEffect(() => {
        if (params && params.familyProductId) {
            const flatten = (routes) =>
                routes.reduce((acc, r) => {
                    if (r.children && r.children.length) {
                        acc = acc.concat(flatten(r.children));
                    }
                    acc.push(r);

                    return acc;
                }, []);
            setFamilyProduct(flatten(familyProducts).find(x => x._id === params.familyProductId))
            loadModelForFamilyProduct(params.familyProductId)
        }

    }, [params, familyProducts, loadModelForFamilyProduct]);

    useEffect(() => {
        if (location && location.hash && location.hash === "#modele") {
            setModeleActive(true)
        }
    }, [location]);


    return (<>

        <Breadcrumb title="Modifier une famille de produit">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                <li className="breadcrumb-item"><Link to="/family-product">Famille de produit</Link></li>
                <li className="breadcrumb-item active">Modifier une famille de produit</li>
            </ol>
        </Breadcrumb>
        <FamilyProductForm action="UPDATE" familyProduct={familyProduct} modeleActive={modeleActive} />
    </>
    )

};

export default withPermissions(FamilyProductEdit, PERMISSION_SUPER_ADMIN);
