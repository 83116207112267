import {
  GET_CONFIGURATORS_SEND,
  CONFIGURATOR_FORM_SEND,
  CONFIGURATOR_FORM_UPDATE,
  CONFIGURATOR_FORM_DELETE,
  GET_EDEC_CONFIGURATOR_SEND,
  EDEC_CONFIGURATOR_FORM_SEND,
  EDEC_CONFIGURATOR_FORM_UPDATE,
  EDEC_CONFIGURATOR_FORM_DELETE,
} from "../constants";
import { GET_LOCAL_CONFIGURATOR } from "../../../common/constants/action-types";

export function getLocalConfiguration() {
  return {
    type: GET_LOCAL_CONFIGURATOR,
  };
}

export function getConfiguratorsSend() {
  return {
    type: GET_CONFIGURATORS_SEND,
  };
}

export function configuratorFormSend(values) {
  return {
    type: CONFIGURATOR_FORM_SEND,
    values,
  };
}

export function configuratorFormUpdate(values) {
  return {
    type: CONFIGURATOR_FORM_UPDATE,
    values,
  };
}

export function configuratorFormDelete(configuratorId) {
  return {
    type: CONFIGURATOR_FORM_DELETE,
    configuratorId,
  };
}

export function getEDECConfiguratorSend(configuratorId) {
  return {
    type: GET_EDEC_CONFIGURATOR_SEND,
    configuratorId,
  };
}

export function EDECConfiguratorFormSend(values) {
  return {
    type: EDEC_CONFIGURATOR_FORM_SEND,
    values,
  };
}

export function EDECConfiguratorFormUpdate(values) {
  return {
    type: EDEC_CONFIGURATOR_FORM_UPDATE,
    values,
  };
}

export function EDECConfiguratorFormDelete(EDECConfiguratorId) {
  return {
    type: EDEC_CONFIGURATOR_FORM_DELETE,
    EDECConfiguratorId,
  };
}