import React from 'react';
import {Link} from "react-router-dom";

const ModelDetail = ({model, familyProductId}) => {

    return (

        <div className="card">
            <div className="card-content collapse show">
                <div className="card-body">
                    <div className="form-group row ">
                        <label htmlFor="name" className="col-md-3 label-control font-weight-bold">Nom du
                            formulaire : </label>
                        <div className="col-md-9">
                            <p>{model.title}</p>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <label htmlFor="name" className="col-md-3 label-control font-weight-bold">Description du
                            formulaire : </label>
                        <div className="col-md-9">
                            <p>{model.description}</p>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <label htmlFor="name" className="col-md-3 label-control font-weight-bold">Icône : </label>
                        <div className="col-md-9">
                            <p>{model.icon}</p>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <label htmlFor="name" className="col-md-3 label-control font-weight-bold">Formulaire de
                            paramètres : </label>
                        <div className="col-md-9">
                            <p><a href={model.parametersCsv} target="_blank" rel="noopener noreferrer"><i className="feather icon-file"/> {model.parametersCsv}</a></p>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <label htmlFor="name" className="col-md-3 label-control font-weight-bold">Formulaire de
                            calcul : </label>
                        <div className="col-md-9">
                            <p><a href={model.resultCsv} target="_blank" rel="noopener noreferrer"><i className="feather icon-file"/> {model.resultCsv}</a></p>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <label htmlFor="name" className="col-md-3 label-control font-weight-bold">Note méthodologique : </label>
                        <div className="col-md-9">
                            <p><a href={model.methodologyNote} target="_blank" rel="noopener noreferrer"><i className="feather icon-file"/> {model.methodologyNote}</a></p>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <label htmlFor="name" className="col-md-3 label-control font-weight-bold">Nature de la modification : </label>
                        <div className="col-md-9">
                            <p>{model.changelog}</p>
                        </div>
                    </div>
                    <div className="form-actions right">
                        <Link to={`/family-product/edit/${familyProductId}#modele`}>
                            <button type="reset" className="btn btn-warning mr-1">
                               Retour
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default ModelDetail;
