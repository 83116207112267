import Configurator from "../pages/Configurator";
import ConfiguratorCreate from "../pages/ConfiguratorCreate";
import ConfiguratorEdit from "../containers/ConfiguratorEdit";

const routes = [
    {
        path: '/configurators',
        exact: true,
        element: Configurator
    },
    {
        path: '/configurators/create',
        exact: true,
        element: ConfiguratorCreate
    },
    {
        path: '/configurators/edit/:configuratorId',
        exact: true,
        element: ConfiguratorEdit
    },
];

export default routes;
