import React from 'react';
import Login from "../common/containers/Login";

const LoginPage = () => {

  return (<Login isOpen={true} />)

};

export default LoginPage;
