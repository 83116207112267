import React from 'react';
import * as Yup from "yup";
import {Formik} from "formik";
import {userTypeKeyToLabel} from "../../../utils/transformer";

const AccountSettingForm = ({user, updateUserForm}) => {

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: user ? user.id : '',
                    lastName: user ? user.lastName : '',
                    firstName: user ? user.firstName : '',
                    type: user ? user.type : '',
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        setSubmitting(false);
                        updateUserForm(values);
                    }, 500);
                }}
                validationSchema={Yup.object().shape({
                    id: Yup.string(),
                    lastName: Yup.string().required("Champ Requis"),
                    firstName: Yup.string().required("Champ Requis"),
                    type: Yup.string(),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;

                    return (
                        <form
                            onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group has-danger">
                                        <div className="controls">
                                            <label
                                                htmlFor="lastName">Nom</label>
                                            <input
                                                type="text"
                                                id="lastName"
                                                placeholder="Nom"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                            <div className="mt-1 danger">{errors.lastName}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group has-danger">
                                        <div className="controls">
                                            <label
                                                htmlFor="firstName">Prénom</label>
                                            <input
                                                type="text"
                                                id="firstName"
                                                placeholder="Prénom"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                            <div className="mt-1 danger">{errors.firstName}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group has-danger">
                                        <div className="controls">
                                            <label
                                                htmlFor="type">Type</label>
                                            <select className="form-control" name="type" id="type" value={values.type}
                                                    onBlur={handleBlur} onChange={handleChange}>
                                                <option value=""/>
                                                {Object.entries(userTypeKeyToLabel).map(value =>
                                                    <option key={value[0]} value={value[0]}>{value[1]}</option>
                                                )}
                                            </select>
                                            {errors.type && touched.type &&
                                            <div className="mt-1 danger">{errors.type}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 d-flex flex-sm-row flex-column justify-content-end">
                                    <button type="submit" className="btn btn-primary ">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    )

};

export default AccountSettingForm;
