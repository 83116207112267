import React from 'react';

const Paginator = ({total, currentPage, limit, onChangePage}) => {
    const numberPage = limit > 0 && total > 0 ? Math.ceil(total / limit) : 1;

    return (<div>
        {numberPage > 1 && (
            <ul className="pagination pagination-lg mb-1 ml-1">
                <li className="page-item"/>
                {Array(numberPage).fill(undefined, undefined, undefined).map((value, page) =>
                    <li key={page} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}><button
                        onClick={() => onChangePage(page + 1)} className="page-link">{page + 1}</button></li>
                )}
                <li className="page-item"/>
            </ul>
        )}
    </div>)
};

export default Paginator;
