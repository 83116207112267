import {connect} from 'react-redux';
import RecoverPasswordForm from '../components/RecoverPasswordForm';
import {saveRecoverPasswordForm} from "../actions/user";

export default connect(
    () => ({
    }),
    (dispatch) => ({
        saveRecoverPasswordForm: (values) => {
            dispatch(saveRecoverPasswordForm(values))
        },
    })

)(RecoverPasswordForm);