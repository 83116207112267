import {connect} from 'react-redux';
import FamilyProductCreate from '../pages/FamilyProductCreate';
import {cleanModels} from "../actions";

export default connect(
    () => ({
    }),
    (dispatch) => ({
        cleanModels: () => {
            dispatch(cleanModels())
        }
    })

)(FamilyProductCreate);