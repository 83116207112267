import produce from "immer";
import {
    GET_GROUPS_SUCCESS,
    LOAD_MODEL_FOR_GROUP_SUCCESS,
    CLEAN_MODELS,
    SEARCH_USERS_BY_CONFIGURATOR_SUCCESS,
    DELETE_GROUP_SUCCESS,
    CLEAN_USERS,
    GET_GROUPS_BY_USERID_SUCCESS
} from '../constants'

const initialState = {
    groups: [],
    userGroups: [],
    models: [],
    users: []
};

const contextReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case GET_GROUPS_SUCCESS:
            storeGroups(draft, action.payload);
            break;
        case GET_GROUPS_BY_USERID_SUCCESS:
            storeUserGroups(draft, action.payload);
            break;
        case DELETE_GROUP_SUCCESS:
            removeGroup(draft, action.payload);
            break;
        case SEARCH_USERS_BY_CONFIGURATOR_SUCCESS:
            storeUsers(draft, action.payload);
            break;
        case LOAD_MODEL_FOR_GROUP_SUCCESS:
            storeModels(draft, action.payload);
            break;
        case CLEAN_USERS:
            draft.users = [];
            break;
        case CLEAN_MODELS:
            draft.models = [];
            break;
        default:
            return draft;
    }
});

function removeGroup(draft, payload) {
    draft.groups.splice(draft.groups.findIndex(groups => groups._id === payload), 1);
}

function storeUsers(draft, payload) {
    draft.users = [...payload.data];
}

function storeUserGroups(draft, payload) {
    draft.userGroups = [...payload.data];
}

function storeGroups(draft, payload) {
    draft.groups = [...payload.data];
}

function storeModels(draft, payload) {
    draft.models = payload.data;
}

export default contextReducer;
