import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import AppRoutes from "./routes";
import {store, persistor} from "./store";
import {PersistGate} from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';


const root = createRoot(
    document.getElementById('root')
);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AppRoutes />
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
