import {
    GET_FAMILY_PRODUCTS,
    SAVE_FAMILY_PRODUCT_FORM,
    UPDATE_FAMILY_PRODUCT_FORM,
    DELETE_FAMILY_PRODUCT,
    SAVE_FAMILY_PRODUCT_FDES,
    SAVE_FAMILY_PRODUCT_FDES_SUCCESS,
    SAVE_FAMILY_PRODUCT_FDES_FAILED,
    UPDATE_FAMILY_PRODUCT_FDES,
    LOAD_MODEL_FOR_FAMILY_PRODUCT,
    CLEAN_MODELS,
    GET_AGGREGATED_FAMILY_PRODUCTS,
} from "../constants";

export function getFamilyProducts() {
    return {
        type: GET_FAMILY_PRODUCTS
    };
}
export function getAggregatedFamilyProducts() {
    return {
        type: GET_AGGREGATED_FAMILY_PRODUCTS
    };
}
export function saveFamilyProductForm(values) {
    return {
        type: SAVE_FAMILY_PRODUCT_FORM, values
    };
}
export function updateFamilyProductForm(values) {
    return {
        type: UPDATE_FAMILY_PRODUCT_FORM, values
    };
}
export function deleteFamilyProduct(familyProductId) {
    return {
        type: DELETE_FAMILY_PRODUCT, familyProductId
    };
}
export function updateFamilyProductFdes(values) {
    return {
        type: UPDATE_FAMILY_PRODUCT_FDES, values
    };
}
export function saveFamilyProductFdes(values, familyProductId) {
    return {
        type: SAVE_FAMILY_PRODUCT_FDES, values, familyProductId
    };
}
export function saveFamilyProductFdesSuccess(fdes) {
    return {
        type: SAVE_FAMILY_PRODUCT_FDES_SUCCESS, fdes
    };
}
export function saveFamilyProductFdesFailed(fdes) {
    return {
        type: SAVE_FAMILY_PRODUCT_FDES_FAILED, fdes
    };
}
export function loadModelForFamilyProduct(familyProductId) {
    return {
        type: LOAD_MODEL_FOR_FAMILY_PRODUCT, familyProductId
    };
}
export function cleanModels() {
    return {
        type: CLEAN_MODELS
    };
}