import React, {useEffect, useState} from 'react';
import Plyr from "plyr-react";
import 'plyr-react/plyr.css'
import CollapsableCard from "../../../common/components/CollapsableCard";
import {Link} from "react-router-dom";

const WelcomeContent = ({localConfigurator, referer}) => {
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        if (referer === "calculs")
            scroll()
    }, [referer]);

    function createMarkup () {
        return {__html: localConfigurator && localConfigurator.content && localConfigurator.content.homepage ? localConfigurator.content.homepage.text : ''};
    }

    function scroll () {
        const section = document.querySelector('#family-product-view');
        section.scrollIntoView({behavior: 'smooth', block: 'start', inline: "start"});
    };

    return (
        <CollapsableCard
            title={localConfigurator && localConfigurator.content && localConfigurator.content.homepage ? localConfigurator.content.homepage.title : ''} defaultOpen={isOpen} onChangeOpen={(newValue) => setIsOpen(newValue)}>
            <div className="row">
                <div className="col-xl-8">
                    <div className="card-text" dangerouslySetInnerHTML={createMarkup()} />
                    <div className="buttons-container">
                        {localConfigurator && localConfigurator.content && localConfigurator.content.homepage && localConfigurator.content.homepage.buttons && localConfigurator.content.homepage.buttons.button_1 &&
                            localConfigurator.content.homepage.buttons.button_1.isActive &&
                            <Link className="btn btn-outline-primary mr-2" to="/about">{localConfigurator.content.homepage.buttons.button_1.label ? localConfigurator.content.homepage.buttons.button_1.label : 'En Savoir plus'}</Link>
                        }
                        {localConfigurator && localConfigurator.content && localConfigurator.content.homepage && localConfigurator.content.homepage.buttons && localConfigurator.content.homepage.buttons.button_2 &&
                            localConfigurator.content.homepage.buttons.button_2.isActive &&
                            <button type="button" className="btn btn-outline-primary" onClick={() => scroll()}>{localConfigurator.content.homepage.buttons.button_2.label ? localConfigurator.content.homepage.buttons.button_2.label : 'Configurer une FDES'}</button>
                        }
                    </div>
                </div>
                {localConfigurator && localConfigurator.content && localConfigurator.content.homepage && localConfigurator.content.homepage.video && (
                    <div className="col-xl-4 mt-3 mt-xl-0">
                        <Plyr source={{
                            type: 'video',
                            sources: [
                                {
                                    src: localConfigurator.content.homepage.video,
                                    provider: 'youtube',
                                },
                            ],
                        }} />
                    </div>
                )}
            </div>
        </CollapsableCard>

    )

};

export default WelcomeContent;
