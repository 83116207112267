import {call, put, select, takeLatest} from 'redux-saga/effects'
import {
    getGroupsCall,
    getModelsForGroupCall,
    updateGroupCall,
    deleteGroupCall,
    getUsersByConfiguratorIdCall,
    postGroupCall,
    getGroupsByUserIdCall
} from "../../../APIClient";
import {
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_BY_USERID,
    GET_GROUPS_BY_USERID_SUCCESS,
    LOAD_MODEL_FOR_GROUP,
    LOAD_MODEL_FOR_GROUP_SUCCESS,
    LOAD_MODEL_FOR_GROUP_FAILED,
    SEARCH_USERS_BY_CONFIGURATOR,
    SEARCH_USERS_BY_CONFIGURATOR_SUCCESS,
    UPDATE_GROUP_FORM,
    DELETE_GROUP,
    SAVE_GROUP_FORM
} from '../constants'
import {formatPostGroupPayload} from '../../../utils/transformer';
import {disableGroupFormLoader} from '../../../common/actions/loader';
import {parseResponse} from "../../../utils/http";
import {toast} from "react-toastify";
import {push} from 'redux-first-history';

function* getGroups () {
    const getToken = (state) => state.user.informations.token;
    const getLocalConfigurator = (state) => state.configurator.localConfigurator;

    try {
        const token = yield select(getToken);
        const localConfigurator = yield select(getLocalConfigurator);
        const data = yield call(getGroupsCall, token, localConfigurator._id);
        yield call(parseResponse, data, GET_GROUPS_SUCCESS);
    } catch (error) {
        yield put({type: "GET_GROUPS_FAILED", error})
    }
}

function* getGroupsByUserId (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getGroupsByUserIdCall, token, action.userId);
        yield call(parseResponse, data, GET_GROUPS_BY_USERID_SUCCESS);
    } catch (error) {
        yield put({type: "GET_GROUPS_BY_USERID_FAILED", error})
    }
}

function* getGroupById (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getModelsForGroupCall, token, action.groupId);
        yield call(parseResponse, data, LOAD_MODEL_FOR_GROUP_SUCCESS);

    } catch (error) {
        yield put({type: LOAD_MODEL_FOR_GROUP_FAILED, error})
    }
}


function* saveGroup (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const formattedGroup = formatPostGroupPayload(action.values);
        const data = yield call(postGroupCall, token, formattedGroup);
        yield call(parseResponse, data, "SAVE_GROUP_FORM_SUCCESS");
        yield put(disableGroupFormLoader());
        yield put(push('/manage-group'));
        yield call(toast.success, "Groupe enregistrée");
    } catch (error) {
        yield put({type: "UPDATE_FAMILY_PRODUCT_FORM_FAILED", error})
        yield put(disableGroupFormLoader());
    }
}

function* patchGroup (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const formattedGroup = formatPostGroupPayload(action.values);
        const data = yield call(updateGroupCall, token, formattedGroup, action.values.id);
        yield call(parseResponse, data, "UPDATE_GROUP_FORM_SUCCESS");
        yield put(disableGroupFormLoader());
        yield put(push('/manage-group'));
        yield call(toast.success, "Modification enregistrée");
    } catch (error) {
        yield put({type: "UPDATE_FAMILY_PRODUCT_FORM_FAILED", error})
        yield put(disableGroupFormLoader());
    }
}

function* deleteGroup (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);

        yield call(deleteGroupCall, token, action.groupId);
        yield call(parseResponse, action.groupId, "DELETE_GROUP_SUCCESS");
        yield put(window.location.reload());
        yield call(toast.success, "Le groupe a été supprimé");

    } catch (error) {
        yield put({type: "DELETE_GROUP_ERROR", error})
    }
}

function* searchUsersByConfiguratorId (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getUsersByConfiguratorIdCall, token, action.configuratorId, action.values);
        yield call(parseResponse, data, SEARCH_USERS_BY_CONFIGURATOR_SUCCESS);

    } catch (error) {
        yield put({type: "SEARCH_USERS_BY_CONFIGURATOR_FAILED", error})
        yield call(toast.error, "Aucun utilisateur ne correspond à votre recherche");
    }
}

export const manageGroupSagas = [
    takeLatest(GET_GROUPS, getGroups),
    takeLatest(GET_GROUPS_BY_USERID, getGroupsByUserId),
    takeLatest(LOAD_MODEL_FOR_GROUP, getGroupById),
    takeLatest(UPDATE_GROUP_FORM, patchGroup),
    takeLatest(DELETE_GROUP, deleteGroup),
    takeLatest(SAVE_GROUP_FORM, saveGroup),
    takeLatest(SEARCH_USERS_BY_CONFIGURATOR, searchUsersByConfiguratorId)
];