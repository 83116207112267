import {connect} from 'react-redux';
import Header from '../../layout/Header';
import history from '../../routes/history';
import {disconnectUser, openLogin} from "../actions/user";

export default connect(
    (state) => ({
        isAuthenticated: state.user.isAuthenticated,
        userInformations: state.user.informations,
        loginModalIsOpen: state.user.loginModalIsOpen,
        localConfigurator: state.configurator.localConfigurator
    }),
    (dispatch) => ({
        openLoginModal: () => {
            dispatch(openLogin());
        },
        disconnectUser: () => {
            dispatch(disconnectUser());
            dispatch(history.push('/'));
        }
    })

)(Header);