import {connect} from 'react-redux';
import ManageGroup from '../components/ManageGroup';
import {getGroups, deleteGroup} from '../actions';

export default connect(
    (state) => ({
        groups: state.manageGroup.groups
    }),
    (dispatch) => ({
        getGroupsRequest: () => {
            dispatch(getGroups());
        },
        deleteGroupRequest: (groupId) => {
            dispatch(deleteGroup(groupId));
        }
    })

)(ManageGroup);