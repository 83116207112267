import React from 'react';
import Configurators from "../containers/Configurators";
import Breadcrumb from "../../../common/components/Breadcrumb";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";

const Configurator = () => {

    return (
        <>
            <Breadcrumb title="Configurateurs" createLink="/configurators/create">
            </Breadcrumb>
            <Configurators/>
        </>
    )

};

export default withPermissions(Configurator, PERMISSION_SUPER_ADMIN);