import React from 'react';
import {Formik} from "formik";
import * as Yup from "yup";

const FormLogin = ({sendFormLogin, loginAttempt, resentValidation, error}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={(values, {setSubmitting}) => {
        setTimeout(() => {
          setSubmitting(false);
          sendFormLogin(values);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Email invalide').required("Champ Requis"),
        password: Yup.string().required("Champ Requis"),
      })}>
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="row form-group">
              <div className="col-md-12">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && <div className="error-message danger">{errors.email}</div>}

              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <label htmlFor="password">Mot de passe</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {errors.password && touched.password && <div className="error-message danger">{errors.password}</div>}
              </div>
            </div>
            {error &&
              <div className="form-group danger darken-1">
                {error}
              </div>
            }

            {loginAttempt && loginAttempt.email !== '' && loginAttempt.isVerified === false && (
              <div className="form-group danger darken-1">
                Votre email {loginAttempt.email} n'a pas été activé.
                <button style={{color: '#9821e8', border: 'none', background: 'none'}} onClick={resentValidation}> Cliquer ici </button>
                pour renvoyer un email d'activation.
              </div>
            )}
            {loginAttempt && loginAttempt.email === '' && loginAttempt.isVerified === false && (
              <div className="form-group danger darken-1">
                Email envoyé.
              </div>
            )}
            <div className="form-group">
              <input type="submit" value="Se connecter" className="btn btn-primary" />
            </div>
          </form>)
      }}
    </Formik>);
};

export default FormLogin;
