import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from "redux-first-history";
import {createRootReducer} from '../reducers';
import {rootSaga} from "../saga";

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loader', 'router', 'context']
};

const {createReduxHistory, routerMiddleware} = createReduxHistoryContext({history: createBrowserHistory()});
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistReducer(persistConfig, createRootReducer),
  middleware: [routerMiddleware, sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export const history = createReduxHistory(store);