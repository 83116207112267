import React, {useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import {Formik} from "formik";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Link} from "react-router-dom";
import Loader from "../../../common/components/Loader";
import ReactTooltip from "react-tooltip";

const ManageGroupForm = ({saveGroupForm, updateGroupForm, searchUsersByConfigurator, cleanUsers, group, familyProducts, manageGroupFormIsLoading, configurators, localConfigurator, users, action, getConfiguratorsRequest}) => {

    const [configurator, setConfigurator] = useState({});
    const [searchUser, setSearchUser] = useState("");
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        if (action !== "CREATE")
            setConfigurator(configurators.find(x => x.id === group?.configurator))
        else
            setConfigurator(configurators.find(x => x.id === localConfigurator._id))
    }, [configurators, group, action, localConfigurator._id])

    useEffect(() => {
        getConfiguratorsRequest();
    }, [getConfiguratorsRequest]);

    function handleFamilyProductChange (familyProduct, setFieldValue, values, event) {
        let familyProducts = [...values.productFamilies];
        if (familyProducts.map(x => x.id).includes(familyProduct._id)) {
            familyProducts = familyProducts.filter(x => x.id !== familyProduct._id);
            if (familyProduct.children && familyProduct.children.length > 0) {
                familyProducts = familyProducts.filter(x => x.id !== familyProduct.children.map(children => children._id));
            }
            familyProducts.push({
                isVisible: event.target.id === "isVisible" ? !!values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible ? !values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible : true : values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible,
                isAccessible: event.target.id === "isAccessible" ? !!values?.productFamilies?.find(x => x.id === familyProduct._id)?.isAccessible ? !values?.productFamilies?.find(x => x.id === familyProduct._id)?.isAccessible : true : false,
                id: familyProduct._id
            })
        } else {
            familyProducts.push({
                isVisible: event.target.id === "isVisible" ? !!values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible ? !values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible : true : !!values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible,
                isAccessible: event.target.id === "isAccessible" ? !!values?.productFamilies?.find(x => x.id === familyProduct._id)?.isAccessible ?
                    !values?.productFamilies?.find(x => x.id === familyProduct._id)?.isAccessible : true : false,
                id: familyProduct._id
            })
        }
        setFieldValue('productFamilies', familyProducts);
    }

    function displayFamilyProduct (familyProduct, paddingRem, setFieldValue, isChild, values, errors, touched) {
        return <>
            {values.familyProducts.map(x => x.id).includes(familyProduct._id) && (
                < tr className={`text-start align-items-center`} key={familyProduct?._id}>
                    {//eslint-disable-next-line
                        <td className={!isChild ? ' fw-bold bg-blue-grey bg-lighten-4 p-1' : ' ' + `ml-1 pl-${paddingRem}`}>
                            {familyProduct?.name}
                        </td>
                    }
                    <td>
                        <input id='isVisible' checked={values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible || false}
                            onChange={(e) => handleFamilyProductChange(familyProduct, setFieldValue, values, e)}
                            type="checkbox" value={values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible || false} className='pt-1' />
                    </td>
                    <td>
                        <div data-tip={"Vous devez d'abord rendre la famille de produit visible"} data-tip-disable={values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible}>
                            <input id='isAccessible' checked={values?.productFamilies?.find(x => x.id === familyProduct._id)?.isAccessible || false}
                                onChange={(e) => handleFamilyProductChange(familyProduct, setFieldValue, values, e)}
                                type="checkbox" value={values?.productFamilies?.find(x => x.id === familyProduct._id)?.isAccessible || false} className='pt-1'
                                disabled={!values?.productFamilies?.find(x => x.id === familyProduct._id)?.isVisible ? true : false}
                            />
                            <ReactTooltip
                                place="top"
                                type="info"
                                className="basic-tooltip"
                            />
                        </div>

                    </td>
                    {errors?.familyProduct?._id && touched?.familyProduct?._id &&
                        <div className="mt-1 danger">{errors.familyProduct._id}</div>}
                </tr>
            )}
            {
                familyProduct && familyProduct.children && familyProduct.children.length > 0 && (
                    familyProduct.children.map(children => !isChild ? displayFamilyProduct(children, paddingRem + 2, setFieldValue, true, values) :
                        displayFamilyProduct(children, paddingRem + 2, setFieldValue, true, values)))
            }
        </>
    }

    function handleDeleteUser (user, setFieldValue, values) {
        let users = [...values.includeUsers];
        users = users.filter(x => x?._id !== user?._id)
        setFieldValue('includeUsers', users);
    }

    function displayUser (user, setFieldValue, values, errors, touched) {
        return <>
            < tr className={`text-start align-items-center`} key={user?._id}>
                <td className={"ml-1"}>
                    {user?.lastName}
                </td>
                <td>
                    {user?.firstName}
                </td>
                <td>
                    {user?.email}
                </td>
                <td>
                    {user?.type}
                </td>
                <td>
                    <Button variant="danger" onClick={() => handleDeleteUser(user, setFieldValue, values)}><i className="feather icon-trash-2" /></Button>
                </td>
                {errors?.user?._id && touched?.user?._id &&
                    <div className="mt-1 danger">{errors.user._id}</div>}
            </tr>
        </>
    }

    function displayListUsers (values, setFieldValue) {
        if (users?.length !== 0 && focus === true)
            return <>
                <table className="table border border-secondary">
                    <thead>
                        <tr className="text-start align-items-center">
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Email</th>
                            <th>Type</th>
                            <th>Ajouter</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.length > 0 &&
                            (
                                users.filter(x => !values?.includeUsers?.map(children => children?._id).includes(x._id)).map(user =>
                                    < tr className={`text-start align-items-center`} key={user?._id}>
                                        <td className={"ml-1"}>
                                            {user?.lastName}
                                        </td>
                                        <td>
                                            {user?.firstName}
                                        </td>
                                        <td>
                                            {user?.email}
                                        </td>
                                        <td>
                                            {user?.type}
                                        </td>
                                        <td>
                                            <Button className="btn btn-primary" onClick={() => addUser(user, setFieldValue, values)}><i className="feather icon-plus" /></Button>
                                        </td>
                                    </tr>
                                )
                            )}
                    </tbody>
                </table>
            </>
    }

    function addUser (user, setFieldValue, values) {
        let includeUsers = [...values.includeUsers];
        if (includeUsers) {
            includeUsers.push(user);
            setFocus(!focus);
            if (searchUser < 4)
                cleanUsers();
        }
        setFieldValue('includeUsers', includeUsers);
    }

    function handleCustomBlur (event) {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setFocus(!focus);
        }
    }

    function handleChangeOnFocus () {
        setFocus(!focus);
        if (searchUser.length === 0)
            cleanUsers();
    }


    function handleSearchUser (value) {
        setSearchUser(value);
        if (value.length >= 3) {
            searchUsersByConfigurator(configurator._id, value);
        } else {
            cleanUsers();
        }
    }

    return (
        <>
            <Loader isActive={manageGroupFormIsLoading} />
            <Formik
                enableReinitialize
                initialValues={{
                    id: group && group._id ? group._id : 0,
                    title: group && group.title ? group.title : '',
                    configuratorId: configurator && configurator._id ? configurator._id : [],
                    familyProducts: configurator && configurator.productFamilies ? configurator.productFamilies : [],
                    productFamilies: group && group.productFamilies ? group.productFamilies : [],
                    includeUsers: group && group.includes && group.includes.users ? group.includes.users : [],
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        setSubmitting(false);
                        action === "CREATE" ? saveGroupForm(values) : updateGroupForm(values);
                    }, 500);
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required("Champ Requis"),
                    familyProducts: Yup.array(),
                    includeUsers: Yup.array(),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    } = props;

                    return (
                        <div className="card">
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <form className="form form-horizontal striped-rows form-bordered"
                                        onSubmit={handleSubmit}>
                                        <ul className="nav nav-tabs nav-underline"
                                            role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="active-tab1"
                                                    data-toggle="tab"
                                                    href="#groupForm" aria-controls="groupForm-tab" role="tab"
                                                    aria-selected="true">Informations générales du groupe</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link`} id="link-tab2"
                                                    data-toggle="tab"
                                                    href="#manageFamily"
                                                    aria-controls="manageFamily-tab" role="tab" aria-selected="false">Gestion des familles visibles/accessibles</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link`} id="link-tab1"
                                                    data-toggle="tab"
                                                    href="#manageMembers"
                                                    aria-controls="manageMembers-tab" role="tab" aria-selected="false">Gestion des membres du groupes</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="groupForm"
                                                className={`form-body tab-pane active`}
                                                aria-labelledby="groupForm-tab" role="tabpanel">
                                                <div className="form-body">
                                                    <div className="form-group row has-danger">
                                                        <label htmlFor="title" className="col-md-3 label-control">Nom</label>
                                                        <div className="col-md-9">
                                                            <input
                                                                type="text"
                                                                id="title"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.title}
                                                            />

                                                            {errors.title && touched.title &&
                                                                <div className="mt-1 danger">{errors.title}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="manageFamily" className="tab-pane"
                                                aria-labelledby="manageFamily-tab"
                                                role="tabpanel">
                                                <div className="form-body">
                                                    <table className="table">
                                                        <thead>
                                                            <tr className="text-start align-items-center">
                                                                <th>Nom</th>
                                                                <th>Visible</th>
                                                                <th>Accessible</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {familyProducts && familyProducts.length > 0 && (
                                                                familyProducts.map(familyProduct =>
                                                                    displayFamilyProduct(familyProduct, 0, setFieldValue, false, values, errors, touched)
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div id="manageMembers" className={`tab-pane`}
                                                aria-labelledby="manageMembers-tab"
                                                role="tabpanel">
                                                <div className="form-body">
                                                    <div className="mx-1 my-1" onBlur={(e) => handleCustomBlur(e)}>
                                                        <input
                                                            id='searchUser'
                                                            type="text"
                                                            value={searchUser}
                                                            onFocus={() => {handleChangeOnFocus()}}
                                                            onChange={(e) => {handleSearchUser(e.target.value)}}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            placeholder="Rechercher un utilisateur"
                                                        />
                                                        <div className="position-absolute bg-light rounded mw-100" style={{zIndex: 1000}}>
                                                            {displayListUsers(values, setFieldValue)}
                                                        </div>
                                                    </div>
                                                    <table className="table">
                                                        <thead>
                                                            <tr className="text-start align-items-center">
                                                                <th>Nom</th>
                                                                <th>Prénom</th>
                                                                <th>Email</th>
                                                                <th>Type</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values && values.includeUsers.length > 0 && (
                                                                values.includeUsers.map(user =>
                                                                    displayUser(user, setFieldValue, values, errors, touched)
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-actions right">
                                            <Link to="/manage-group">
                                                <button type="reset" className="btn btn-warning mr-1">
                                                    <i className="feather icon-x" /> Annuler
                                                </button>
                                            </Link>
                                            <button type="submit" className="btn btn-primary">
                                                <i className="fa fa-check-square-o" /> Enregistrer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik>

        </>
    )

};

export default ManageGroupForm;
