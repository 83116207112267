import {connect} from 'react-redux';
import SyntheticResult from "../components/SyntheticResult";
import {changeSyntheticResultForm} from "../actions";
import {getResultForIndicatorStep, getMeasureForIndicatorSelected} from "../selectors";

export default connect(
    (state) => ({
        steps: state.fdes.references.steps,
        additionalResults: state.fdes.additionalResults,
        localConfigurator: state.configurator.localConfigurator,
        indicators: state.fdes.references.indicators,
        indicatorValue: state.fdes.synthetic.fields.indicator,
        stepValue: state.fdes.synthetic.fields.step,
        result: getResultForIndicatorStep(state),
        measure: getMeasureForIndicatorSelected(state)

    }),
    (dispatch) => ({
        onChangeSyntheticResultForm: (field, value) => {
            dispatch(changeSyntheticResultForm(field, value))
        },
    })

)(SyntheticResult);