import {connect} from 'react-redux';
import FamilyProductForm from '../components/FamilyProductForm';
import {saveFamilyProductForm, updateFamilyProductForm} from "../actions";

export default connect(
    (state) => ({
        familyProductFormIsLoading: state.loader.familyProductFormIsLoading,
    }),
    (dispatch) => ({
        saveFamilyProductForm: (values) => {
            dispatch(saveFamilyProductForm(values))
        },
        updateFamilyProductForm: (values) => {
            dispatch(updateFamilyProductForm(values))
        },
    })

)(FamilyProductForm);