import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {Link} from 'react-router-dom';
import DeleteModal from "../../../common/components/DeleteModal";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {userTypeKeyToLabel} from "../../../utils/transformer";

const UserRaw = ({user, deleteUser}) => {

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    return (
        <>
            <DeleteModal
                isOpen={deleteModalIsOpen}
                onClose={setDeleteModalIsOpen}
                onSubmit={()=>deleteUser(user.id)}
            />
            <tr>
                <td>{userTypeKeyToLabel[user.type]}</td>
                <td>{user.lastName}</td>
                <td>{user.firstName}</td>
                <td>{user.email}</td>
                <td>{user.createdAt && format(parseISO(user.createdAt),'dd/MM/yyyy HH:mm')}</td>
                <td>
                    <div className="d-flex"><Link
                        to={`/users/edit/${user.id}`}>
                        <Button variant="success" className="mr-25"><i className="feather icon-edit-1"/> </Button></Link>{' '}
                        <Button variant="danger" onClick={() => setDeleteModalIsOpen(true)}><i className="feather icon-trash-2"/></Button>
                    </div>
                </td>
            </tr>

        </>
    )

};

export default UserRaw;
