import React from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import Faq from "../containers/Faq";

const FaqPage = () => {

    return (
        <>
            <Breadcrumb title="Foire aux questions">
            </Breadcrumb>
            <Faq />
        </>
    )

};

export default FaqPage