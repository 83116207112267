export const LOAD_FDES_PARAMETERS = 'LOAD_FDES_PARAMETERS';
export const GET_FDES_PARAMETERS_SUCCESS = 'GET_FDES_PARAMETERS_SUCCESS';
export const CHANGE_FDES_PARAMETER_FORM = 'CHANGE_FDES_PARAMETER_FORM';
export const SAVE_FDES_FORM = 'SAVE_FDES_FORM';
export const SAVE_FDES_FORM_GOOGLE = 'SAVE_FDES_FORM_GOOGLE';
export const SAVE_CURRENT_FDES_ID = 'SAVE_CURRENT_FDES_ID';
export const SAVE_FDES_FORM_SUCCESS = 'SAVE_FDES_FORM_SUCCESS';
export const SAVE_FDES_FORM_FAILED = 'SAVE_FDES_FORM_FAILED';
export const LOAD_REFERENCE = 'LOAD_REFERENCE';
export const LOAD_REFERENCE_SUCCESS = 'LOAD_REFERENCE_SUCCESS';
export const LOAD_REFERENCE_FAILED = 'LOAD_REFERENCE_FAILED';
export const CHANGE_SYNTHETIC_RESULT_FORM = 'CHANGE_SYNTHETIC_RESULT_FORM';
export const CHANGE_MODULE_RESULT_FORM = 'CHANGE_MODULE_RESULT_FORM';
export const CHANGE_CALCUL_NAME_FORM = 'CHANGE_CALCUL_NAME_FORM';
export const SAVE_FDES_RESULT = 'SAVE_FDES_RESULT';
export const SAVE_FDES_RESULT_SUCCESS = 'SAVE_FDES_RESULT_SUCCESS';
export const SAVE_FDES_RESULT_FAILED = 'SAVE_FDES_RESULT_FAILED';
export const GENERATE_FDES_XML = 'GENERATE_FDES_XML';
export const CHANGE_PROJECT_NAME_FORM = 'CHANGE_PROJECT_NAME_FORM';
export const LOAD_CALCUL_DATA = 'LOAD_CALCUL_DATA';
export const UPDATE_CALCUL = 'UPDATE_CALCUL';
export const UPDATE_CALCUL_SUCCESS = 'UPDATE_CALCUL_SUCCESS';
export const UPDATE_CALCUL_FAILED = 'UPDATE_CALCUL_FAILED';
export const SET_LAST_SAVED_USER_FDES_ID = 'SET_LAST_SAVED_USER_FDES_ID';
export const CLEAN_NAME_FORM = 'CLEAN_NAME_FORM';
export const UPGRADE_CALCUL = 'UPGRADE_CALCUL';
export const GET_FDES_METADATA = 'GET_FDES_METADATA';
export const GET_FDES_METADATA_CALCUL_RAW = "GET_FDES_METADATA_CALCUL_RAW";
export const GET_FDES_METADATA_CALCUL_RAW_SUCCESS = "GET_FDES_METADATA_CALCUL_RAW_SUCCESS";
export const GET_FDES_METADATA_SUCCESS = 'GET_FDES_METADATA_SUCCESS'
export const POST_FDES_FILE = 'POST_FDES_FILE';
export const POST_FDES_FILE_SUCCESS = 'POST_FDES_FILE_SUCCESS';
export const POST_FDES_FILE_FAILED = 'POST_FDES_FILE_FAILED';
export const LOAD_CALCUL_DATA_SUCCESS = 'LOAD_CALCUL_DATA_SUCCESS';
export const CLEAR_CALCUL_INFORMATIONS = 'CLEAR_CALCUL_INFORMATIONS';
export const SEND_XML_TO_EDEC = 'SEND_XML_TO_EDEC';