import React from 'react';
import ManageGroup from "../containers/ManageGroup";
import Breadcrumb from "../../../common/components/Breadcrumb";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";

const ManageGroupePage = () => {

    return (
        <>
            <Breadcrumb title="Gestion des groupes" createLink="/manage-group/create">
            </Breadcrumb>
            <ManageGroup/>
        </>
    )

};

export default withPermissions(ManageGroupePage, PERMISSION_SUPER_ADMIN);
