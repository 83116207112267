import React, {useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import styled from "styled-components";
import chroma from "chroma-js";
import {Helmet} from "react-helmet";
import Header from "../common/containers/Header";
import Menu from "../common/components/Menu";
import {noMenuLayoutLocations, noCheckLoginLocations} from '../utils/http';


const ColorDiv = styled.div`

.navbar-dark, .navbar-semi-light{
        background-color: ${(props) => (props.headerColor !== null ? props.headerColor : "#404E67")};
}
.navbar-semi-dark .navbar-header{
        background-color: ${(props) => (props.headerColor !== null ? props.headerColor : "#404E67")};
}
.navbar-dark .navbar-nav .nav-link,
.navbar-semi-light .navbar-nav .nav-link,
{
    color: ${(props) => (props.headerColor !== null && chroma(props.headerColor).luminance() > 0.5 ? "black" : "#FFF")};
    }
    .bg-primary{
        background-color: ${(props) => (props.primaryColor !== null ? props.primaryColor : "#00B5B8")} !important;
    }
    .fdes .col .badge-white-title{
        color: ${(props) => (props.primaryColor !== null ? props.primaryColor : "#00B5B8")} !important;
    }
    .basic-tooltip{
        background-color: ${(props) => (props.secondaryColor !== null ? props.secondaryColor : "#00B5B8")} !important;
    }
    .basic-tooltip.place-right::after{
        border-right-color: ${(props) => (props.secondaryColor !== null ? props.secondaryColor : "#00B5B8")} !important;
    }
    .basic-tooltip.place-top::after{
        border-top-color: ${(props) => (props.secondaryColor !== null ? props.secondaryColor : "#00B5B8")} !important;
    }
    .basic-tooltip.place-bottom::after{
        border-bottom-color: ${(props) => (props.secondaryColor !== null ? props.secondaryColor : "#00B5B8")} !important;
    }
    .basic-tooltip.place-left::after{
        border-left-color: ${(props) => (props.secondaryColor !== null ? props.secondaryColor : "#00B5B8")} !important;
    }
    
    .main-menu .navigation li.active
    { 
    border-left: 4px solid ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    .main-menu .navigation>li.active>a{
    color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    .dropdown-item.active, .dropdown-item:active{
    color:white;
      background-color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    a{
        color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    .btn-outline-primary{
        color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
        border-color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    .btn-outline-primary:hover{
        background-color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        background-color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle{
        background-color: ${(props) => (props.textColor !== null ? chroma(props.textColor).darken(1) : "#00B5B8")};
        border-color: ${(props) => (props.textColor !== null ? chroma(props.textColor).darken(1) : "#00B5B8")};
    }
    .btn-primary{
        background-color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")}!important;
        border-color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")}!important;
    } 
    .nav.nav-tabs.nav-underline{
        border-bottom: 1px solid ${(props) => (props.textColor !== null ? props.textColor : "#2DCEE3")};
    }
    .nav.nav-tabs.nav-underline .nav-item a.nav-link{
        color: ${(props) => (props.textColor !== null ? props.textColor : "#2DCEE3")};
    }
    .nav.nav-tabs.nav-underline .nav-item a.nav-link:before{
        background: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")};
    }
    .form-control:focus{
        border-color: ${(props) => (props.textColor !== null ? props.textColor : "#00B5B8")}!important;
    }
    .dropdown-item{
    color:#2A2E30;
    }
    
    .slider-container{
    .noUi-horizontal .noUi-handle{
            border: 5px solid ${(props) => (props.headerColor !== null ? props.headerColor : "#00B5B8")};
    }
    }
`;

const Layout = ({children, getLocalConfigurator, getFamilyProductsRequest, location, localConfigurator, checkIfLogged, getLocalEDECIntegration}) => {

    useEffect(() => {
        // check if user is logged in unless the route is into the noCheckLoginLocations array
        if (!noCheckLoginLocations.includes(location.pathname)) {
            checkIfLogged();
        }
        getLocalConfigurator();
        getFamilyProductsRequest();
        getLocalEDECIntegration();
        //eslint-disable-next-line
    }, [getLocalConfigurator, getFamilyProductsRequest]);
    
    return (noMenuLayoutLocations.includes(location.pathname) ?
        <ColorDiv
            headerColor={localConfigurator && localConfigurator.color ? localConfigurator.color : null}
            primaryColor={localConfigurator && localConfigurator.theme && localConfigurator.theme.colors ? localConfigurator.theme.colors.primary : null}
            secondaryColor={localConfigurator && localConfigurator.theme && localConfigurator.theme.colors ? localConfigurator.theme.colors.secondary : null}
            textColor={localConfigurator && localConfigurator.theme && localConfigurator.theme.colors ? localConfigurator.theme.colors.primaryText : null}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>{localConfigurator && localConfigurator.title !== "" ? localConfigurator.title : "Ma FDES"}</title>
            </Helmet>
            <div id="main" className="app-content content">
                <div className="content-overlay" />
                <div className="content-wrapper">
                    {children}
                    <ToastContainer theme='colored' />
                </div>
            </div>
        </ColorDiv>
        :
        <ColorDiv
            headerColor={localConfigurator && localConfigurator.color ? localConfigurator.color : null}
            primaryColor={localConfigurator && localConfigurator.theme && localConfigurator.theme.colors ? localConfigurator.theme.colors.primary : null}
            secondaryColor={localConfigurator && localConfigurator.theme && localConfigurator.theme.colors ? localConfigurator.theme.colors.secondary : null}
            textColor={localConfigurator && localConfigurator.theme && localConfigurator.theme.colors ? localConfigurator.theme.colors.primaryText : null}
        >

            <Helmet>
                <meta charSet="utf-8" />
                <title>{localConfigurator && localConfigurator.title !== "" ? localConfigurator.title : "Ma FDES"}</title>
            </Helmet>
            <Header />
            <Menu pathname={location ? location.pathname : '/'} localConfigurator={localConfigurator} />
            <div id="main" className="app-content content pt-4">
                <div className="content-wrapper">
                    {children}
                    <ToastContainer theme='colored' />
                </div>
            </div>
        </ColorDiv>

    );
};

export default Layout;