import React, {useEffect} from 'react';
import FamilyProductParent from './FamilyProductParent';
import './FamilyProductView.scss';

const FamilyProductView = ({familyProducts, familyProductsFilters, localConfigurator, aggregatedFamilyProducts, getAggregatedFamilyProducts}) => {

    useEffect(() => {
        getAggregatedFamilyProducts();
    }, [getAggregatedFamilyProducts])

    function createMarkup () {
        return {__html: localConfigurator?.content?.productFamilies ? localConfigurator.content.productFamilies.text : ''};
    }

    return (
        <section id='family-product-view'>
            <div className="row">
                <div className="col-12 mt-5">
                    {localConfigurator?.content?.productFamilies?.title && localConfigurator.content.productFamilies.title !== '' && (
                        <h4 className="text-uppercase">{localConfigurator.content.productFamilies.title}</h4>
                    )}
                    {localConfigurator?.content?.productFamilies?.text && localConfigurator.content.productFamilies.text !== '' && (
                        <div dangerouslySetInnerHTML={createMarkup()} />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card-columns">
                        {familyProductsFilters?.length > 0 && (
                            familyProductsFilters.map(familyProductId => {
                                if (familyProducts.find(x => x._id === familyProductId))
                                    return <FamilyProductParent familyProduct={familyProducts.find(x => x._id === familyProductId)} key={familyProductId} familyProductsFilters={familyProductsFilters} aggregatedFamilyProducts={aggregatedFamilyProducts} />
                                else return ''
                            }
                            )
                        )}
                    </div>
                </div>
            </div>
        </section>
    )

};

export default FamilyProductView;
