import React from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_USER} from "../../user/constants/permissions";
import Calculs from "../containers/Calculs";

const CalculPage = () => {
    return (
        <>
            <Breadcrumb title="Mes calculs" createCalculLink="/?referer=calculs" >
            </Breadcrumb>
            <Calculs />
        </>
    )

};
export default withPermissions(CalculPage, PERMISSION_USER);
