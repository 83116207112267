import {call, put, select, takeLatest} from "redux-saga/effects";
import {
    CHANGE_CALCUL_PAGE,
    CHANGE_CALCUL_SORT, DELETE_CALCUL, DELETE_CALCUL_SUCCESS, DUPLICATE_CALCUL, DUPLICATE_CALCUL_SUCCESS,
    LOAD_CALCUL, LOAD_CALCUL_FAILED, LOAD_CALCUL_SUCCESS,
    GET_USER_CALCULS_FAILED,
    GET_USER_CALCULS_SUCCESS,
    LOAD_USER_CALCULS,
    POST_CALCUL_SUCCESS,
    CLEAR_CALCUL,
    CLEAR_CALCUL_OBJECT
} from "../constants";
import {deleteCalculCalls, duplicateCalculCalls, getCalculCall, getUserCalculsCall, upgradeCalculCalls} from "../../../APIClient";
import {parseResponse} from "../../../utils/http";
import {deleteCalculFailed} from "../actions";
import {toast} from "react-toastify";
import {UPGRADE_CALCUL, LOAD_CALCUL_DATA, CLEAR_CALCUL_INFORMATIONS} from "../../fdes/constants";
import {disableCalculLoader, enableCalculLoader} from "../../../common/actions/loader";

function* getUserCalculs () {
    const getToken = (state) => state.user.informations.token;
    const getUserId = (state) => state.user.informations._id;
    const getCalculSort = (state) => state.calculs.sort;
    const getCalculLimit = (state) => state.calculs.calculLimit;
    const getCalculPage = (state) => state.calculs.currentPage;
    const getLocalConfigurator = (state) => state.configurator.localConfigurator;

    try {
        const token = yield select(getToken);
        const userId = yield select(getUserId);
        const sort = yield select(getCalculSort);
        const page = yield select(getCalculPage);
        const limit = yield select(getCalculLimit);
        const localConfigurator = yield select(getLocalConfigurator);

        const data = yield call(getUserCalculsCall, token, userId, sort, page, limit, localConfigurator._id);
        yield call(parseResponse, data, GET_USER_CALCULS_SUCCESS);
    } catch (error) {
        yield put({type: GET_USER_CALCULS_FAILED, error})
    }
}

function* getCalcul (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);

        const data = yield call(getCalculCall, token, action.calculId);
        
        yield call(parseResponse, data, LOAD_CALCUL_SUCCESS);
        yield call(parseResponse, data, LOAD_CALCUL_DATA)
    } catch (error) {
        yield call(toast.error, "Erreur lors du chargement du calcul");
        yield put({type: LOAD_CALCUL_FAILED, error})
        yield put({type: LOAD_CALCUL_DATA, error})
    }
}

function* deleteCalcul (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);

        const data = yield call(deleteCalculCalls, token, action.calculId);
        yield call(parseResponse, data, DELETE_CALCUL_SUCCESS);
        yield call(toast.success, "Calcul supprimé");
    } catch (error) {
        yield call(toast.error, error.message);
        yield put(deleteCalculFailed())

    }
}

function* postUpgradeCalcul (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        yield put(enableCalculLoader())

        yield call(upgradeCalculCalls, token, action.calculId);
        const data = yield call(getCalculCall, token, action.calculId);
        yield call(parseResponse, data, POST_CALCUL_SUCCESS);

        yield call(toast.success, "Calcul mis à jour");
        yield put(disableCalculLoader())
    } catch (error) {
        yield call(toast.error, error.message);
        yield put(disableCalculLoader())

    }
}

function* postDuplicateCalcul (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        yield put(enableCalculLoader())
        const data = yield call(duplicateCalculCalls, token, action.calculId);
        yield call(parseResponse, data, DUPLICATE_CALCUL_SUCCESS);

        yield call(toast.success, "Calcul dupliqué");
        yield put(disableCalculLoader());
        // refresh page after duplication
        setInterval(() => {
            window.location.reload();
        }, 1000);
    } catch (error) {
        yield call(toast.error, error.message);
        yield put(disableCalculLoader())

    }
}

function* clearClacul () {
    yield put({type: CLEAR_CALCUL_OBJECT});
    yield put({type: CLEAR_CALCUL_INFORMATIONS});
}

export const calculsSagas = [
    takeLatest(LOAD_USER_CALCULS, getUserCalculs),
    takeLatest(LOAD_CALCUL, getCalcul),
    takeLatest(CHANGE_CALCUL_SORT, getUserCalculs),
    takeLatest(CHANGE_CALCUL_PAGE, getUserCalculs),
    takeLatest(DELETE_CALCUL, deleteCalcul),
    takeLatest(UPGRADE_CALCUL, postUpgradeCalcul),
    takeLatest(DUPLICATE_CALCUL, postDuplicateCalcul),
    takeLatest(CLEAR_CALCUL, clearClacul)
];