import React from 'react';
import './header.scss';
import { Link } from "react-router-dom";
import { PERMISSION_ORG_ADMIN, PERMISSION_SUPER_ADMIN, userHasPermission } from "../modules/user/constants/permissions";
import {
  HEADER_CENTER,
  HEADER_LEFT,
  THEME_DARK, THEME_DEFAULT,
  THEME_LIGHT,
  THEME_SEMI_DARK,
  THEME_SEMI_LIGHT
} from "../utils/transformer";
import Login from "../common/containers/Login";
import ReactTooltip from "react-tooltip";

const Header = ({ isAuthenticated, localConfigurator, loginModalIsOpen, userInformations, openLoginModal, disconnectUser }) => {
  function getHeaderStyleClass() {
    if (localConfigurator && localConfigurator.theme && localConfigurator.theme.headerStyle) {
      switch (localConfigurator.theme.headerStyle) {
        case THEME_DARK:
          return 'navbar-dark';
        case THEME_LIGHT:
          return 'navbar-light';
        case THEME_SEMI_DARK:
          return 'navbar-semi-dark';
        case THEME_SEMI_LIGHT:
          return 'navbar-semi-light';
        default:
          return THEME_DEFAULT;

      }

    }

    return THEME_DEFAULT;
  }

  return (
    <header>
      <nav
        className={`header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-border ${getHeaderStyleClass()}`}>
        <div className="navbar-wrapper">
          <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item mobile-menu d-md-none mr-auto">
                {
                  // eslint-disable-next-line
                  <a className="nav-link nav-menu-main menu-toggle hidden-xs">
                  <i className="feather icon-menu font-large-1" /></a>
                }
              </li>
              <li className="nav-item"><Link to="/" className="navbar-brand">
                {localConfigurator && localConfigurator.logo && (localConfigurator.theme.logoPosition === HEADER_LEFT || localConfigurator.theme.logoPosition === undefined) && (
                  <img className="brand-logo" src={localConfigurator.logo} alt={localConfigurator.title} />
                )}
                {localConfigurator && localConfigurator.secondaryLogo && (localConfigurator.theme.secondaryLogoPosition === HEADER_LEFT || localConfigurator.theme.secondaryLogoPosition === undefined) && (
                  <img className="brand-logo" src={localConfigurator.secondaryLogo} alt={localConfigurator.title} />
                )}
              </Link>
              </li>
              <li className="nav-item d-md-none">
                {
                  // eslint-disable-next-line
                  <a className="nav-link open-navbar-container" data-toggle="collapse"
                    data-target="#navbar-mobile" data-parent="menu-toggle"> 
                    <i className="fa fa-ellipsis-v" /></a>
                }
              </li>
            </ul>
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse align-items-center justify-content-between" id="navbar-mobile">
              <ul className="nav navbar-nav float-left">
                <li className="nav-item d-none d-md-block">
                  {
                    // eslint-disable-next-line
                    <a className="nav-link nav-menu-main menu-toggle hidden-xs">
                      <i className="feather icon-menu" /></a>
                  }
                </li>
              </ul>
              {localConfigurator && localConfigurator.logo && localConfigurator.theme.logoPosition === HEADER_CENTER && (
                <ul className="nav navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="navbar-brand"><img className="brand-logo" src={localConfigurator.logo}
                      alt={localConfigurator.title} /></Link>
                  </li>
                </ul>
              )}
              {localConfigurator && localConfigurator.secondaryLogo && localConfigurator.theme.secondaryLogoPosition === HEADER_CENTER && (
                <ul className="nav navbar-nav">
                  <li className="nav-item">
                    <a href={localConfigurator.secondaryLogoUrl} target="_blank" rel="noopener noreferrer" className="navbar-brand"><img className="brand-logo" src={localConfigurator.secondaryLogo}
                      alt={localConfigurator.title} /></a>
                  </li>
                </ul>
              )}
              {isAuthenticated ?
                <ul className="nav navbar-nav float-right">
                {localConfigurator && localConfigurator.email && localConfigurator.email.adminContactEmail && (
                  <Link to="/contact" className=" nav-link" data-tip={"Nous contacter"} data-for={'contact_tip'}>
                    <i className="fa fa-envelope-o" />
                  </Link>
                )}
                  <>{
                    // eslint-disable-next-line
                    <a className="dropdown-toggle nav-link dropdown-user-link" href="#"
                      data-toggle="dropdown">
                      <span className="user-name">{userInformations ? userInformations.email : ''}</span>
                    </a>
                  }
                    <div className="dropdown-menu dropdown-menu-right">
                      <li><Link to="/account-setting" className="dropdown-item">
                        <i className="feather icon-user" /> Mon compte</Link></li>
                      {userInformations && userInformations.permissions && userInformations.permissions.length > 0 && userHasPermission(userInformations.permissions, PERMISSION_ORG_ADMIN) && (
                        <li><Link to="/users" className="dropdown-item"><i className="feather icon-users" /> Gestion des utilisateurs</Link></li>
                      )}
                      {userInformations && userInformations.permissions && userInformations.permissions.length > 0 && userHasPermission(userInformations.permissions, PERMISSION_SUPER_ADMIN) && (
                        <>
                          <li><Link to="/manage-group" className="dropdown-item"><i
                            className="fa fa-users" /> Gestion des groupes</Link></li>
                          <li><Link to="/family-product" className="dropdown-item"><i
                            className="fa fa-sitemap" /> Gestion des familles de produit</Link></li>
                          <li><Link to="/configurators" className="dropdown-item"><i className="fa fa-server" /> Gestion des
                            configurateurs</Link></li>
                        </>
                      )}
                      <div className="dropdown-divider" />
                      {
                        // eslint-disable-next-line
                        <a className="dropdown-item" href="#" onClick={() => disconnectUser()}><i
                          className="feather icon-power" /> Se déconnecter</a>
                      }
                    </div>
                  </>
                </ul>
                :
                <ul className="nav navbar-nav float-right">
                {localConfigurator && localConfigurator.email && localConfigurator.email.adminContactEmail && (
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link nav-menu-main" data-tip={"Nous contacter"} data-for={'contact_tip'}>
                      <i className="fa fa-envelope-o" />
                    </Link>
                  </li>
                  )}
                  <li className="nav-item">
                    {
                      localConfigurator.auth && localConfigurator.auth.find(o => o.strategy === "saml") ?
                        <a className="nav-link nav-menu-main" href={localConfigurator.auth.find(o => o.strategy === "saml").loginUrl}>{localConfigurator.auth.find(o => o.strategy === "saml").label}</a> :
                        // eslint-disable-next-line
                        <a className="nav-link nav-menu-main" onClick={() => openLoginModal()}>Se connecter </a>
                    }
                  </li>
                  {localConfigurator?.security?.allowRegister === true && (
                    <li className="nav-item"><Link className="nav-link nav-menu-main" to="/register">S'inscrire </Link></li>
                  )}
                </ul>

              }
            </div>
          </div>
        </div>
        <Login isOpen={loginModalIsOpen} />
      </nav>
        <ReactTooltip
            id='contact_tip'
            place="top"
            type="info"
            className="basic-tooltip"
        />
    </header>
  );
};

export default Header;
