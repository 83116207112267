import ContactPage from "../pages/Contact";
import FaqPage from "../pages/Faq";

const routes = [
    {
        path: '/contact',
        exact: true,
        element: ContactPage
    },
    {
        path: '/faq',
        exact: true,
        element: FaqPage
    },
];

export default routes;
