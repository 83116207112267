import React from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import UserForm from "../containers/UserForm";
import {Link} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_ORG_ADMIN} from "../constants/permissions";

const User = () => {

    return (
        <>
            <Breadcrumb title="Créer un utilisateur">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/users">Utilisateurs</Link></li>
                    <li className="breadcrumb-item active">Créer un utilisateur</li>
                </ol>
            </Breadcrumb>
            <UserForm/>
        </>
    )

};
export default withPermissions(User, PERMISSION_ORG_ADMIN);