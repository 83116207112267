import {connect} from 'react-redux';
import FdesEditPage from "../pages/FdesEdit";
import {
    loadFdesParameters,
    saveFdesForm,
    saveFdesGoogleForm,
    loadReference,
    saveFdesResult,
    generateFdesXml,
    loadCalculData, updateCalcul, setLastSavedUserFdesId, cleanNameForm, upgradeCalcul, getFdesMetaData
} from "../actions";
import {getFdesFormIsEdited} from "../selectors";
import {getCalcul, sendXmlToEDEC} from '../../calcul/actions';

export default connect(
    (state) => ({
        user: state.user.informations,
        pdfReportIsLoading: state.loader.pdfReportIsLoading,
        xmlReportIsLoading: state.loader.xmlReportIsLoading,
        calculIsLoading: state.loader.calculIsLoading,
        familyProducts: state.familyProduct.familyProducts,
        formIsLoading: state.fdes.form.isLoading,
        isEdited: getFdesFormIsEdited(state),
        resultFormIsLoading: state.fdes.resultForm.isLoading,
        resultFormIsSaved: state.fdes.resultForm.isSaved,
        lastSavedUserFdesId: state.fdes.resultForm.lastSavedUserFdesId,
        metadata: state.fdes.metadata,
        calculs: state.calculs.calculs,
        calcul: state.calculs.calcul
    }),
    (dispatch) => ({
        loadFdesParameters: (fdesId, isRealTime, calcul) => {
            dispatch(loadFdesParameters(fdesId, isRealTime, calcul));
        },
        saveFdesForm: () => {
            dispatch(saveFdesForm());
        },
        saveFdesGoogleForm: () => {
            dispatch(saveFdesGoogleForm());
        },
        loadReference: (standard) => {
            dispatch(loadReference(standard));
        },
        saveFdesResult: () => {
            dispatch(saveFdesResult());
        },
        generateXml: (familyProductId) => {
            dispatch(generateFdesXml(familyProductId));
        },
        loadCalculData: (calcul) => {
            dispatch(loadCalculData(calcul))
        },
        updateCalcul: (calculId) => {
            dispatch(updateCalcul(calculId));
        },
        setLastSavedUserFdesId: (calculId) => {
            dispatch(setLastSavedUserFdesId(calculId));
        },
        cleanNameForm: () => {
            dispatch(cleanNameForm())
        },
        onUpgradeCalcul: (calculId) => {
            dispatch(upgradeCalcul(calculId))
        },
        getFdesMetadata: (fdesId) => {
            dispatch(getFdesMetaData(fdesId));
        },
        getCalcul: (calculId) => {
            dispatch(getCalcul(calculId))
        },
        sendXmlToEDEC: (values) => {
            dispatch(sendXmlToEDEC(values))
        }
    })

)(FdesEditPage);