import { connect } from "react-redux";
import EDECForm from "../components/EDECForm";
import { updateUserEDECIntegration } from "../actions"

export default connect(
    (state) => ({
        user: state.user.informations,
        localEDECIntegration: state.configurator.localEDECIntegration
    }),
    (dispatch) => ({
        updateUserForm: (values) => {
            dispatch(updateUserEDECIntegration(values))
        }
    })
)(EDECForm);