import { connect } from "react-redux";
import ConfiguratorForm from "../components/ConfiguratorForm";
import {
  configuratorFormSend,
  configuratorFormUpdate,
  EDECConfiguratorFormSend,
  EDECConfiguratorFormUpdate
} from "../actions";

export default connect(
  (state) => ({
    familyProducts: state.familyProduct.familyProducts,
    isLoading: state.configurator.isLoading,
    EDECIntegration: state.configurator.EDECIntegration,
  }),
  (dispatch) => ({
    sendConfiguratorForm: (values) => {
      dispatch(configuratorFormSend(values));
    },
    configuratorFormUpdate: (values) => {
      dispatch(configuratorFormUpdate(values));
    },
    sendEDECConfiguratorForm: (values) => {
      dispatch(EDECConfiguratorFormSend(values));
    },
    EDECConfiguratorFormUpdate: (values) => {
      dispatch(EDECConfiguratorFormUpdate(values));
    },
  })
)(ConfiguratorForm);
