import React from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConfiguratorDeleteModal = ({isOpen, onClose, onSubmit}) => {

    return (
        <Modal show={isOpen} onHide={() => onClose()}>
            <Modal.Body>
                Confirmer la suppression ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" onClick={() => onClose()}>Annuler</Button>{' '}
                <Button variant="danger" onClick={() => onSubmit()}>Supprimer</Button>
            </Modal.Footer>
        </Modal>
    )

};

export default ConfiguratorDeleteModal;
