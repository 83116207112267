import {connect} from 'react-redux';
import Methodology from '../components/Methodology';
import {getMethodologyDownloadLinks} from "../selectors";

export default connect(
    (state) => ({
        localConfigurator: state.configurator.localConfigurator,
        methodologyDownloadLinks: getMethodologyDownloadLinks(state)

    }), null
)(Methodology);