export const NOMCALCUL = 'NOMCALCUL';
export const NOMPROJET = 'NOMPROJET';
export const INDICATEURRS = 'INDICATEURRS';
export const ETAPESRS = 'ETAPESRS';
export const INDICATEURM = 'INDICATEURM';
export const AFFICHAGERSLT = 'AFFICHAGERSLT';
export const CREATEPDF = 'CREATEPDF';
export const RESULTATSPARETAPES = 'RESULTATSPARETAPES';
export const GRAPHERESULTATS = 'GRAPHERESULTATS';
export const TABLEAUDETAIL = 'TABLEAUDETAIL';
export const DOWNLOADXMLCONFIG = 'DOWNLOADXMLCONFIG';