import React, { useEffect } from 'react';
import ManageGroupRaw from './ManageGroupRaw';

const ManageGroup = ({ getGroupsRequest, groups, deleteGroupRequest }) => {

    useEffect(() => {
        getGroupsRequest();
    }, [getGroupsRequest]);

    return (
        <div className="users-list-table">
            <div className="card">
                <div className="card-content">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table id="users-list-datatable" className="table">
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th />
                                        <th />
                                        <th />
                                        <th className="text-right pr-4">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groups && groups.length > 0 && (
                                        groups.map(group =>
                                            <ManageGroupRaw key={group._id} deleteGroupRequest={deleteGroupRequest} group={group} />
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default ManageGroup;
