import produce from "immer";
import {
  GET_CONFIGURATORS_SUCCESS,
  CONFIGURATOR_FORM_DELETE_SUCCESS,
  GET_LOCAL_CONFIGURATOR_SUCCESS,
  CONFIGURATOR_FORM_SEND,
  CONFIGURATOR_FORM_UPDATE,
  CONFIGURATOR_FORM_SUCCESS,
  CONFIGURATOR_FORM_FAILED,
  CONFIGURATOR_FORM_UPDATE_SUCCESS,
  CONFIGURATOR_FORM_UPDATE_FAILED,
  GET_EDEC_CONFIGURATOR_SUCCESS,
  EDEC_CONFIGURATOR_FORM_SEND,
  EDEC_CONFIGURATOR_FORM_SUCCESS,
  EDEC_CONFIGURATOR_FORM_FAILED,
  EDEC_CONFIGURATOR_FORM_UPDATE,
  EDEC_CONFIGURATOR_FORM_UPDATE_SUCCESS,
  EDEC_CONFIGURATOR_FORM_UPDATE_FAILED,
  EDEC_CONFIGURATOR_FORM_DELETE_SUCCESS,
  GET_LOCAL_EDEC_INTEGRATION_SUCCESS,
  GET_LOCAL_EDEC_INTEGRATION_FAILED
} from "../constants";

const initialState = {
  configurators: [],
  localConfigurator: {},
  EDECIntegration: {},
  localEDECIntegration: {},
  isLoading: false,
};

const contextReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case CONFIGURATOR_FORM_SEND:
      draft.isLoading = true;
      break;
    case CONFIGURATOR_FORM_UPDATE:
      draft.isLoading = true;
      break;
    case CONFIGURATOR_FORM_FAILED:
    case CONFIGURATOR_FORM_SUCCESS:
      draft.isLoading = false;
      break;
    case CONFIGURATOR_FORM_UPDATE_SUCCESS:
    case CONFIGURATOR_FORM_UPDATE_FAILED:
      draft.isLoading = false;
      break;
    case GET_CONFIGURATORS_SUCCESS:
      formatConfiguratorsResponse(draft, action.payload);
      draft.isLoading = false;
      break;
    case CONFIGURATOR_FORM_DELETE_SUCCESS:
      removeConfigurator(draft, action);
      draft.isLoading = false;
      break;
    case GET_LOCAL_CONFIGURATOR_SUCCESS:
      storeLocalConfigurator(draft, action.payload);
      draft.isLoading = false;
      break;
    case EDEC_CONFIGURATOR_FORM_SEND:
      draft.isLoading = true;
      break;
    case EDEC_CONFIGURATOR_FORM_UPDATE:
      draft.isLoading = true;
      break;
    case GET_EDEC_CONFIGURATOR_SUCCESS:
      formatEDECConfiguratorResponse(draft, action.payload);
      draft.isLoading = false;
      break;
    case EDEC_CONFIGURATOR_FORM_FAILED:
    case EDEC_CONFIGURATOR_FORM_SUCCESS:
      draft.isLoading = false;
      break;
    case EDEC_CONFIGURATOR_FORM_UPDATE_FAILED:
    case EDEC_CONFIGURATOR_FORM_UPDATE_SUCCESS:
      draft.isLoading = false;
      break;
    case EDEC_CONFIGURATOR_FORM_DELETE_SUCCESS:
      removeEDECIntegration(draft, action);
      break;
    case GET_LOCAL_EDEC_INTEGRATION_SUCCESS:
      storeLocalEDECIntegration(draft, action);
      draft.isLoading = false;
      break;
    case GET_LOCAL_EDEC_INTEGRATION_FAILED:
      draft.isLoading = false;
      break;
    default:
      return draft;
  }
});

function formatConfiguratorsResponse(draft, payload) {
  if (payload && payload.data && payload.data.length > 0) {
    draft.configurators = payload.data.map((configurator) => ({
      ...configurator,
      id: configurator._id,
    }));
  }
}

function removeConfigurator(draft, payload) {
  draft.configurators.splice(
    draft.configurators.findIndex(
      (configurator) => configurator.id === payload
    ),
    1
  );
}

function storeLocalConfigurator(draft, payload) {
  draft.localConfigurator = {
    ...payload,
    id: payload._id,
  };
}

function formatEDECConfiguratorResponse(draft, payload) {
  draft.EDECIntegration = payload.data[0];
}

function storeLocalEDECIntegration(draft, { payload }) {
  draft.localEDECIntegration = payload.data[0];
}

function removeEDECIntegration(draft, payload) {
  draft.EDECIntegration = {};
}

export default contextReducer;
