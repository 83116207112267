import {connect} from 'react-redux';
import Configurators from '../components/Configurators';
import {getConfiguratorsSend, configuratorFormDelete} from "../actions";

export default connect(
    (state) => ({
        configurators: state.configurator.configurators
    }),
    (dispatch) => ({
        getConfiguratorsRequest: () => {
            dispatch(getConfiguratorsSend())
        },
        deleteConfigurator: (configuratorId) => {
            dispatch(configuratorFormDelete(configuratorId))
        }
    })

)(Configurators);