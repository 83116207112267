import React, {useEffect, useState} from 'react';
import RecoverPasswordForm from "../common/containers/RecoverPasswordForm";
// import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom"


const RecoverPasswordPage = ({location}) => {
    // let location = useLocation();
    const [queryParameters] = useSearchParams()

    const [token,setToken] = useState('');

    useEffect(()=>{
      const token = queryParameters.get("token");
      if (token) {
          setToken(token);
      } else {
        console.log('Token not set');
      }
    },[]);
  return (
        <>
            <RecoverPasswordForm token={token}/>
       </>
)

};

export default RecoverPasswordPage;
