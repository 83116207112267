import React from 'react';
import ConfiguratorForm from "../containers/ConfiguratorForm";
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";

const ConfiguratorCreate = () => {

    return (
        <>
            <Breadcrumb title="Créer un configurateur">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/configurators">Configurateurs</Link></li>
                    <li className="breadcrumb-item active">Créer un configurateur</li>
                </ol>
            </Breadcrumb>
            <ConfiguratorForm/>
        </>
    )

};
export default withPermissions(ConfiguratorCreate, PERMISSION_SUPER_ADMIN);
