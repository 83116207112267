export const SORT_CALCUL_NAME_ASC = 'title_asc';
export const SORT_CALCUL_NAME_DESC = 'title_desc';
export const SORT_CALCUL_PROJECT_NAME_ASC = 'project_asc';
export const SORT_CALCUL_PROJECT_NAME_DESC = 'project_desc';
export const SORT_CALCUL_FAMILY_PRODUCT_ASC = 'productFamily.name_asc';
export const SORT_CALCUL_FAMILY_PRODUCT_DESC = 'productFamily.name_desc';
export const SORT_CALCUL_CREATED_AT_ASC = 'createdAt_asc';
export const SORT_CALCUL_CREATED_AT_DESC = 'createdAt_desc';
export const SORT_CALCUL_MODIFIED_AT_ASC = 'updatedAt_asc';
export const SORT_CALCUL_MODIFIED_AT_DESC = 'updatedAt_desc';
export const SORT_CALCUL_INDICATOR_AT_ASC = 'metrics.totalGlobalWarming_asc';
export const SORT_CALCUL_INDICATOR_AT_DESC = 'metrics.totalGlobalWarming_desc';

export const convertSortToAPIParams = (sort, page, limit) => {
    let params = [];
    if(limit && limit >0){
        params.push(`$limit=${limit}`)
    }

    if(sort && sort !== ''){
        const sortSplitted = sort.split('_');
        params.push(`$sort[${sortSplitted[0]}]=${sortSplitted[1] === 'asc' ? 1:-1}`)
    }

    if(page && limit && page > 1 && limit > 0){
        params.push(`$skip=${(limit*page) - limit}`)
    }

    return params.join('&');
};