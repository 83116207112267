import * as Yup from "yup";
import { Formik } from "formik";

const EDECForm = ({user, updateUserForm}) => {
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: user ? user.id : '',
                    EDECUsername: user && user.integrations && user.integrations.edec ? user.integrations.edec.username : "",
                    EDECPassword: user && user.integrations && user.integrations.edec ? user.integrations.edec.password : ""
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        setSubmitting(false);
                        updateUserForm(values);
                    }, 500)
                }}
                validationSchema={Yup.object().shape({
                    id: Yup.string(),
                    EDECUsername: Yup.string().required("Champ Requis"),
                    EDECPassword: Yup.string().required("Champ Requis")
                })}
                >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group has-danger">
                                        <div className="controls">
                                            <label htmlFor="EDECUsername">EDEC Username</label>
                                            <input 
                                                type="text" 
                                                id="EDECUsername"
                                                placeholder="EDEC Username"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.EDECUsername}
                                            />
                                            {errors.EDECUsername && touched.EDECUsername &&
                                            <div className="mt-1 danger">{errors.EDECUsername}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group has-danger">
                                        <div className="controls">
                                            <label htmlFor="EDECPassword">EDEC Password</label>
                                            <input 
                                                type="text" 
                                                id="EDECPassword"
                                                placeholder="EDEC Password"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.EDECPassword}
                                            />
                                            {errors.EDECPassword && touched.EDECPassword &&
                                            <div className="mt-1 danger">{errors.EDECPassword}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 d-flex flex-sm-row flex-column justify-content-end">
                                    <button type="submit" className="btn btn-primary ">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    )
}

export default EDECForm;