import React from 'react';
import FamilyProduct from "../containers/FamilyProduct";
import Breadcrumb from "../../../common/components/Breadcrumb";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";

const FamilyProductPage = () => {

    return (
        <>
            <Breadcrumb title="Famille de produit" createLink="/family-product/create">
            </Breadcrumb>
            <FamilyProduct/>
        </>
    )

};

export default withPermissions(FamilyProductPage,PERMISSION_SUPER_ADMIN);
