import React, {useEffect, useState} from 'react';
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import './SyntheticResult.scss';
import {Bar} from 'react-chartjs-2';
import IndicatorGroupedTableResult from "./IndicatorGroupedTableResult";
import './ModuleResult.scss';
import CollapsableCard from "../../../common/components/CollapsableCard";
import {DEFAULT_ACTIVE_COLOR, selectStyles} from "../../../common/constants/color";
import {toEsteanaExponential} from "../../../common/utils/formatter";
import {GRAPHERESULTATS, INDICATEURM, RESULTATSPARETAPES, TABLEAUDETAIL} from '../constants/metadata';
import {Chart as ChartJS, BarElement, LinearScale, CategoryScale, Tooltip} from 'chart.js'

const ModuleResult = ({indicators, localConfigurator, onChangeModuleResultForm, indicatorValue, histogramResults, tableResult, tableSteps, metadata}) => {
    const activeColor = localConfigurator && localConfigurator.theme && localConfigurator.theme.colors && localConfigurator.theme.colors.primaryText ? localConfigurator.theme.colors.primaryText : DEFAULT_ACTIVE_COLOR;

    ChartJS.register(BarElement, LinearScale, CategoryScale, Tooltip)

    const [indicateurM, setIndicateurM] = useState({})
    const [resultatParEtapes, setResultatParEtapes] = useState({})
    const [grapheResultats, setGrapheResultats] = useState({})
    const [tableauDetail, setTableauDetail] = useState({})

    useEffect(() => {
        metadata && setIndicateurM(metadata.find(e => e.paramId === INDICATEURM))
        metadata && setResultatParEtapes(metadata.find(e => e.paramId === RESULTATSPARETAPES))
        metadata && setGrapheResultats(metadata.find(e => e.paramId === GRAPHERESULTATS))
        metadata && setTableauDetail(metadata.find(e => e.paramId === TABLEAUDETAIL))
    }, [metadata])

    function displayModuleResult () {
        if (resultatParEtapes)
            return resultatParEtapes.visible
        else
            return true
    }

    function displayGrapheResultats () {
        if (grapheResultats)
            return grapheResultats.visible
        else
            return true
    }

    function displayTableauDetail () {
        if (tableauDetail)
            return tableauDetail.visible
        else
            return true
    }

    return (
        <>
            {displayModuleResult() &&
                <CollapsableCard title="Résultats par étapes">
                    {
                        displayGrapheResultats() &&
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <fieldset className="form-group">
                                        <label htmlFor="indicators">{indicateurM && indicateurM.name ? indicateurM.name : 'Indicateur environnemental'}<i
                                            className="feather icon-info ml-3"
                                            data-tip={indicateurM && indicateurM.helpText ? indicateurM.helpText : "Sélectionnez l’indicateur environnemental pour lequel vous souhaitez afficher le résultat. Les indicateurs proposés sont ceux requis en France pour la réalisation de déclarations environnementales de produits et équipements de construction, et la réalisation d’analyses de cycle de vie de bâtiments."}
                                            data-for="indicators" /></label>
                                        <Select
                                            styles={selectStyles(activeColor)}
                                            value={indicatorValue}
                                            onChange={(selectedOption) => onChangeModuleResultForm(selectedOption)}
                                            options={indicators && (indicators.map(indicator => {
                                                return {value: indicator.id, label: indicator.title}
                                            }))}
                                        />
                                        <ReactTooltip
                                            id="indicators"
                                            place="top"
                                            type="info"
                                            className="basic-tooltip"
                                        />
                                    </fieldset>

                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <Bar data={histogramResults} options={{
                                        legend: {
                                            display: false
                                        },
                                        plugins: {
                                            tooltip: {
                                                callbacks: {
                                                    label: (tooltipItem) => {
                                                        const tooltipValue = tooltipItem?.raw || 0;
                                                        return Math.abs(tooltipValue) < 0.1 || Math.abs(tooltipValue) > 999 ? toEsteanaExponential(tooltipValue?.toString() || "") : tooltipValue.toFixed(3).replace('.', ',')
                                                    }
                                                }
                                            }
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true
                                            },
                                            x: {
                                                autoSkip: false,
                                                maxRotation: 0,
                                                fontSize: 11,
                                                minRotation: 0,
                                            }
                                        }
                                    }} />
                                </div>
                            </div>
                        </>
                    }
                    {
                        displayTableauDetail() &&
                        <div className="row">
                            <div className="col-12 d-md-block d-none">
                                <table className="module">
                                    <thead>
                                        <tr>
                                            <th className="indicator-head">Indicateur</th>
                                            <th className="unity">Unité</th>
                                            {tableSteps && tableSteps.length > 0 && (
                                                tableSteps.map(step =>
                                                    <th key={step.shortTitle}>{step.shortTitle}</th>
                                                )
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableResult && tableResult.length > 0 && (
                                            tableResult.map((groupedIndicator, index) =>
                                                <IndicatorGroupedTableResult key={groupedIndicator.name} groupedIndicator={groupedIndicator} defautOpen={index === 0} />
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </CollapsableCard>
            }
        </>
    )
};

export default ModuleResult;
