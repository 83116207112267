import FamilyProductPage from "../pages/FamilyProduct";
import FamilyProductCreatePage from "../containers/FamilyProductCreate";
import FamilyProductEdit from "../containers/FamilyProductEdit";
import ModelCreate from "../pages/ModelCreate";
import ModelView from "../containers/ModelView";
import ModelEdit from "../containers/ModelEdit";

const routes = [
    {
        path: '/family-product',
        exact: true,
        element: FamilyProductPage
    },
    {
        path: '/family-product/create',
        exact: true,
        element: FamilyProductCreatePage
    },
    {
        path: '/family-product/create/:parentId',
        exact: true,
        element: FamilyProductCreatePage
    },
    {
        path: '/family-product/edit/:familyProductId',
        exact: true,
        element: FamilyProductEdit
    },
    {
        path: '/family-product/edit/:familyProductId/models/create',
        exact: true,
        element: ModelCreate
    },
    {
        path: '/family-product/edit/:familyProductId/models/view/:modelId',
        exact: true,
        element: ModelView
    },
    {
        path: '/family-product/edit/:familyProductId/models/edit/:modelId',
        exact: true,
        element: ModelEdit
    },
];

export default routes;
