import {connect} from 'react-redux';
import Layout from '../../layout';
import {checkLogin, getLocalConfigurator, getLocalEDECIntegration} from "../actions/user";
import {getFamilyProducts} from "../../modules/family-product/actions";

export default connect(
    (state) => ({
        location: state.router.location,
        localConfigurator: state.configurator.localConfigurator,
        localEDECIntegration: state.configurator.localEDECIntegration
    }),
    (dispatch) => ({
        getLocalConfigurator: () => {
            dispatch(getLocalConfigurator());
        },
        getFamilyProductsRequest: () => {
            dispatch(getFamilyProducts());
        },
        checkIfLogged: () => {
            dispatch(checkLogin())
        },
        getLocalEDECIntegration: () => {
            dispatch(getLocalEDECIntegration())
        },
    })
)(Layout);