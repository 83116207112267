import Home from "../pages/Home";
import RecoverPassword from "../pages/RecoverPassword";
import Register from "../pages/Register";
import Verify from "../common/containers/Verify";
import usersRoutes from "../modules/user/routes";
import configuratorRoutes from "../modules/configurator/routes";
import familyProductRoutes from "../modules/family-product/routes";
import fdesRoutes from "../modules/fdes/routes";
import calculsRoutes from "../modules/calcul/routes";
import About from "../common/containers/About";
import Guide from "../common/containers/Guide";
import Tutorial from "../common/containers/Tutorial";
import Methodology from "../common/containers/Methodology";
import FDESMere from "../common/containers/FDESMere";
import Login from "../pages/Login";
import AccessToken from "../common/containers/AccessToken";
import ManageGroupRoutes from "../modules/manage-group/routes";
import AssistanceRoutes from "../modules/assistance/routes"

const routes = [
  {
    path: '/',
    exact: true,
    element: Home
  },
  {
    path: '/register',
    exact: true,
    element: Register
  },
  {
    path: '/login',
    exact: true,
    element: Login
  },
  {
    path: '/about',
    exact: true,
    element: About
  },
  {
    path: '/guides',
    exact: true,
    element: Guide
  },
  {
    path: '/videos',
    exact: true,
    element: Tutorial
  },
  {
    path: '/methods',
    exact: true,
    element: Methodology
  },
  {
    path: '/references',
    exact: true,
    element: FDESMere
  },
  {
    path: '/recover-password',
    exact: true,
    element: RecoverPassword
  },
  {
    path: '/verify',
    exact: true,
    element: Verify
  },
  {
    path: '/recover-token',
    search: '?access_token=',
    hash: '#access_token=',
    exact: true,
    element: AccessToken
  },
  {
    path: '/recover-token/:error',
    exact: true,
    element: AccessToken
  },
];

//eslint-disable-next-line
export default [...routes, ...usersRoutes, ...configuratorRoutes, ...familyProductRoutes, ...fdesRoutes, ...calculsRoutes, ...ManageGroupRoutes, ...AssistanceRoutes];
