import Nouislider from "nouislider-react";
import React, {useEffect, useState} from "react";
import "nouislider/distribute/nouislider.css";
import {NumericFormat} from 'react-number-format';

function PercentContainer ({onChange, defaultValue, readOnly, validationRules, type}) {
    // const [currentValue, setCurrentValue] = useState(0);
    const [currentValue, setCurrentValue] = useState(defaultValue ? +defaultValue.toString().replace(',', '.').toLowerCase() : 0);
    const [prevValue, setPrevValue] = useState(0);
    const [error, setError] = useState('');
    const [min, setMin] = useState(undefined);
    const [max, setMax] = useState(undefined);
    useEffect((type) => {
        const validationRulesMin = validationRules?.find(x => x.type === "min")
        const validationRulesMax = validationRules?.find(x => x.type === "max")
        if (!validationRulesMin || isNaN(formatValue(validationRulesMin.value))) {
            console.log('ALERT Percent Param (min) is not a number: ', validationRulesMin);
        }
        if (!validationRulesMax || isNaN(formatValue(validationRulesMax.value))) {
            console.log('ALERT Percent Param (max) is not a number: ', validationRulesMax);
        }

        if (validationRulesMin?.value && validationRulesMin?.value!== '-') {
            const minValue = formatValue(validationRulesMin.value);
            setMin({type: 'min', value: minValue})
        } else if(type === "percentslider") {
            setMin({type: 'min', value: 0})
        }
        if (validationRulesMax?.value &&  validationRulesMax?.value !== '-') {
            const maxValue = formatValue(validationRulesMax.value);
            setMax({type: 'max', value: maxValue})
        } else if(type === "percentslider") {
            setMax({type: 'max', value: 100})
        }
    }, [validationRules]);

    useEffect(() => {
        setCurrentValue(formatValue(defaultValue));
        setPrevValue(formatValue(defaultValue));
    }, [defaultValue])

    function toFrenchFormatValueString(value) {
        return value.toString().replace('.', ',');
    }
    function formatValue(value) {
        value = (parseFloat(value.toString().replace(',', '.')) * 100).toFixed(4);
        value = parseFloat(value);
        return value;
    }
    function handleEndSlider (value) {
        const tmpValue = parseFloat(value.toString().replace(',', '.').toLowerCase())
        if (checkAllowed(tmpValue)) {
            setPrevValue(tmpValue);
            setCurrentValue(tmpValue.toString().replace(',', '.').toLowerCase());
            onChange(+tmpValue.toString().replace(',', '.').toLowerCase());
            setError("");
        }
        else {
            // if (validationRules?.find(x => x.type === "min") && tmpValue <= min.value)
            if (min !== undefined && max === undefined && tmpValue < min.value) {
                setError(`La valeur saisie doit être supérieure ou égale à ${toFrenchFormatValueString(min.value)}%`);
            }
            // else if (validationRules?.find(x => x.type === "max") && tmpValue >= max.value)
            else if (min === undefined && max !== undefined && tmpValue > max.value) {
                setError(`La valeur saisie doit être inférieure ou égale à ${toFrenchFormatValueString(max.value)}%`);
            }
            else
                setError(`La valeur saisie doit être comprise entre ${toFrenchFormatValueString(min.value)}% et ${toFrenchFormatValueString(max.value)}%`);
            setCurrentValue(+prevValue.toString().replace(',', '.').toLowerCase());
            onChange(+prevValue.toString().replace(',', '.').toLowerCase());
        }
    }

    function checkAllowed (tmpValue) {
        return (
            (min === undefined && max === undefined) ||
            (min !== undefined && max === undefined && tmpValue >= parseFloat(min.value)) ||
            (min === undefined && max !== undefined && tmpValue <= parseFloat(max.value)) ||
            (min !== undefined && max !== undefined && tmpValue >= parseFloat(min.value) && tmpValue <= parseFloat(max.value))
        )
    }

    return <div className="slider-container">
        {(type === "percentslider") && (min !== undefined && max !== undefined) && (
            < Nouislider
                range={
                    {
                        ...((min.value || min.value === 0) && { min: +parseFloat(min.value) }), 
                        ...((max.value || max.value === 0) && { max: +parseFloat(max.value) })
                    }
                }
                start={prevValue}
                onEnd={(e) => handleEndSlider(parseFloat(e).toFixed(2))}
                onChange={(e) => setCurrentValue(parseFloat(e).toFixed(2))}
                onSlide={(e) => setCurrentValue(parseFloat(e).toFixed(2))}
                disabled={readOnly}
                connect />
        )}
        <NumericFormat
            type="text"
            decimalSeparator=","
            valueIsNumericString={true}
            value={currentValue}
            suffix={" %"}
            onValueChange={(e) => setCurrentValue(e.floatValue)}
            onBlur={(e) => handleEndSlider(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" ? handleEndSlider(e.target.value) : ''}
            className="form-control"
            disabled={readOnly}
        />
        <p style={{color: "red"}}>{error}</p>
    </div>
}

export default PercentContainer;