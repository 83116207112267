import React from 'react';
import './SyntheticResult.scss';
import ExposantValue from "./ExposantValue";
import { toEsteanaExponential } from "../../../common/utils/formatter";

const IndicatorTableResult = ({ groupedIndicator, isOpen }) => {

    return (
        <>
            {isOpen && groupedIndicator.indicators && groupedIndicator.indicators.length > 0 && (
                groupedIndicator.indicators.map(indicator =>
                    <tr key={indicator.indicator.title}>
                        <td>{indicator.indicator.title}</td>
                        <td className="text-center">{indicator.indicator.unity}</td>
                        {indicator.values && indicator.values.length > 0 && (
                            indicator.values.map((result, index) =>
                                <td key={index} className="text-center"><ExposantValue value={result ? toEsteanaExponential(result.value) : '0'} /></td>
                            )
                        )}
                    </tr>
                )
            )}
        </>)
};

export default IndicatorTableResult;