export const GET_USERS_SEND = 'GET_USERS_SEND';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const USER_FORM_SEND = 'USER_FORM_SEND';
export const UPDATE_USER_FORM = 'UPDATE_USER_FORM';
export const USER_FORM_DELETE = 'USER_FORM_DELETE';
export const USER_FORM_DELETE_SUCCESS = 'USER_FORM_DELETE_SUCCESS';
export const UPDATE_ACCOUNT_SETTING_FORM = 'UPDATE_ACCOUNT_SETTING_FORM';
export const UPDATE_ACCOUNT_SETTING_FORM_SUCCESS = 'UPDATE_ACCOUNT_SETTING_FORM_SUCCESS';
export const UPDATE_PASSWORD_FORM = 'UPDATE_PASSWORD_FORM';
export const UPDATE_USER_EDEC_INTEGRATION = 'UPDATE_USER_EDEC_INTEGRATION';
export const UPDATE_USER_EDEC_INTEGRATION_SUCCESS = 'UPDATE_USER_EDEC_INTEGRATION_SUCCESS';