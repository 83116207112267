import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {Link} from 'react-router-dom';
import ConfiguratorDeleteModal from "./ConfiguratorDeleteModal";
import './ConfiguratorRaw.scss';

const ConfiguratorRaw = ({configurator, deleteConfigurator}) => {

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    return (
        <>
            <ConfiguratorDeleteModal
                isOpen={deleteModalIsOpen}
                onClose={setDeleteModalIsOpen}
                onSubmit={()=>deleteConfigurator(configurator.id)}
            />
            <tr>
                <td>{configurator.domain}</td>
                <td>{configurator.title}</td>
                <td>{configurator.color}</td>
                <td className="table-logo">{configurator.logo && (<img className="img-fluid" alt={configurator.title} src={configurator.logo}/>)}</td>
                <td>
                    <div className="d-flex"><Link
                        to={`/configurators/edit/${configurator.id}`}>
                        <Button variant="success" className="mr-25"><i className="feather icon-edit-1"/> </Button></Link>{' '}
                        <Button variant="danger" onClick={() => setDeleteModalIsOpen(true)}><i className="feather icon-trash-2"/></Button>
                    </div>
                </td>
            </tr>

        </>
    )

};

export default ConfiguratorRaw;
