import React from 'react';
import * as Yup from "yup";
import {Formik} from "formik";

const PasswordForm = ({user, updateUserForm}) => {

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: user ? user.id : '',
                    password: '',
                    passwordConfirmation: '',
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        setSubmitting(false);
                        updateUserForm(values);
                    }, 500);
                }}
                validationSchema={Yup.object().shape({
                    id: Yup.string(),
                    password: Yup.string().required("Champ Requis"),
                    passwordConfirmation: Yup.string().required("Champ Requis")
                        .oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques'),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;

                    return (
                        <form
                            onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group has-danger">
                                        <div className="controls">
                                            <label
                                                htmlFor="password">Mot de passe</label>
                                            <input
                                                type="password"
                                                id="password"
                                                placeholder="Mot de passe"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                            {errors.password && touched.password &&
                                            <div className="mt-1 danger">{errors.password}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group has-danger">
                                        <div className="controls">
                                            <label
                                                htmlFor="passwordConfirmation">Confirmer le mot de passe</label>
                                            <input
                                                type="password"
                                                id="passwordConfirmation"
                                                placeholder="Confirmer le mot de passe"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.passwordConfirmation}
                                            />
                                            {errors.passwordConfirmation && touched.passwordConfirmation &&
                                            <div className="mt-1 danger">{errors.passwordConfirmation}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 d-flex flex-sm-row flex-column justify-content-end">
                                    <button type="submit" className="btn btn-primary ">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    )

};

export default PasswordForm;
