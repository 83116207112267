import {connect} from 'react-redux';
import Verify from "../../pages/Verify";
import {sendVerification} from "../actions/register";
// import { useLocation } from "react-router-dom";

export default connect(
    (state) => ({
        location: state.router.location,
        sendVerification: sendVerification,
        isLoading: state.context.verify.isLoading,
        isValid: state.context.verify.isValid,
    }),
    (dispatch) => ({
        sendVerification: (token) => {
            dispatch(sendVerification(token))
        },
    })
)(Verify);