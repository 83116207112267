import React from 'react';
import {Formik} from "formik";
import * as Yup from "yup";

const SendRecoverPasswordForm = ({sendRecoverPasswordForm}) => {

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: ''
      }}
      onSubmit={(values, {setSubmitting}) => {
        setTimeout(() => {
          setSubmitting(false);
          sendRecoverPasswordForm(values);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Email invalide').required("Champ Requis")
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <fieldset className="form-group position-relative has-icon-left">
              <input type="text" id="email" className="form-control form-control-lg"
                placeholder="Adresse email" onChange={handleChange}
                onBlur={handleBlur}
                value={values.email} />

              {errors.email && touched.email &&
                <div className="error-message danger">{errors.email}</div>}

            </fieldset>
            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-lg btn-block mb"><i
                className="feather icon-unlock" /> Réinitialiser votre mot de passe
              </button>
            </div>
          </form>)
      }}
    </Formik>
  );
};

export default SendRecoverPasswordForm;
