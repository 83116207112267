import React from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import Users from "../containers/Users";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_ORG_ADMIN} from "../constants/permissions";

const User = () => {

    return (
        <>
            <Breadcrumb title="Utilisateurs" createLink="/users/create">
            </Breadcrumb>
            <Users />
        </>
    )

};
export default withPermissions(User, PERMISSION_ORG_ADMIN);
