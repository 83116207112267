import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

const AccessToken = ({ loginSaml }) => {
  let location = useLocation();
  const hash = location.hash;

  useEffect(() => {
    if (hash.includes("#error")) {
      let values = { error: "error" };
      loginSaml(values);
    } else {
      let values = { token: hash.split('#access_token=')[1] };
      loginSaml(values);
    }
  }, [hash, loginSaml]);

  return (<></>);
};

export default AccessToken;
