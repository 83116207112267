import Select from "react-select";
import {selectStyles} from "../../../common/constants/color";
import React, {useRef} from "react";

function FdesFormSelect({
                            activeColor,
                            fields,
                            parameter,
                            onChangeFdesParameterForm,
                            isAuto,
                            readOnly,
                        }){
    const selectRef = useRef(null);

    function handleMenuOpen(){
        if(selectRef && selectRef.current){
            selectRef.current.scrollIntoView({block: "center"})
        }
    }
    return (<span ref={selectRef}>
        <Select
            styles={selectStyles(activeColor)}
            value={fields && fields.find(x => x.paramId === parameter.paramId) && (fields.find(x => x.paramId === parameter.paramId).value)}
            onChange={(selectedOption) => onChangeFdesParameterForm(parameter.paramId, selectedOption, (isAuto === 'Y' && !parameter.isGeneralParameter))}
            options={parameter.values && (parameter.values.map((label, value) => {
                return {value, label}
            }))}
            onMenuOpen={handleMenuOpen}
            isDisabled={readOnly}
            placeholder={parameter.defaultValue}

        />
    </span>)
}

export default FdesFormSelect;