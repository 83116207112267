import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";
import ModelForm from "../containers/ModelForm";

const ModelEdit = ({match, models}) => {
    const [model, setModel] = useState('');
    const [familyProductId, setFamilyProductId] = useState('');

    const params = useParams()

    useEffect(() => {
        if (params && params.modelId) {
            setModel(models.find(x => x._id === params.modelId))
        }
        setFamilyProductId(params.familyProductId);
    }, [params, models]);
    return (
        <>
            <Breadcrumb title="Modifier le modèle">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/family-product">Famille de produit</Link></li>
                    <li className="breadcrumb-item"><Link to={`/family-product/edit/${familyProductId}`}>Modifier la famille de produit</Link></li>
                    <li className="breadcrumb-item active">Modifier le modèle</li>
                </ol>
            </Breadcrumb>
            <ModelForm action="UPDATE" model={model} familyProductId={familyProductId} />
        </>
    )

};
export default withPermissions(ModelEdit, PERMISSION_SUPER_ADMIN);

