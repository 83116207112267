import {combineReducers} from 'redux';
import loader from '../common/reducers/loader';
import user from '../common/reducers/user';
import context from '../common/reducers/context';
import configurator from '../modules/configurator/reducers';
import familyProduct from '../modules/family-product/reducers';
import fdes from "../modules/fdes/reducers";
import calculs from "../modules/calcul/reducers";
import manageGroup from "../modules/manage-group/reducers";
import assistance from "../modules/assistance/reducers";
import {createReduxHistoryContext} from "redux-first-history";
import browserHistory from "../routes/history";

const {routerReducer} = createReduxHistoryContext({history: browserHistory})

export const createRootReducer = combineReducers({
      loader,
      user,
      configurator,
      familyProduct,
      manageGroup,
      context,
      fdes,
      calculs,
      assistance,
      router: routerReducer
});