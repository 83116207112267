import React from 'react';
import {Link} from 'react-router-dom';

const Breadcrumb = ({title, createLink, children, createCalculLink}) => {
    return (<div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-2">
            <h3 className="content-header-title mb-0">{title}</h3>
            <div className="row breadcrumbs-top">
                <div className="breadcrumb-wrapper col-12">
                    {children}
                </div>
            </div>
        </div>
        {createLink && (
            <div className="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <div className="btn-group float-md-right" role="group" aria-label="Button group with nested dropdown">
                    <Link className="btn btn-outline-primary" to={createLink}><i
                        className="feather icon-plus" /></Link>
                </div>
            </div>
        )}
        {createCalculLink && (
            <div className="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <div className="btn-group float-md-right" role="group" >
                    <Link className="btn btn-outline-primary" to={createCalculLink}>Nouveau calcul</Link>
                </div>
            </div>
        )}
    </div>)
};

export default Breadcrumb;