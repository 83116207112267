import React from 'react';
import * as Yup from "yup";
import {Formik} from "formik";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Link} from "react-router-dom";
import Loader from "../../../common/components/Loader";
import Models from "../containers/Models";

const FamilyProductForm = ({saveFamilyProductForm, familyProduct, action = "CREATE", updateFamilyProductForm, parentId, familyProductFormIsLoading, modeleActive}) => {

    function onChangeFile (event, setFieldValue, fieldName) {
        setFieldValue(fieldName, event.target.files[0]);
    }

    function handleAddElement (values, setFieldValue) {
        const elements = values.documentation?.fdesMeres?.elements ? [...values.documentation.fdesMeres.elements] : []
        elements.push({
            title: '',
            file: '',
            fileToSend: '',
            order: ''
        })
        setFieldValue('documentation.fdesMeres.elements', elements);
    }

    function handleDeleteElement (values, setFieldValue, index) {
        const elements = [...values.documentation.fdesMeres.elements];
        elements.splice(index, 1);
        setFieldValue('documentation.fdesMeres.elements', elements);
    }

    return (
        <>
            <Loader isActive={familyProductFormIsLoading} />
            <div className="card">
                <div className="card-content collapse show">
                    <div className="card-body">

                        <ul className="nav nav-tabs nav-underline"
                            role="tablist">
                            <li className="nav-item">
                                <a className={`nav-link ${modeleActive ? '' : 'active'}`} id="active-tab1"
                                    data-toggle="tab"
                                    href="#familyProductForm" aria-controls="active1" role="tab"
                                    aria-selected="true">Famille de produit</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="link-tab2"
                                    data-toggle="tab"
                                    href="#documentation"
                                    aria-controls="documentation" role="tab" aria-selected="false">Documentation</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${modeleActive ? 'active' : ''}`} id="link-tab1"
                                    data-toggle="tab"
                                    href="#fdesForm"
                                    aria-controls="link1" role="tab" aria-selected="false">Modèle de calcul</a>
                            </li>
                        </ul>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                id: familyProduct ? familyProduct._id : '',
                                name: familyProduct ? familyProduct.name : '',
                                label: familyProduct ? familyProduct.label : '',
                                shortLabel: familyProduct ? familyProduct.shortLabel : '',
                                description: familyProduct ? familyProduct.description : '',
                                parentId: parentId !== '' ? parentId : familyProduct ? familyProduct.parent : '',
                                file: '',
                                guideFile: '',
                                methodologyFile: '',
                                content: {
                                    helpTextLabel: familyProduct && familyProduct.content && familyProduct.content.helpTextLabel ? familyProduct.content.helpTextLabel : 'Réaliser une FDES'
                                },
                                documentation: {
                                    guide: {
                                        file: familyProduct && familyProduct.documentation && familyProduct.documentation.guide ? familyProduct.documentation.guide.file : '',
                                        label: familyProduct && familyProduct.documentation && familyProduct.documentation.guide ? familyProduct.documentation.guide.label : '',
                                    },
                                    tutorial: {
                                        file: familyProduct && familyProduct.documentation && familyProduct.documentation.tutorial ? familyProduct.documentation.tutorial.file : '',
                                        label: familyProduct && familyProduct.documentation && familyProduct.documentation.tutorial ? familyProduct.documentation.tutorial.label : '',
                                    },
                                    videoTutorial: {
                                        title: familyProduct && familyProduct.documentation && familyProduct.documentation.videoTutorial ? familyProduct.documentation.videoTutorial.title : '',
                                        description: familyProduct && familyProduct.documentation && familyProduct.documentation.videoTutorial ? familyProduct.documentation.videoTutorial.description : '',
                                        video: familyProduct && familyProduct.documentation && familyProduct.documentation.videoTutorial ? familyProduct.documentation.videoTutorial.video : '',
                                    },
                                    fdesMeres: {
                                        elements: familyProduct && familyProduct.documentation && familyProduct.documentation.fdesMeres && familyProduct.documentation.fdesMeres.elements ? familyProduct.documentation.fdesMeres.elements : []
                                    }
                                }
                            }}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    action === "CREATE" ? saveFamilyProductForm(values) : updateFamilyProductForm(values);
                                }, 500);
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required("Champ Requis"),
                                description: Yup.string(),
                                parentId: Yup.string(),
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <form className="form form-horizontal striped-rows form-bordered"
                                        onSubmit={handleSubmit}>
                                        <div className="tab-content">
                                            <div id="familyProductForm"
                                                className={`form-body tab-pane ${modeleActive ? '' : 'active in'}`}
                                                aria-labelledby="familyProductForm-tab1" role="tabpanel">

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="name" className="col-md-3 label-control">Nom</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />
                                                        {errors.name && touched.name &&
                                                            <div className="mt-1 danger">{errors.name}</div>}
                                                    </div>
                                                </div>

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="label" className="col-md-3 label-control">Libellé complet</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="label"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.label}
                                                        />
                                                        {errors.label && touched.label &&
                                                            <div className="mt-1 danger">{errors.label}</div>}
                                                    </div>
                                                </div>

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="shortLabel" className="col-md-3 label-control">Libellé court</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="shortLabel"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.shortLabel}
                                                        />
                                                        {errors.shortLabel && touched.shortLabel &&
                                                            <div className="mt-1 danger">{errors.shortLabel}</div>}
                                                    </div>
                                                </div>
                                                <div className="form-group row has-danger">
                                                    <label htmlFor="name" className="col-md-3 label-control">ID</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="id"
                                                            className="form-control"
                                                            value={values.id}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row has-danger">
                                                    <label htmlFor="logo"
                                                        className="col-md-3 label-control">Image</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="file"
                                                            id="logo"
                                                            className="form-control"
                                                            onChange={(e) => onChangeFile(e, setFieldValue, 'file')}
                                                            onBlur={handleBlur}
                                                            value={values.logo}
                                                        />
                                                        {errors.logo && touched.logo &&
                                                            <div className="mt-1 danger">{errors.image}</div>}
                                                        {familyProduct && familyProduct.image !== "" && (
                                                            <div className="max-image">Image actuelle : <img className="img-fluid" alt="Logo" src={familyProduct.image} /></div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="description"
                                                        className="col-md-3 label-control">Description</label>
                                                    <div className="col-md-9">
                                                        <textarea
                                                            id="description"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                        />
                                                        {errors.description && touched.description &&
                                                            <div className="mt-1 danger">{errors.description}</div>}
                                                    </div>

                                                </div>
                                                <div className="form-group row has-danger">
                                                    <label htmlFor="content.helpTextLabel"
                                                        className="col-md-3 label-control">Texte bulle d'aide calcul</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="content.helpTextLabel"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.content.helpTextLabel}
                                                        />
                                                        {errors.content && errors.content.helpTextLabel &&
                                                            touched.content && touched.content.helpTextLabel &&
                                                            <div className="mt-1 danger">{errors.content.helpTextLabel}</div>}
                                                    </div>

                                                </div>
                                                <h4 className="form-section">FDES mères</h4>
                                                {values.documentation.fdesMeres && values.documentation.fdesMeres.elements && values.documentation.fdesMeres.elements.length > 0 && (
                                                    <table className="w-100">
                                                        <thead>
                                                            <tr>
                                                                <th>Titre</th>
                                                                <th>Fichier</th>
                                                                <th>Ordre</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.documentation.fdesMeres.elements.map((element, index) =>
                                                                <tr key={index} className='pt-2'>
                                                                    <td className="pl-0 pr-1 pb-2 col-md-3">
                                                                        <input
                                                                            type="text"
                                                                            id={`documentation.fdesMeres.elements[${index}].title`}
                                                                            className="form-control"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.documentation.fdesMeres.elements[index].title}
                                                                        />
                                                                    </td>
                                                                    <td className="pl-0 pr-1 pb-2 col-md-6">
                                                                        <input
                                                                            type="file"
                                                                            id={`documentation.fdesMeres.elements[${index}].fileToSend`}
                                                                            className="form-control"
                                                                            onChange={(e) => onChangeFile(e, setFieldValue, `documentation.fdesMeres.elements[${index}].fileToSend`)}
                                                                            onBlur={handleBlur}
                                                                        />

                                                                        {familyProduct &&
                                                                            familyProduct.documentation &&
                                                                            familyProduct.documentation.fdesMeres &&
                                                                            familyProduct.documentation.fdesMeres.elements &&
                                                                            familyProduct.documentation.fdesMeres.elements[index] &&
                                                                            familyProduct.documentation.fdesMeres.elements[index].file !== "" &&
                                                                            (
                                                                                <div>Fichier actuel : <a className="button" target="_blank" rel="noopener noreferrer"
                                                                                    href={familyProduct.documentation.fdesMeres.elements[index].file}>{familyProduct.documentation.fdesMeres.elements[index].file}</a>
                                                                                </div>
                                                                            )}
                                                                    </td>
                                                                    <td className="pl-0 pr-1 pb-2 col-md-2">
                                                                        <input className="ml-2"
                                                                            type="number"
                                                                            id={`documentation.fdesMeres.elements[${index}].order`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.documentation.fdesMeres.elements[index].order} />
                                                                    </td>
                                                                    <td className="pl-0 pr-1 pb-2 col-md-1">
                                                                        <button className="btn btn-danger" type="button"
                                                                            onClick={() => handleDeleteElement(values, setFieldValue, index)}>
                                                                            <i className="feather icon-trash-2" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                                <div className="form-group row has-danger mt-2 justify-content-end">
                                                    <button className="btn btn-primary" type="button"
                                                        onClick={() => handleAddElement(values, setFieldValue)}>Ajouter un élément
                                                    </button>
                                                </div>
                                                <div className="form-actions right">
                                                    <Link to="/family-product">
                                                        <button type="reset" className="btn btn-warning mr-1">
                                                            <i className="feather icon-x" /> Annuler
                                                        </button>
                                                    </Link>
                                                    <button type="submit" className="btn btn-primary">
                                                        <i className="fa fa-check-square-o" /> Enregistrer
                                                    </button>
                                                </div>

                                            </div>

                                            <div id="documentation" className="tab-pane"
                                                aria-labelledby="documentation-tab1"
                                                role="tabpanel">
                                                <div className="form-group row has-danger">
                                                    <label htmlFor="documentation.guide.label"
                                                        className="col-md-3 label-control">Libellé fichier guide</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="documentation.guide.label"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.documentation.guide.label}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row has-danger">
                                                    <label htmlFor="guideFile"
                                                        className="col-md-3 label-control">Guide produit</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="file"
                                                            id="guideFile"
                                                            className="form-control"
                                                            onChange={(e) => onChangeFile(e, setFieldValue, 'guideFile')}
                                                            onBlur={handleBlur}
                                                        />
                                                        {familyProduct &&
                                                            familyProduct.documentation &&
                                                            familyProduct.documentation.guide &&
                                                            familyProduct.documentation.guide.file !== "" && (
                                                                <div >Fichier actuel : <a target="_blank" rel="noopener noreferrer" href={familyProduct.documentation.guide.file} >{familyProduct.documentation.guide.file}</a></div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="documentation.tutorial.label"
                                                        className="col-md-3 label-control">Libellé fichier méthodologie</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="documentation.tutorial.label"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.documentation.tutorial.label}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row has-danger">
                                                    <label htmlFor="methodologyFile"
                                                        className="col-md-3 label-control">Méthodologie</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="file"
                                                            id="methodologyFile"
                                                            className="form-control"
                                                            onChange={(e) => onChangeFile(e, setFieldValue, 'methodologyFile')}
                                                            onBlur={handleBlur}
                                                        />
                                                        {familyProduct &&
                                                            familyProduct.documentation &&
                                                            familyProduct.documentation.tutorial &&
                                                            familyProduct.documentation.tutorial.file !== "" && (
                                                                <div >Fichier actuel : <a target="_blank" rel="noopener noreferrer" href={familyProduct.documentation.tutorial.file} >{familyProduct.documentation.tutorial.file}</a></div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="documentation.videoTutorial.title"
                                                        className="col-md-3 label-control">Titre de la vidéo</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="documentation.videoTutorial.title"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.documentation.videoTutorial.title}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="documentation.videoTutorial.description"
                                                        className="col-md-3 label-control">Description de la vidéo</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="documentation.videoTutorial.description"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.documentation.videoTutorial.description}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row has-danger">
                                                    <label htmlFor="documentation.videoTutorial.video" className="col-md-3 label-control">Lien tutoriel vidéo</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            id="documentation.videoTutorial.video"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.documentation.videoTutorial.video}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-actions right">
                                                    <Link to="/family-product">
                                                        <button type="reset" className="btn btn-warning mr-1">
                                                            <i className="feather icon-x" /> Annuler
                                                        </button>
                                                    </Link>
                                                    <button type="submit" className="btn btn-primary">
                                                        <i className="fa fa-check-square-o" /> Enregistrer
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="fdesForm" className={`tab-pane ${modeleActive ? 'active in' : ''}`}
                                                aria-labelledby="fdesForm-tab1"
                                                role="tabpanel">
                                                <Models activeFamilyProductId={familyProduct ? familyProduct._id : ''} />
                                            </div>
                                        </div>
                                    </form>)
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )

};

export default FamilyProductForm;
