import CalculPage from "../pages/CalculPage";


const routes = [
    {
        path: '/calculs',
        exact: true,
        element: CalculPage
    }
];

export default routes;
