import React, {useEffect} from 'react';
import * as Yup from "yup";
import {Formik} from "formik";
import {Link, useParams} from "react-router-dom";
import {PERMISSION_ORG_ADMIN, PERMISSION_USER, userHasPermission} from "../constants/permissions";
import {userTypeKeyToLabel} from "../../../utils/transformer";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";


const UserForm = ({match, user, action = "CREATE", sendUserForm, updateUserForm, getConfigurators, getGroupsByUserId, configurators, localConfigurator, userInformations, userGroups}) => {

    const params = useParams()

    useEffect(() => {
        getConfigurators();
        if (params && params.userId) {
            getGroupsByUserId(params.userId);
        }
    }, [getConfigurators, getGroupsByUserId, params]);

    function handlePermissionRules (configurator, role, values, setFieldValue) {
        let permissionRules = [...values.permissionRules];
        let permissions = [...values.permissions];
        let configurators = [...values.configurators];
        if (permissionRules && configurators) {
            permissionRules = permissionRules.filter(x => x.configurator !== configurator)
            configurators = configurators.filter(x => x !== configurator)
            permissions = permissions.filter(x => x !== role)
        }
        if (role !== "") {
            configurators.push(configurator)
            permissions.push(role)
            setFieldValue('permissions', permissions);
            setFieldValue('configurators', configurators);
        }
        permissionRules.push({
            configurator: configurator,
            role: role
        })
        setFieldValue('permissionRules', permissionRules);

    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: user ? user.id : '',
                    lastName: user ? user.lastName : '',
                    firstName: user ? user.firstName : '',
                    type: user ? user.type : '',
                    email: user ? user.email : '',
                    password: '',
                    passwordConfirmation: '',
                    configurators: user && user.configurators && user.configurators.length > 0 ? user.configurators : userInformations && userInformations.configurators && userInformations.configurators.length > 0 ? userInformations.configurators : [],
                    permissions: user ? user.permissions : [PERMISSION_USER],
                    permissionRules: user && user.permissionRules ? user.permissionRules : []
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setSubmitting(false);
                        action === "CREATE" ? sendUserForm(values) : updateUserForm(values);
                    }, 500);
                }}
                validationSchema={Yup.object().shape({
                    id: Yup.string(),
                    lastName: Yup.string().required("Champ Requis"),
                    firstName: Yup.string().required("Champ Requis"),
                    email: Yup.string().required("Champ Requis"),
                    type: Yup.string(),
                    password: action === "CREATE" ? Yup.string().required("Champ Requis") : Yup.string(),
                    passwordConfirmation: action === "CREATE" ? Yup.string().required("Champ Requis")
                        .oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques') :
                        Yup.string()
                            .oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques'),
                    configurators: Yup.array().required("Champ Requis"),
                    permissions: Yup.array(),
                    permissionRules: Yup.array()
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    } = props;

                    return (
                        <div className="card">
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <form className="form form-horizontal striped-rows form-bordered"
                                        onSubmit={handleSubmit}>
                                        <div className="form-body">
                                            <div className="form-group row has-danger">
                                                <label htmlFor="lastName" className="col-md-3 label-control">Nom</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        id="lastName"
                                                        placeholder="Nom"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.lastName}
                                                    />
                                                    {errors.lastName && touched.lastName &&
                                                        <div className="mt-1 danger">{errors.lastName}</div>}
                                                </div>
                                            </div>
                                            <div className="form-group row has-danger">
                                                <label htmlFor="firstName" className="col-md-3 label-control">Prénom</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        id="firstName"
                                                        placeholder="Prénom"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.firstName}
                                                    />
                                                    {errors.firstName && touched.firstName &&
                                                        <div className="mt-1 danger">{errors.firstName}</div>}
                                                </div>
                                            </div>
                                            <div className="form-group row has-danger">
                                                <label htmlFor="email" className="col-md-3 label-control">Email</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        placeholder="Email"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        disabled={action === "UPDATE"}
                                                    />
                                                    {errors.email && touched.email &&
                                                        <div className="mt-1 danger">{errors.email}</div>}
                                                </div>
                                            </div>
                                            <div className="form-group row has-danger">
                                                <label htmlFor="type" className="col-md-3 label-control">Type</label>
                                                <div className="col-md-9">
                                                    <select className="form-control" name="type" id="type" value={values.type} onBlur={handleBlur} onChange={handleChange}>
                                                        <option value="" />
                                                        {Object.entries(userTypeKeyToLabel).map(value =>
                                                            <option key={value[0]} value={value[0]}>{value[1]}</option>
                                                        )}
                                                    </select>
                                                    {errors.type && touched.type &&
                                                        <div className="mt-1 danger">{errors.type}</div>}
                                                </div>
                                            </div>
                                            <div className="form-group row has-danger">
                                                <label htmlFor="password" className="col-md-3 label-control">Mot de passe</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        placeholder="Mot de passe"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password}
                                                    />
                                                    {errors.password && touched.password &&
                                                        <div className="mt-1 danger">{errors.password}</div>}
                                                </div>
                                            </div>
                                            <div className="form-group row has-danger">
                                                <label htmlFor="passwordConfirmation" className="col-md-3 label-control">Confirmer le mot de passe</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="password"
                                                        id="passwordConfirmation"
                                                        placeholder="Confirmer le mot de passe"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.passwordConfirmation}
                                                    />
                                                    {errors.passwordConfirmation && touched.passwordConfirmation &&
                                                        <div className="mt-1 danger">{errors.passwordConfirmation}</div>}
                                                </div>
                                            </div>
                                            {userInformations && userInformations.permissions && (userHasPermission(userInformations.permissions, PERMISSION_SUPER_ADMIN) || userHasPermission(userInformations.permissions, PERMISSION_ORG_ADMIN)) && (
                                                <>
                                                    <div className="form-group row has-danger">
                                                        <label htmlFor="configurators" className="col-md-3 label-control">Configurateurs et permissions</label>
                                                        <div className="col-md-9">
                                                            <div className="row mb-1">
                                                                <div className="col-4">
                                                                    <b>Configurateurs</b>
                                                                </div>
                                                                <div className="col-4">
                                                                    <b>Permissions</b>
                                                                </div>
                                                                <div className="col-4">
                                                                    <b>Groupes</b>
                                                                </div>
                                                            </div>
                                                            {configurators && configurators.length > 0 && configurators.map(configurator =>
                                                                !userHasPermission(userInformations.permissions, PERMISSION_SUPER_ADMIN) ?
                                                                    userInformations?.permissionRules?.find(x => x.configurator === configurator.id) &&
                                                                    <div className="row">
                                                                        <div className="col-4">
                                                                            <p className="mt-1" key={configurator.id} value={configurator.id}>{configurator.title}</p>
                                                                        </div>
                                                                        <div className="col-4 align-items-center">
                                                                            <select className="form-control" name="permissions" id={configurator.id}
                                                                                value={values?.permissionRules?.find(x => x.configurator === configurator.id)?.role || ""}
                                                                                onBlur={handleBlur} onChange={(e) => handlePermissionRules(configurator.id, e.target.value, values, setFieldValue)}>
                                                                                <option value={""}></option>
                                                                                <option value={PERMISSION_USER}>Utilisateur</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-4 align-items-center">
                                                                            <select className="form-control" name="userGroups" id={userGroups?.map(x => x).find(x => x.configurator === configurator.id)?._id}
                                                                                value={userGroups?.map(x => x).find(x => x.configurator === configurator.id)?.title || ""}
                                                                                onBlur={handleBlur}
                                                                                readOnly>
                                                                                {userGroups?.filter(x => x.configurator === configurator.id).map(group =>
                                                                                    <option key={group._id} value={group.title}>{group.title}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    : <div className="row">
                                                                        <div className="col-4">
                                                                            <p className="mt-1" key={configurator.id} value={configurator.id}>{configurator.title}</p>
                                                                        </div>
                                                                        <div className="col-4 align-items-center">
                                                                            <select className="form-control" name="permissions" id={configurator.id}
                                                                                value={values?.permissionRules?.find(x => x.configurator === configurator.id)?.role || ""}
                                                                                onBlur={handleBlur} onChange={(e) => handlePermissionRules(configurator.id, e.target.value, values, setFieldValue)}>
                                                                                <option value={""}></option>
                                                                                <option value={PERMISSION_USER}>Utilisateur</option>
                                                                                <option value={PERMISSION_ORG_ADMIN}>Administrateur de configurateur</option>
                                                                                <option value={PERMISSION_SUPER_ADMIN}>Super admin</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-4 align-items-center">
                                                                            <select className="form-control" name="userGroups" id={userGroups?.map(x => x).find(x => x.configurator === configurator.id)?._id}
                                                                                value={userGroups?.map(x => x).find(x => x.configurator === configurator.id)?.title || ""}
                                                                                onBlur={handleBlur}
                                                                                readOnly>
                                                                                {userGroups?.filter(x => x.configurator === configurator.id).map(group =>
                                                                                    <option key={group._id} value={group.title}>{group.title}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                            )}
                                                            {errors?.permissionRules && touched?.permissionRules &&
                                                                <div className="mt-1 danger">{errors?.permissionRules}</div>}
                                                        </div>
                                                    </div>
                                                </>
                                            )}


                                        </div>
                                        <div className="form-actions right">
                                            <Link to="/users">
                                                <button type="reset" className="btn btn-warning mr-1">
                                                    <i className="feather icon-x" /> Annuler
                                                </button>
                                            </Link>
                                            <button type="submit" className="btn btn-primary">
                                                <i className="fa fa-check-square-o" /> Enregistrer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </>
    )

};

export default UserForm;
