import {connect} from 'react-redux';
import Users from '../components/Users';
import {deleteUser, getUsersSend} from "../actions";

export default connect(
    (state) => ({
        users: state.user.users
    }),
    (dispatch) => ({
        getUsersRequest: () => {
            dispatch(getUsersSend())
        },
        deleteUser: (userId) => {
            dispatch(deleteUser(userId))
        }
    })

)(Users);