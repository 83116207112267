import {connect} from 'react-redux';
import {updatePasswordForm} from "../actions";
import PasswordForm from "../components/PasswordForm";

export default connect(
    (state) => ({
        user: state.user.informations,
    }),
    (dispatch) => ({
        updateUserForm: (values) => {
            dispatch(updatePasswordForm(values))
        }
    })
)(PasswordForm);