import React from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import Contact from "../containers/Contact";

const ContactPage = () => {

    return (
        <>
            <Breadcrumb title="Nous Contacter">
            </Breadcrumb>
            <Contact />
        </>
    )

};

export default ContactPage