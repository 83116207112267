import React from 'react';
import CollapsableCard from "../../../common/components/CollapsableCard";
import { withPermissions } from "../../../layout/Permissions";

const Faq = ({ localConfigurator }) => {

    function createMarkup(description) {
        return { __html: description ? description : '' };
    }

    return (
        localConfigurator && localConfigurator.documentation && localConfigurator.documentation.faq && localConfigurator.documentation.faq.elements ?
            <>
                {localConfigurator.documentation.faq.elements.slice().sort((a, b) => a.order - b.order).map((element) => (
                    <CollapsableCard title={element.title} defaultOpen={false}>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card-text" dangerouslySetInnerHTML={createMarkup(element.text)} />
                            </div>
                        </div>
                    </CollapsableCard>
                ))}
            </> :
            <> </>
    )

};

export default withPermissions(Faq);