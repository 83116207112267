import React from 'react';
import {Link} from "react-router-dom";
import './FamilyProductGroup.scss';
import ReactTooltip from "react-tooltip";

const FamilyProductGroup = ({familyProduct, isOpen, familyProductsFilters, aggregatedFamilyProducts}) => {
    return (
        isOpen && (
            <ul className="list-group list-group-flush">
                {familyProduct.children.map(familyProductChild =>
                    familyProductsFilters?.includes(familyProductChild._id) && aggregatedFamilyProducts.find(x => x.id === familyProductChild._id)?.isVisible && (
                        <li
                            key={familyProductChild._id}
                            style={{order: familyProductsFilters.findIndex(x => x === familyProductChild._id)}}
                            className="list-group-item list-group-item-action">
                            <div className='d-flex flex-row w-100'>
                                <p className='w-75'>
                                    {familyProductChild.shortLabel || familyProductChild.name}
                                </p>

                                {/* fdesMeres */}

                                <div className="button-container d-flex justify-content-between mx-1 w-25">
                                    {
                                        familyProductChild?.documentation?.fdesMeres?.elements?.length > 1 ? (

                                            //if multiple fdesMeres

                                            <div className="dropdown w-auto pr-1">
                                                <a type="button" href="!#" id={familyProductChild._id} data-toggle="dropdown" aria-expanded="false">
                                                    <div className="button-container onHover" data-tip={"Voir les FDES mères"}>
                                                        <i className="feather icon-file-text m-0" />
                                                        <i className="feather icon-chevron-down m-0" style={{fontSize: "1rem"}} />
                                                    </div>

                                                </a>
                                                <ReactTooltip
                                                    place="top"
                                                    type="info"
                                                    className="basic-tooltip"
                                                />
                                                <div className="dropdown-menu m-0" aria-labelledby={familyProductChild._id}>
                                                    {familyProductChild.documentation.fdesMeres.elements.slice().sort((a, b) => a.order - b.order).map((x, i) => x && (
                                                        <div key={i + x.file} className="text-nowrap">
                                                            <a href={x.file} target="_blank" rel="noopener noreferrer" className='p-0'>
                                                                <p className="px-1">{x.title}</p>
                                                            </a>
                                                        </div>))}
                                                    {familyProductChild.children?.length > 0 && (
                                                        familyProductsFilters.map(familyProductId => familyProductChild?.children.map((x) => { //eslint-disable-line
                                                            if (x._id === familyProductId)
                                                                x.documentation?.fdesMeres?.elements?.slice().sort((a, b) => a.order - b.order).map((x, i) =>
                                                                    <div key={i} className="text-nowrap">
                                                                        <a href={x.file} target="_blank" rel="noopener noreferrer" className='p-0'>
                                                                            <p className="px-1">{x.title}</p>
                                                                        </a>
                                                                    </div>
                                                                )
                                                        })))}
                                                </div>
                                            </div>
                                        )
                                            :

                                            //if unique fdesMere

                                            <div className="w-auto pr-1">
                                                {familyProductChild?.documentation?.fdesMeres?.elements[0]?.file && (
                                                    <a href={familyProductChild.documentation.fdesMeres.elements[0].file} target="_blank" rel="noopener noreferrer">
                                                        <i className="feather icon-file-text m-0 onHover" data-tip={"Voir la FDES mère"} />
                                                        <ReactTooltip
                                                            place="top"
                                                            type="info"
                                                            className="basic-tooltip"
                                                        />
                                                    </a>
                                                )}
                                            </div>
                                    }

                                    {/* edit */}

                                    {aggregatedFamilyProducts.find(x => x.id === familyProductChild._id)?.isVisible && (
                                        <>
                                            {familyProductChild.currentFdes?._id && familyProductChild._id && !familyProductChild.maintenance && familyProductChild.children?.length === 0 && (
                                                aggregatedFamilyProducts.find(x => x.id === familyProductChild._id)?.isAccessible ?

                                                    //if edit is accessible
                                                    <Link to={`/fdes/${familyProductChild._id}`} className="w-autor">
                                                        <i className="feather icon-edit m-0 mr-1 onHover" data-tip={familyProductChild.content && familyProductChild.content.helpTextLabel && familyProductChild.content.helpTextLabel ? familyProductChild.content.helpTextLabel : 'Réaliser une FDES'} />
                                                        <ReactTooltip
                                                            place="top"
                                                            type="info"
                                                            className="basic-tooltip"
                                                        />
                                                    </Link>
                                                    :

                                                    //if edit is not accessible

                                                    <div className="w-auto">
                                                        <i className="feather icon-edit m-0 mr-1" data-tip={"Cette fonctionnalité n’est pas accessible à votre niveau d’utilisation"} disable="true" />
                                                        <ReactTooltip
                                                            place="top"
                                                            type="info"
                                                            className="basic-tooltip"
                                                        />
                                                    </div>
                                            )}

                                            {/* sub-sub-families */}

                                            {familyProductChild.children?.length > 0 && 
                                            // if sub-sub-families length > 1
                                            familyProductChild.children.filter((subSubFamily) => aggregatedFamilyProducts.find((x) => x.id === subSubFamily._id && x.isVisible)).length > 1 ? (
                                                <div className="dropdown w-auto">
                                                    {// eslint-disable-next-line
                                                        <a type="button" id={familyProductChild._id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-tip={familyProductChild.content && familyProductChild.content.helpTextLabel && familyProductChild.content.helpTextLabel ? familyProductChild.content.helpTextLabel : 'Réaliser une FDES'}>
                                                            <div className="button-container onHover">
                                                                <i className="feather icon-edit m-0"/>
                                                                <i className="feather icon-chevron-down m-0" style={{fontSize: "1rem"}}/>
                                                            </div>
                                                            <ReactTooltip
                                                                place="top"
                                                                type="info"
                                                                className="basic-tooltip"
                                                            />
                                                        </a>
                                                    }
                                                    <div className="dropdown-menu text-nowrap" aria-labelledby={familyProductChild._id}>
                                                        {familyProductsFilters.map(familyProductId => familyProductChild?.children.map((x, i) => x._id === familyProductId && aggregatedFamilyProducts.find(z => z.id === x._id)?.isVisible &&
                                                            (x.currentFdes?.active && aggregatedFamilyProducts.find(y => y.id === x._id)?.isAccessible ?
                                                                <Link key={i + 'productFamily'} to={`/fdes/${x.currentFdes.productFamily}`}>
                                                                    <p className="px-1">{x.name}</p>
                                                                </Link>
                                                                :
                                                                <div key={i + 'productFamily'}>
                                                                    <p className="px-1" data-tip={"Cette fonctionnalité n’est pas encore accessible aux utilisateurs"} >{x.name}</p>
                                                                    <ReactTooltip
                                                                        id={`sub-sub-family-tooltip-${i}`}
                                                                        place="top"
                                                                        type="info"
                                                                        className="basic-tooltip"
                                                                    />
                                                                </div>
                                                            )))}
                                                    </div>
                                                </div>
                                            ) : //if sub-sub-families length = 1
                                                familyProductsFilters.map(familyProductId => familyProductChild?.children.map((x, i) => x._id === familyProductId && aggregatedFamilyProducts.find(z => z.id === x._id)?.isVisible && 
                                                    (x.currentFdes?.active && aggregatedFamilyProducts.find(y => y.id === x._id)?.isAccessible ? 
                                                    <Link key={i + 'productFamily'} to={`/fdes/${x.currentFdes.productFamily}`} className="w-auto">
                                                        <i className="feather icon-edit m-0 mr-1 onHover" data-tip={x.name}/>
                                                        <ReactTooltip
                                                            place="top"
                                                            type="info"
                                                            className="basic-tooltip"
                                                        /></Link>
                                                    :

                                                    //if edit is not accessible

                                                    <div key={i + 'productFamily'} className="w-auto">
                                                        <i className="feather icon-edit m-0 mr-1" data-tip={"Cette fonctionnalité n’est pas accessible à votre niveau d’utilisation"} disable="true" />
                                                        <ReactTooltip
                                                            place="top"
                                                            type="info"
                                                            className="basic-tooltip"
                                                        />
                                                    </div>
                                                    ))
                                                )
                                            }
                                        </>
                                    )}
                                </div>

                                {/* if familly under maintenance */}

                                {familyProductChild.currentFdes && familyProductChild.currentFdes._id && familyProductChild._id && familyProductChild.maintenance &&
                                    <div className="button-container">
                                        <i className="feather icon-alert-triangle danger darken-4"
                                            data-tip="Famille de produit en maintenance."
                                            data-for={`maintenance${familyProductChild._id}`}
                                        />
                                        <ReactTooltip
                                            id={`maintenance${familyProductChild._id}`}
                                            place="right"
                                            type="info"
                                            className="basic-tooltip"
                                        />
                                    </div>
                                }
                            </div>
                        </li>

                    )
                )
                }
            </ul >
        )

    )

};

export default FamilyProductGroup;
