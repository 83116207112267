import { call, put, all, select, takeLatest } from "redux-saga/effects";
import {
  createConfiguratorCall,
  deleteConfiguratorCall,
  getConfiguratorsCall,
  getFilteredConfiguratorsCall,
  updateConfiguratorCall,
  sendFileToUploader,
  getEDECConfiguratorCall,
  createEDECConfiguratorCall,
  updateEDECConfiguratorCall,
  deleteEDECConfiguratorCall,
} from "../../../APIClient";
import { parseResponse } from "../../../utils/http";
import {
  CONFIGURATOR_FORM_DELETE,
  CONFIGURATOR_FORM_DELETE_FAILED,
  CONFIGURATOR_FORM_DELETE_SUCCESS,
  CONFIGURATOR_FORM_FAILED,
  CONFIGURATOR_FORM_SEND,
  CONFIGURATOR_FORM_SUCCESS,
  CONFIGURATOR_FORM_UPDATE,
  CONFIGURATOR_FORM_UPDATE_FAILED,
  CONFIGURATOR_FORM_UPDATE_SUCCESS,
  GET_CONFIGURATORS_FAILED,
  GET_CONFIGURATORS_SEND,
  GET_CONFIGURATORS_SUCCESS,
  GET_LOCAL_CONFIGURATOR_SUCCESS,
  GET_LOCAL_CONFIGURATOR_FAILED,
  GET_EDEC_CONFIGURATOR_SEND,
  GET_EDEC_CONFIGURATOR_SUCCESS,
  GET_EDEC_CONFIGURATOR_FAILED,
  EDEC_CONFIGURATOR_FORM_SEND,
  EDEC_CONFIGURATOR_FORM_SUCCESS,
  EDEC_CONFIGURATOR_FORM_FAILED,
  EDEC_CONFIGURATOR_FORM_UPDATE,
  EDEC_CONFIGURATOR_FORM_UPDATE_SUCCESS,
  EDEC_CONFIGURATOR_FORM_UPDATE_FAILED,
  EDEC_CONFIGURATOR_FORM_DELETE,
  EDEC_CONFIGURATOR_FORM_DELETE_SUCCESS,
  EDEC_CONFIGURATOR_FORM_DELETE_FAILED,
  GET_LOCAL_EDEC_INTEGRATION_SUCCESS,
  GET_LOCAL_EDEC_INTEGRATION_FAILED
} from "../constants";
import {
  formatFilePayload,
  formatPostConfiguratorPayload,
  formatPostEDECConfiguratorPayload,
} from "../../../utils/transformer";
import { toast } from "react-toastify";
import { GET_LOCAL_CONFIGURATOR, GET_LOCAL_EDEC_INTEGRATION } from "../../../common/constants/action-types";
import {
  getLocalConfiguration,
  getEDECConfiguratorSend,
} from "../actions";
import { push } from "redux-first-history";

function* getConfigurators() {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    const data = yield call(getConfiguratorsCall, token);
    yield call(parseResponse, data, GET_CONFIGURATORS_SUCCESS);
  } catch (error) {
    yield put({ type: GET_CONFIGURATORS_FAILED, error });
  }
}

function* postConfigurators(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    let imageUri = "";
    let secondaryImageUri = "";
    let documentationAboutImage = "";
    let documentationGuideFile = "";
    let documentationMethodologyFile = "";

    if (action.values && action.values.file && action.values.file.name) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.file)
      );
      imageUri = image.url;
    }
    if (
      action.values &&
      action.values.secondaryLogo &&
      action.values.secondaryLogo.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.secondaryLogo)
      );
      secondaryImageUri = image.url;
    }
    if (
      action.values &&
      action.values.aboutImage &&
      action.values.aboutImage.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.aboutImage)
      );
      documentationAboutImage = image.url;
    }

    if (
      action.values &&
      action.values.guideFile &&
      action.values.guideFile.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.guideFile)
      );
      documentationGuideFile = image.url;
    }

    if (
      action.values &&
      action.values.methodologyFile &&
      action.values.methodologyFile.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.methodologyFile)
      );
      documentationMethodologyFile = image.url;
    }

    const data = yield call(
      createConfiguratorCall,
      token,
      formatPostConfiguratorPayload(
        action.values,
        imageUri,
        secondaryImageUri,
        documentationAboutImage,
        documentationGuideFile,
        documentationMethodologyFile
      )
    );
    
    if(action.values.EDECConfigurator !== "" && action.values.EDECKeyConfigurator !== "") {
      yield call(
        createEDECConfiguratorCall,
        token,
        formatPostEDECConfiguratorPayload({
          id: data._id,
          EDECConfigurator: action.values.EDECConfigurator,
          EDECKeyConfigurator: action.values.EDECKeyConfigurator
        })
      )
    }

    yield call(parseResponse, data, CONFIGURATOR_FORM_SUCCESS);
    yield put(getLocalConfiguration());
    yield put(push("/configurators"));
  } catch (error) {
    yield put({ type: CONFIGURATOR_FORM_FAILED, error });
  }
}

function* patchConfigurator(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    let imageUri = "";
    let secondaryImageUri = "";
    let documentationAboutImage = "";
    let documentationGuideFile = "";
    let documentationMethodologyFile = "";
    let documentationFdesMereElementsFiles = [];
    let documentationFdesMereElementsPictures = [];

    if (action.values && action.values.file && action.values.file.name) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.file)
      );
      imageUri = image.url;
    }
    if (
      action.values &&
      action.values.secondaryLogo &&
      action.values.secondaryLogo.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.secondaryLogo)
      );
      secondaryImageUri = image.url;
    }
    if (
      action.values &&
      action.values.aboutImage &&
      action.values.aboutImage.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.aboutImage)
      );
      documentationAboutImage = image.url;
    }

    if (
      action.values &&
      action.values.guideFile &&
      action.values.guideFile.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.guideFile)
      );
      documentationGuideFile = image.url;
    }

    if (
      action.values &&
      action.values.methodologyFile &&
      action.values.methodologyFile.name
    ) {
      const image = yield call(
        sendFileToUploader,
        token,
        formatFilePayload(action.values.methodologyFile)
      );
      documentationMethodologyFile = image.url;
    }

    if (
      action.values &&
      action.values.documentation &&
      action.values.documentation.fdesMeres &&
      action.values.documentation.fdesMeres.elements
    ) {
      documentationFdesMereElementsFiles = yield all(
        action.values.documentation.fdesMeres.elements
          .filter((x) => x.fileToSend && x.fileToSend.name)
          .map((element) =>
            call(
              sendFileToUploader,
              token,
              formatFilePayload(element.fileToSend)
            )
          )
      );
      documentationFdesMereElementsPictures = yield all(
        action.values.documentation.fdesMeres.elements
          .filter((x) => x.imageToSend && x.imageToSend.name)
          .map((element) =>
            call(
              sendFileToUploader,
              token,
              formatFilePayload(element.imageToSend)
            )
          )
      );
    }

    const formattedConfigurator = formatPostConfiguratorPayload(
      action.values,
      imageUri,
      secondaryImageUri,
      documentationAboutImage,
      documentationGuideFile,
      documentationMethodologyFile,
      documentationFdesMereElementsFiles,
      documentationFdesMereElementsPictures
    );
    const data = yield call(
      updateConfiguratorCall,
      token,
      formattedConfigurator,
      formattedConfigurator.id
    );
    yield call(parseResponse, data, CONFIGURATOR_FORM_UPDATE_SUCCESS);
    
    yield put(getLocalConfiguration());
    yield put(push("/configurators"));
    yield put(toast.success("Modification enregistrée"));
  } catch (error) {
    yield put({ type: CONFIGURATOR_FORM_UPDATE_FAILED, error });
  }
}

function* deleteConfigurator(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);

    yield call(deleteConfiguratorCall, token, action.configuratorId);
    yield call(
      parseResponse,
      action.configuratorId,
      CONFIGURATOR_FORM_DELETE_SUCCESS
    );
  } catch (error) {
    yield put({ type: CONFIGURATOR_FORM_DELETE_FAILED, error });
  }
}

function* getLocalConfigurator() {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    const data = yield call(getFilteredConfiguratorsCall, token);
    if (data && data.total > 0) {
      yield call(parseResponse, data.data[0], GET_LOCAL_CONFIGURATOR_SUCCESS);
    }
  } catch (error) {
    yield put({ type: GET_LOCAL_CONFIGURATOR_FAILED, error });
  }
}

function* getLocalEDECIntegration() {
  const getToken = (state) => state.user.informations.token;
  const getLocalConfigurator = (state) => state.configurator.localConfigurator;
  try {
    const token = yield select(getToken);
    const localConfigurator = yield select(getLocalConfigurator);
    
    const data = yield call(
      getEDECConfiguratorCall,
      token,
      localConfigurator._id
    );
    if (data && data.total > 0) {
      yield call(parseResponse, data, GET_LOCAL_EDEC_INTEGRATION_SUCCESS);
    }
  } catch (error) {
    yield put({ type: GET_LOCAL_EDEC_INTEGRATION_FAILED, error });
  }
}

function* getEDECConfigurator(action) {
  const getToken = (state) => state.user.informations.token;
  
  try {
    const token = yield select(getToken);
    const data = yield call(
      getEDECConfiguratorCall,
      token,
      action.configuratorId
    );
    if (data && data.total > 0) {
      yield call(parseResponse, data, GET_EDEC_CONFIGURATOR_SUCCESS);
    }
  } catch (error) {
    yield put({ type: GET_EDEC_CONFIGURATOR_FAILED, error });
  }
}

function* postEDECConfigurator(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);

    const data = yield call(
      createEDECConfiguratorCall,
      token,
      formatPostEDECConfiguratorPayload(action.values)
    );
    yield call(parseResponse, data, EDEC_CONFIGURATOR_FORM_SUCCESS);
    yield put(getEDECConfiguratorSend(action.values.id));
  } catch (error) {
    yield put({ type: EDEC_CONFIGURATOR_FORM_FAILED, error });
  }
}

function* patchEDECConfigurator(action) {
  const getToken = (state) => state.user.informations.token;
  try {
    const token = yield select(getToken);

    const data = yield call(
      updateEDECConfiguratorCall,
      token,
      formatPostEDECConfiguratorPayload(action.values),
      action.values.EDECConfiguratorId
    );
    yield call(parseResponse, data, EDEC_CONFIGURATOR_FORM_UPDATE_SUCCESS);
    yield put(getEDECConfiguratorSend(action.values.id));
    
    yield put(toast.success("EDEC Modification enregistrée"));
  } catch (error) {
    yield put({ type: EDEC_CONFIGURATOR_FORM_UPDATE_FAILED, error });
  }
}

function* deleteEDECConfigurator(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);

    yield call(deleteEDECConfiguratorCall, token, action.EDECConfiguratorId); //CHECK action object
    yield call(
      parseResponse,
      action.configuratorId,
      EDEC_CONFIGURATOR_FORM_DELETE_SUCCESS
    );
  } catch (error) {
    yield put({ type: EDEC_CONFIGURATOR_FORM_DELETE_FAILED, error });
  }
}

export const configuratorSagas = [
  takeLatest(GET_CONFIGURATORS_SEND, getConfigurators),
  takeLatest(CONFIGURATOR_FORM_SEND, postConfigurators),
  takeLatest(CONFIGURATOR_FORM_UPDATE, patchConfigurator),
  takeLatest(CONFIGURATOR_FORM_DELETE, deleteConfigurator),
  takeLatest(GET_LOCAL_CONFIGURATOR, getLocalConfigurator),
  takeLatest(GET_EDEC_CONFIGURATOR_SEND, getEDECConfigurator),
  takeLatest(EDEC_CONFIGURATOR_FORM_SEND, postEDECConfigurator),
  takeLatest(EDEC_CONFIGURATOR_FORM_UPDATE, patchEDECConfigurator),
  takeLatest(EDEC_CONFIGURATOR_FORM_DELETE, deleteEDECConfigurator),
  takeLatest(GET_LOCAL_EDEC_INTEGRATION, getLocalEDECIntegration),
];
