import React, { useEffect, useState } from 'react';

const CollapsableCard = ({ children, title, defaultOpen, classes, onChangeOpen }) => {

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (defaultOpen !== undefined) {
      setIsOpen(defaultOpen);
    }
  }, [defaultOpen]);

  function handleOpen() {
    setIsOpen(!isOpen);
    if (onChangeOpen) {
      onChangeOpen(!isOpen)
    }
  }

  return (
    <div className={`card ${classes}`} key={title}>
      <div className="card-header">
        <h4 className="card-title">{title}</h4>
        <div className="heading-elements">
          <ul className="list-inline mb-0">
            <li>
              {
                //eslint-disable-next-line
                <a type="button" data-action="collapse" onClick={() => handleOpen()}>
                  {isOpen ? <i className="feather icon-minus" /> : <i className="feather icon-plus" />}</a>
              }
            </li>
          </ul>
        </div>
      </div>
      {isOpen && (
        <div className="collapse show" aria-expanded="true">
          <div className="card-body">
            {children}
          </div>
        </div>
      )}
    </div>

  )
};

export default CollapsableCard;
