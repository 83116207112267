export const OPEN_LOGIN = 'OPEN_LOGIN';
export const CLOSE_LOGIN = 'CLOSE_LOGIN';
export const LOGIN_FORM_SEND = 'LOGIN_FORM_SEND';
export const LOGIN_FORM_SUCCESS = 'LOGIN_FORM_SUCCESS';
export const LOGIN_SAML = 'LOGIN_SAML';
export const LOGIN_FORM_FAILED = 'LOGIN_FORM_FAILED';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS';
export const CHECK_LOGIN_FAILED = 'CHECK_LOGIN_FAILED';
export const DISCONNECT_USER = 'DISCONNECT_USER';
export const GET_LOCAL_CONFIGURATOR = 'GET_LOCAL_CONFIGURATOR';
export const NOT_FOUND = 'NOT_FOUND';
export const CONFLICT = 'CONFLICT';
export const AUTH_FORBIDDEN = 'AUTH_FORBIDDEN';
export const AUTH_FAILED = 'AUTH_FAILED';
export const SERVER_ERROR = 'SERVER_ERROR';
export const RESENT_VALIDATION = 'RESENT_VALIDATION';
export const BAD_REQUEST = 'BAD_REQUEST';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const RESET_LOGIN_ATTEMPT = 'RESET_LOGIN_ATTEMPT';
export const LOGIN_ATTEMPT_NOT_VERIFIED = 'LOGIN_ATTEMPT_NOT_VERIFIED';
export const RESENT_VALIDATION_SUCCESS = 'RESENT_VALIDATION_SUCCESS';
export const PERMISSION_REFUSED = 'PERMISSION_REFUSED';
export const SEND_RECOVER_PASSWORD_FORM = 'SEND_RECOVER_PASSWORD_FORM';
export const SAVE_RECOVER_PASSWORD_FORM = 'SAVE_RECOVER_PASSWORD_FORM';
export const DISABLE_FAMILY_PRODUCT_FORM_LOADER = 'DISABLE_FAMILY_PRODUCT_FORM_LOADER';
export const DISABLE_GROUP_FORM_LOADER = 'DISABLE_GROUP_FORM_LOADER';
export const SAVE_REGISTER_FORM = 'SAVE_REGISTER_FORM';
export const SAVE_REGISTER_FORM_SUCCESS = 'SAVE_REGISTER_FORM_SUCCESS';
export const SEND_VERIFICATION = 'SEND_VERIFICATION';
export const SAVE_VERIFICATION_SUCCESS = 'SAVE_VERIFICATION_SUCCESS';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const SAVE_VERIFICATION_FAILED = 'SAVE_VERIFICATION_FAILED';
export const GET_LOCAL_EDEC_INTEGRATION = 'GET_LOCAL_EDEC_INTEGRATION';
export const NOT_ACCEPTABLE = 'NOT_ACCEPTABLE';