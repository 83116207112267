export const GET_CONFIGURATORS_SEND = 'GET_CONFIGURATORS_SEND';
export const GET_CONFIGURATORS_SUCCESS = 'GET_CONFIGURATORS_SUCCESS';
export const GET_CONFIGURATORS_FAILED = 'GET_CONFIGURATORS_FAILED';
export const CONFIGURATOR_FORM_SEND = 'CONFIGURATOR_FORM_SEND';
export const CONFIGURATOR_FORM_SUCCESS = 'CONFIGURATOR_FORM_SUCCESS';
export const CONFIGURATOR_FORM_FAILED = 'CONFIGURATOR_FORM_FAILED';
export const CONFIGURATOR_FORM_UPDATE = 'CONFIGURATOR_FORM_UPDATE';
export const CONFIGURATOR_FORM_UPDATE_SUCCESS = 'CONFIGURATOR_FORM_UPDATE_SUCCESS';
export const CONFIGURATOR_FORM_UPDATE_FAILED = 'CONFIGURATOR_FORM_UPDATE_FAILED';
export const CONFIGURATOR_FORM_DELETE = 'CONFIGURATOR_FORM_DELETE';
export const CONFIGURATOR_FORM_DELETE_SUCCESS = 'CONFIGURATOR_FORM_DELETE_SUCCESS';
export const CONFIGURATOR_FORM_DELETE_FAILED = 'CONFIGURATOR_FORM_DELETE_FAILED';
export const GET_LOCAL_CONFIGURATOR_SUCCESS = 'GET_LOCAL_CONFIGURATOR_SUCCESS';
export const GET_LOCAL_CONFIGURATOR_FAILED = 'GET_LOCAL_CONFIGURATOR_FAILED';
export const GET_EDEC_CONFIGURATOR_SEND = 'GET_EDEC_CONFIGURATOR_SEND';
export const GET_EDEC_CONFIGURATOR_SUCCESS = 'GET_EDEC_CONFIGURATOR_SUCCESS';
export const GET_EDEC_CONFIGURATOR_FAILED = 'GET_EDEC_CONFIGURATOR_FAILED';
export const EDEC_CONFIGURATOR_FORM_SEND = 'EDEC_CONFIGURATOR_FORM_SEND';
export const EDEC_CONFIGURATOR_FORM_SUCCESS = 'EDEC_CONFIGURATOR_FORM_SUCCESS';
export const EDEC_CONFIGURATOR_FORM_FAILED = 'EDEC_CONFIGURATOR_FORM_FAILED';
export const EDEC_CONFIGURATOR_FORM_UPDATE = 'EDEC_CONFIGURATOR_FORM_UPDATE';
export const EDEC_CONFIGURATOR_FORM_UPDATE_SUCCESS = 'EDEC_CONFIGURATOR_FORM_UPDATE_SUCCESS';
export const EDEC_CONFIGURATOR_FORM_UPDATE_FAILED = 'EDEC_CONFIGURATOR_FORM_UPDATE_FAILED';
export const EDEC_CONFIGURATOR_FORM_DELETE = 'EDEC_CONFIGURATOR_FORM_DELETE';
export const EDEC_CONFIGURATOR_FORM_DELETE_SUCCESS = 'EDEC_CONFIGURATOR_FORM_DELETE_SUCCESS';
export const EDEC_CONFIGURATOR_FORM_DELETE_FAILED = 'EDEC_CONFIGURATOR_FORM_DELETE_FAILED';
export const GET_LOCAL_EDEC_INTEGRATION_SUCCESS = 'GET_LOCAL_EDEC_INTEGRATION_SUCCESS';
export const GET_LOCAL_EDEC_INTEGRATION_FAILED = 'GET_LOCAL_EDEC_INTEGRATION_FAILED';
