import {
    GET_GROUPS,
    LOAD_MODEL_FOR_GROUP,
    SAVE_GROUP_FORM,
    SEARCH_USERS_BY_CONFIGURATOR,
    DELETE_GROUP,
    UPDATE_GROUP_FORM,
    CLEAN_MODELS,
    CLEAN_USERS,
    GET_GROUPS_BY_USERID
} from '../constants'

export function getGroups() {
    return {
        type: GET_GROUPS
    };
}
export function getGroupsByUserId(userId) {
    return {
        type: GET_GROUPS_BY_USERID, userId
    };
}
export function saveGroupForm(values) {
    return {
        type: SAVE_GROUP_FORM, values
    };
}
export function searchUsersByConfigurator(configuratorId, values) {
    return {
        type: SEARCH_USERS_BY_CONFIGURATOR, configuratorId, values
    };
}
export function updateGroupForm(values) {
    return {
        type: UPDATE_GROUP_FORM, values
    };
}

export function deleteGroup(groupId) {
    return {
        type: DELETE_GROUP, groupId
    };
}
export function loadModelForGroup(groupId) {
    return {
        type: LOAD_MODEL_FOR_GROUP, groupId
    };
}

export function cleanUsers() {
    return {
        type: CLEAN_USERS
    };
}

export function cleanModels() {
    return {
        type: CLEAN_MODELS
    };
}