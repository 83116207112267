import {connect} from 'react-redux';
import {
    closeLogin,
    sendFormLogin,
    sendRecoverPasswordForm,
    resentValidation, resetLoginAttempt
} from "../actions/user";
import Login from "../components/Login";

export default connect(
    (state) => ({
        loginAttempt: state.user.loginAttempt,
        localConfigurator: state.configurator.localConfigurator,
        error: state.user.error,
        sendRecoverPasswordFormIsLoading: state.loader.sendRecoverPasswordFormIsLoading
    }),
    (dispatch) => ({
        resetLoginAttempt: () => {
            dispatch(resetLoginAttempt());
        },
        resentValidation: () => {
            dispatch(resentValidation());
        },
        onClose: () => {
            dispatch(closeLogin());
        },
        sendFormLogin: (values) => {
            dispatch(sendFormLogin(values));
        },
        sendRecoverPasswordForm: (values) => {
            dispatch(sendRecoverPasswordForm(values));
        },
    })

)(Login);
