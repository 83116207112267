import React from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DuplicateModal = ({ isOpen, onClose, onSubmit }) => {

    return (
        <Modal show={isOpen} onHide={() => onClose()}>
            <Modal.Body>
                Confirmer la duplication ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => onClose()}>Annuler</Button>{' '}
                <Button variant="success" onClick={() => onSubmit()}>Dupliquer</Button>
            </Modal.Footer>
        </Modal>
    )

};

export default DuplicateModal;
