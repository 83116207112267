import React from 'react';
import A from "../../../assets/A.png";
import B from "../../../assets/B.png";
import C from "../../../assets/C.png";
import D from "../../../assets/D.png";
import E from "../../../assets/E.png";

const LabelChart = ({ value }) => {


    function displayLabel() {
        switch (value.toUpperCase()) {
            case 'A':
                return <img src={A} width={'100%'} alt='ADME Chart Label A' />
            case 'B':
                return <img src={B} width={'100%'} alt='ADME Chart Label B' />
            case 'C':
                return <img src={C} width={'100%'} alt='ADME Chart Label C' />
            case 'D':
                return <img src={D} width={'100%'} alt='ADME Chart Label D' />
            case 'E':
                return <img src={E} width={'100%'} alt='ADME Chart Label E' />
            default:
                return;
        }
    }

    return (
        <>
            {displayLabel()}
        </>
    )
};

export default LabelChart;