import React from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import { Link } from "react-router-dom";
import { withPermissions } from "../../../layout/Permissions";
import { PERMISSION_SUPER_ADMIN } from "../../user/constants/permissions";
import ManageGroupForm from '../containers/ManageGroupForm';

const ManageGroupCreatePage = () => {

    return (
        <>
            <Breadcrumb title="Créer un groupe">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/manage-group">Gestions des groupes</Link></li>
                    <li className="breadcrumb-item active">Créer un groupe</li>
                </ol>
            </Breadcrumb>
            <ManageGroupForm action={"CREATE"} />
        </>
    )
};
export default withPermissions(ManageGroupCreatePage, PERMISSION_SUPER_ADMIN);

