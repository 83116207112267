import { call, select, takeLatest } from "redux-saga/effects";
import { sendEmailCall } from "../../../APIClient";
import { SEND_EMAIL, SEND_EMAIL_SUCCESS } from "../constants";
import { parseResponse } from "../../../utils/http";

function* sendEmailFunction(action) {
    const getToken = (state) => state.user.informations.token;
    const getLocalConfigurator = (state) => state.configurator.localConfigurator;
    try {
        const token = yield select(getToken);
        const localConfigurator = yield select(getLocalConfigurator);
        const data = yield call(sendEmailCall, token, localConfigurator._id, action.values);
        yield call(parseResponse, data, SEND_EMAIL_SUCCESS);
    } catch (error) {

    }
}

export const assistanceSagas = [
    takeLatest(SEND_EMAIL, sendEmailFunction),
];