import {connect} from 'react-redux';
import UserForm from '../components/UserForm';
import {userFormSend, updateUserForm} from "../actions";
import {getConfiguratorsSend} from "../../configurator/actions";
import {getGroupsByUserId} from "../../manage-group/actions";

export default connect(
    (state) => ({
        users: state.user.users,
        configurators: state.configurator.configurators,
        localConfigurator: state.configurator.localConfigurator,
        userInformations: state.user.informations,
        userGroups: state.manageGroup.userGroups
    }),
    (dispatch) => ({
        sendUserForm: (values) => {
            dispatch(userFormSend(values))
        },
        updateUserForm: (values) => {
            dispatch(updateUserForm(values))
        },
        getConfigurators: () => {
            dispatch(getConfiguratorsSend())
        },
        getGroupsByUserId: (userId) => {
            dispatch(getGroupsByUserId(userId))
        }
    })

)(UserForm);