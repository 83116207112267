import React from 'react';
import {Route, Routes} from 'react-router-dom';
import routes from './routes';
import Layout from "../common/containers/Layout";
import {HistoryRouter as Router} from "redux-first-history/rr6";
import {history} from '../store';

const AppRoutes = () => (
  <Router history={history}>
    <Layout>
      <Routes>
        {routes.map((route, i) => {
          return <Route key={i} {...route} element={<route.element />} />;
        })}
      </Routes>
    </Layout>
  </Router>
);

export default AppRoutes;
