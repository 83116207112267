import {connect} from 'react-redux';
import WelcomeContent from '../components/WelcomeContent';

export default connect(
    (state) => ({
        localConfigurator:state.configurator.localConfigurator
    }),
    (dispatch)=> ({

    })

)(WelcomeContent);