export const PERMISSION_USER = 'USER';
export const PERMISSION_SUPER_ADMIN = 'SUPER_ADMIN';
export const PERMISSION_ORG_ADMIN = 'ORG_ADMIN';

export const permissionsWeight = {
    [PERMISSION_SUPER_ADMIN] : 50,
    [PERMISSION_ORG_ADMIN] : 25,
    [PERMISSION_USER] : 10,
};

export function userHasPermission(permissions, permissionRequired){
    if(permissions && permissions.length > 0){
        return permissions.some(permission => permissionsWeight[permission] >= permissionsWeight[permissionRequired])
    }
    return false;
}
