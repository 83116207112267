import { connect } from "react-redux";
import ConfiguratorEdit from "../pages/ConfiguratorEdit";
import { getEDECConfiguratorSend } from "../actions";

export default connect(
  (state) => ({
    configurators: state.configurator.configurators,
    EDECIntegration: state.configurator.EDECIntegration
  }),
  (dispatch) => ({
    getEDECConfiguratorRequest: (configuratorId) => {
      dispatch(getEDECConfiguratorSend(configuratorId));
    },
  })
)(ConfiguratorEdit);
