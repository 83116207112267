import React, {useEffect} from 'react';
import Loader from "../common/components/Loader";

const Verify = ({location, sendVerification, isLoading, isValid}) => {

    useEffect(() => {
        const token = new URLSearchParams(location.search).get("token");
        if (token) {
            sendVerification(token)
        }
    }, [location, sendVerification]);

    return (
        <>
            <Loader isActive={isLoading} />
            {!isLoading && isValid && (
                <p className="text-center">Votre compte est confirmé. Vous pouvez maintenant vous connecter</p>
            )}
            {!isLoading && !isValid && (
                <p className="text-center">Le lien a expiré.</p>
            )}
        </>
    )

};

export default Verify;
