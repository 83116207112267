import {connect} from 'react-redux';
import FdesFormGroup from "../components/FdesFormGroup";
import {changeFdesParameterForm, postFdesFile, saveFdesForm} from "../actions";

export default connect(
    (state) => ({
        fdesParameterGroups: state.fdes.fdesParameterGroups,
        localConfigurator: state.configurator.localConfigurator,
        fields: state.fdes.form.fields
    }),
    (dispatch) => ({
        onChangeFdesParameterForm: (paramId, value, isAuto) => {
            dispatch(changeFdesParameterForm(paramId, value));
            if (isAuto) {
                dispatch(saveFdesForm());
            }
        },
        uploadFile: (paramId, file, isAuto) => {
            dispatch(postFdesFile(paramId, file));
            if (isAuto) {
                dispatch(saveFdesForm());
            }
        }
    })

)(FdesFormGroup);