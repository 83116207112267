import Nouislider from "nouislider-react";
import React, {useEffect, useState} from "react";
import "nouislider/distribute/nouislider.css";
import {NumericFormat} from 'react-number-format';

function NumericContainer ({onChange, defaultValue, readOnly, validationRules, type}) {
    const [currentValue, setCurrentValue] = useState(defaultValue ? +defaultValue.toString().replace(',', '.').toLowerCase() : 0);
    const [prevValue, setPrevValue] = useState(defaultValue ? +defaultValue.toString().replace(',', '.').toLowerCase() : 0);
    const [error, setError] = useState('');
    const [min, setMin] = useState(undefined);
    const [max, setMax] = useState(undefined);
    useEffect(() => {
        setCurrentValue(defaultValue);
        setPrevValue(defaultValue);
        const validationRulesMin = validationRules?.find(x => x.type === "min");
        const validationRulesMax = validationRules?.find(x => x.type === "max");
        if (!validationRulesMin || isNaN(parseFloat(validationRulesMin.value))) {
            console.log('ALERT Percent Param (min) is not a number: ', validationRulesMin);
        }
        if (!validationRulesMax || isNaN(parseFloat(validationRulesMax.value))) {
            console.log('ALERT Percent Param (max) is not a number: ', validationRulesMax);
        }

        if (validationRulesMin?.value && validationRulesMin?.value!== '-') {
            setMin({type: 'min', value: +validationRulesMin.value.toString().replace(',', '.')});
        } else if(type === "numericslider") {
            setMin({type: 'min', value: 0});
        }
        if (validationRulesMax?.value &&  validationRulesMax?.value !== '-') {
            setMax({type: 'max', value: +validationRulesMax.value.toString().replace(',', '.')});
        } else if(type === "numericslider") {
            setMax({type: 'max', value: 1});
        }
    }, [defaultValue, validationRules])

    function handleEndSlider (value) {
        const tmpValue = parseFloat(value.toString().replace(',', '.').toLowerCase())
        if (checkAllowed(tmpValue)) {
            setPrevValue(tmpValue);
            setCurrentValue(+tmpValue.toString().replace(',', '.').toLowerCase());
            onChange(+tmpValue.toString().replace(',', '.').toLowerCase());
            setError("");
        }
        else {
            if (min !== undefined && max === undefined && tmpValue < min.value) {
                setError("La valeur saisie doit être supérieure ou égale à " + min.value.toString())
            }
            else if (min === undefined && max !== undefined && tmpValue > max.value) {
                setError("La valeur saisie doit être inférieure ou égale à " + max.value.toString())
            }
            else
                setError("La valeur saisie doit être comprise dans l’intervalle { " + (min && (min.value.toString() || '-')) + " ; " + (max && (max.value.toString() || '-')) + " }")
            setCurrentValue(+prevValue.toString().replace(',', '.').toLowerCase());
            onChange(+prevValue.toString().replace(',', '.').toLowerCase());
        }
    }

    function checkAllowed (tmpValue) {
        return (
            (min === undefined && max === undefined) ||
            (min !== undefined && max === undefined && tmpValue >= parseFloat(min.value)) ||
            (min === undefined && max !== undefined && tmpValue <= parseFloat(max.value)) ||
            (min !== undefined && max !== undefined && tmpValue >= parseFloat(min.value) && tmpValue <= parseFloat(max.value))
        )
    }

    return <div className="slider-container">
        {(type === "numericslider") && (min !== undefined && max !== undefined) && (
            <Nouislider
                range={
                    {
                        ...((min.value || min.value === 0)  && { min: +parseFloat(min.value) }), 
                        ...((max.value || max.value === 0)  && { max: +parseFloat(max.value) })
                    }
                }
                start={+prevValue}
                onEnd={(e) => {handleEndSlider(+e)}}
                onChange={(e) => setCurrentValue(+e)}
                onSlide={(e) => setCurrentValue(+e)}
                disabled={readOnly}
                connect />
        )}
        <NumericFormat
            type="text"
            decimalSeparator=","
            valueIsNumericString={true}
            value={currentValue}
            onValueChange={(e) => setCurrentValue(e.floatValue)}
            onBlur={(e) => handleEndSlider(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" ? handleEndSlider(e.target.value) : ''}
            className="form-control"
            disabled={readOnly}
        />
        <p style={{color: "red"}}>{error}</p>
    </div>
}

export default NumericContainer;
