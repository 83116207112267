import FamilyProductGroup from "./FamilyProductGroup";

const FamilyProductParent = ({familyProduct, familyProductsFilters, aggregatedFamilyProducts}) => {
    return (
        familyProductsFilters && familyProductsFilters.includes(familyProduct._id) &&
        <>
            {aggregatedFamilyProducts?.find(x => x.id === familyProduct._id)?.isVisible && (
                <div className="card" key={familyProduct._id}>
                    <div className="card-content">
                        <img src={familyProduct.image} className="card-img-top img-fluid" alt={familyProduct.shortLabel} />
                        {(familyProduct.shortLabel || familyProduct.description) && (
                            <div className="card-body">
                                {familyProduct.shortLabel && (
                                    <h4 className="card-title">{familyProduct.shortLabel}</h4>
                                )}
                                {familyProduct.description && (
                                    <p className="card-text">{familyProduct && familyProduct.description ? familyProduct.description : ''}</p>
                                )}
                            </div>
                        )}
                        {familyProduct && familyProduct.children && familyProduct.children.length > 0 && (
                            <FamilyProductGroup isOpen familyProduct={familyProduct} familyProductsFilters={familyProductsFilters} aggregatedFamilyProducts={aggregatedFamilyProducts} />
                        )}
                    </div>
                </div>)
            }
        </>
    );
};

export default FamilyProductParent