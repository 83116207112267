import {
    AUTH_FORBIDDEN,
    BAD_REQUEST, CONFLICT,
    DISCONNECT_USER,
    NOT_FOUND,
    OPEN_LOGIN,
    SERVER_ERROR,
    NOT_ACCEPTABLE
} from "../common/constants/action-types";
import {put, call} from 'redux-saga/effects'
import {toast} from 'react-toastify';
import {history} from "../store";

const JsonWebTokenError = "JsonWebTokenError";
const TokenExpiredError = "TokenExpiredError";

export const CURRENT_DOMAIN = (window && window.location) ? window.location.hostname : '';

export const noMenuLayoutLocations = ['/register', '/login'];
export const noCheckLoginLocations = ['/recover-password'];

export function* parseResponse (response, type) {

    switch (response.code) {
        case 400:
            yield call(toast.error, response.message);
            yield put({type: BAD_REQUEST, payload: response});
            throw Error('');

        case 401:
            if (response && response.data && response.data.name) {
                switch (response.data.name) {
                    case TokenExpiredError:
                        yield call(toast.error, "Session expirée");
                        yield call(history.push, "/");
                        yield put({type: DISCONNECT_USER});
                        yield put({type: OPEN_LOGIN});
                        break;
                    case JsonWebTokenError:
                        yield call(toast.error, "Token invalide");
                        yield call(history.push, "/");
                        yield put({type: DISCONNECT_USER});
                        yield put({type: OPEN_LOGIN});
                        break;
                    default:
                        break;
                }
            } else if (response && response.className === 'not-authenticated') {
                yield call(toast.error, "Token invalide");
                yield call(history.push, "/");
                yield put({type: DISCONNECT_USER});
                yield put({type: OPEN_LOGIN});
            } else {
                yield call(toast.error, "Login invalide");
                yield call(history.push, "/");
                yield put({type: DISCONNECT_USER});
                yield put({type: OPEN_LOGIN});
            }
            throw Error('');

        case 403:
            yield call(toast.error, "Droits insuffisants");
            yield put({type: AUTH_FORBIDDEN, payload: response});
            throw Error('');

        case 404:
            yield call(toast.error, response.message);
            yield put({type: NOT_FOUND, payload: response});
            throw Error('');
            
        case 406:
            yield call(toast.error, response.message);
            yield put({type: NOT_ACCEPTABLE, payload: response});
            throw Error('');
            
        case 409:
            yield call(toast.error, "Email déjà enregistré");
            yield put({type: CONFLICT, payload: response});
            throw Error('');

        case 503:
        case 500:
            yield call(toast.error, response.message);
            yield put({type: SERVER_ERROR, payload: response});
            throw Error('');

        default:
            yield put({type: type, payload: response});
            break;
    }
}