import {connect} from 'react-redux';
import ManageGroupForm from '../components/ManageGroupForm';
import {saveGroupForm, updateGroupForm, searchUsersByConfigurator, cleanUsers} from "../actions";
import {getConfiguratorsSend} from '../../configurator/actions';

export default connect(
    (state) => ({
        familyProducts: state.familyProduct.familyProducts,
        manageGroupFormIsLoading: state.loader.manageGroupFormIsLoading,
        configurators: state.configurator.configurators,
        localConfigurator: state.configurator.localConfigurator,
        users: state.manageGroup.users
    }),
    (dispatch) => ({
        saveGroupForm: (values) => {
            dispatch(saveGroupForm(values))
        },
        updateGroupForm: (values) => {
            dispatch(updateGroupForm(values))
        },
        searchUsersByConfigurator: (configuratorId, values) => {
            dispatch(searchUsersByConfigurator(configuratorId, values))
        },
        cleanUsers: () => {
            dispatch(cleanUsers())
        },
        getConfiguratorsRequest: () => {
            dispatch(getConfiguratorsSend())
        },
    })

)(ManageGroupForm);