import { DISABLE_FAMILY_PRODUCT_FORM_LOADER } from "../constants/action-types";
import {
    DISABLE_CALCUL_LOADER,
    DISABLE_REPORTS_LOADER,
    ENABLE_PDF_REPORT_LOADER,
    ENABLE_CALCUL_LOADER,
    ENABLE_REPORTS_LOADER, ENABLE_XML_REPORT_LOADER, DISABLE_PDF_REPORT_LOADER, DISABLE_XML_REPORT_LOADER
} from "../../modules/family-product/constants";
import {
    DISABLE_GROUP_FORM_LOADER
} from '../../modules/manage-group/constants'

export function disableFamilyProductFormLoader() {
    return {
        type: DISABLE_FAMILY_PRODUCT_FORM_LOADER
    };
}


export function disableGroupFormLoader() {
    return {
        type: DISABLE_GROUP_FORM_LOADER
    };
}

export function enableReportsLoader() {
    return {
        type: ENABLE_REPORTS_LOADER
    };
}

export function disableReportsLoader() {
    return {
        type: DISABLE_REPORTS_LOADER
    };
}

export function enableCalculLoader() {
    return {
        type: ENABLE_CALCUL_LOADER
    };
}

export function disableCalculLoader() {
    return {
        type: DISABLE_CALCUL_LOADER
    };
}

export function enablePdfReportLoader(calculId) {
    return {
        type: ENABLE_PDF_REPORT_LOADER, calculId
    };
}

export function disablePdfReportLoader() {
    return {
        type: DISABLE_PDF_REPORT_LOADER
    };
}

export function enableXmlReportLoader(calculId) {
    return {
        type: ENABLE_XML_REPORT_LOADER, calculId
    };
}

export function disableXmlReportLoader() {
    return {
        type: DISABLE_XML_REPORT_LOADER
    };
}