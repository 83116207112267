import {
  API_DELETE_CALCUL,
  API_DELETE_FAMILY_PRODUCT,
  API_DELETE_CONFIGURATORS,
  API_DELETE_USERS,
  API_FILE_UPLOAD,
  API_GET_CALCULS,
  API_GET_FAMILY_PRODUCTS,
  API_GET_FDES,
  API_GET_LOCAL_CONFIGURATORS,
  API_GET_MODELS,
  API_GET_CONFIGURATORS,
  API_GET_REFERENCE,
  API_GET_USERS,
  API_PATCH_FAMILY_PRODUCTS,
  API_PATCH_FDES,
  API_PATCH_CONFIGURATORS,
  API_PATCH_USERS,
  API_POST_FAMILY_PRODUCT,
  API_POST_FDES,
  API_POST_GENERATE_REPORT,
  API_POST_LOGIN,
  API_POST_CONFIGURATORS,
  API_POST_PARAMS,
  API_GOOGLE_POST_PARAMS,
  API_POST_RECOVER_PASSWORD,
  API_POST_REGISTER,
  API_POST_RESULT,
  API_POST_USERS,
  API_POST_VERIFICATION,
  API_PATCH_RESULT,
  API_UPGRADE_CALCUL,
  API_GET_CALCUL,
  API_FILE_UPLOADER,
  API_ASSET_UPLOADER,
  API_CSV_UPLOADER,
  API_CONNECT_ME,
  API_GET_GROUPS,
  API_GET_MODELS_FOR_GROUP,
  API_PATCH_GROUP,
  API_POST_GROUP,
  API_DELETE_GROUP,
  API_SEARCH_USERS_BY_CONFIGURATOR_ID,
  API_GET_GROUPS_BY_USERID,
  API_GET_AGGREGATED_FAMILY_PRODUCTS,
  API_DUPLICATE_CALCUL,
  API_CONNECT_CONTACT,
  API_GET_FDES_METADATA,
  API_POST_FILE,
  API_GET_EDEC_CONFIGURATOR,
  API_POST_EDEC_CONFIGURATOR,
  API_PATCH_EDEC_CONFIGURATOR,
  API_DELETE_EDEC_CONFIGURATOR,
  API_POST_EDEC_INTEGRATION_PROCESS
} from "./common/constants/api";
import { CURRENT_DOMAIN } from "./utils/http";
import { convertSortToAPIParams } from "./common/constants/sort";

export function postAuthenticate(data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_LOGIN, opts).then(defaultResultHandler);
}

export function getUserByAccessToken(token) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_CONNECT_ME, opts).then(defaultResultHandler);
}

export function getConfiguratorsCall(token) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_CONFIGURATORS, opts).then(defaultResultHandler);
}

export function getFamilyProductsCall(token) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_FAMILY_PRODUCTS, opts).then(defaultResultHandler);
}

export function getAggregatedFamilyProductsCall(
  token,
  userId,
  localConfiguratorId
) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("configurator", localConfiguratorId);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(
    API_GET_AGGREGATED_FAMILY_PRODUCTS(userId, localConfiguratorId),
    opts
  ).then(defaultResultHandler);
}

export function getGroupsCall(token, localConfiguratorId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_GROUPS(localConfiguratorId), opts).then(
    defaultResultHandler
  );
}

export function getGroupsByUserIdCall(token, userId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_GROUPS_BY_USERID(userId), opts).then(
    defaultResultHandler
  );
}

export function getUsersByConfiguratorIdCall(token, configuratorId, value) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(
    API_SEARCH_USERS_BY_CONFIGURATOR_ID(configuratorId, value),
    opts
  ).then(defaultResultHandler);
}

export function getFdesParametersCall(token, fdesId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_FDES(fdesId), opts).then(defaultResultHandler);
}

export function getFdesMetaDataCall(token, fdesId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_FDES_METADATA(fdesId), opts).then(defaultResultHandler);
}

export function getReferencesCall(token, standard) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_REFERENCE(standard), opts).then(defaultResultHandler);
}

export function postFdesResultCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_PARAMS, opts).then(defaultResultHandler);
}

export function postFdesUploadFileCall(token, data) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: data,
  };
  return fetch(API_POST_FILE, opts).then(defaultResultHandler);
}

//@todo to verify
export function postFdesGoogleResultCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_GOOGLE_POST_PARAMS, opts).then(defaultResultHandler);
}

export function saveFdesResultCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_RESULT, opts).then(defaultResultHandler);
}

export function updateCalculCall(token, data, calculId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_PATCH_RESULT(calculId), opts).then(defaultResultHandler);
}

export function generateReportCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_GENERATE_REPORT, opts).then(defaultResultHandler);
}
export function getFile(filepath) {
  const headers = new Headers();
  headers.append("Content-Type", "text/plain");
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(filepath, opts).then(defaultResultHandler);
}

export function getFilteredConfiguratorsCall(token) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };

  return fetch(API_GET_LOCAL_CONFIGURATORS(CURRENT_DOMAIN), opts).then(
    defaultResultHandler
  );
}

export function createFamilyProductCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_FAMILY_PRODUCT, opts).then(defaultResultHandler);
}

export function createConfiguratorCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_CONFIGURATORS, opts).then(defaultResultHandler);
}

export function createUserCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_USERS, opts).then(defaultResultHandler);
}

export function createFdesCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_FDES, opts).then(defaultResultHandler);
}

export function updateFdesCall(token, data, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_PATCH_FDES(id), opts).then(defaultResultHandler);
}

export function updateUserCall(token, data, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_PATCH_USERS(id), opts).then(defaultResultHandler);
}

export function updateConfiguratorCall(token, data, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_PATCH_CONFIGURATORS(id), opts).then(defaultResultHandler);
}

export function updateFamilyProductCall(token, data, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_PATCH_FAMILY_PRODUCTS(id), opts).then(defaultResultHandler);
}

export function postGroupCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_GROUP, opts).then(defaultResultHandler);
}

export function updateGroupCall(token, data, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_PATCH_GROUP(id), opts).then(defaultResultHandler);
}

export function deleteGroupCall(token, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "DELETE",
    headers: headers,
  };
  return fetch(API_DELETE_GROUP(id), opts).then(defaultResultHandler);
}

export function deleteConfiguratorCall(token, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "DELETE",
    headers: headers,
  };
  return fetch(API_DELETE_CONFIGURATORS(id), opts).then(defaultResultHandler);
}

export function deleteUserCall(token, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "DELETE",
    headers: headers,
  };
  return fetch(API_DELETE_USERS(id), opts).then(defaultResultHandler);
}

export function recoverPasswordCall(data, localConfiguratorId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("configurator", localConfiguratorId);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_RECOVER_PASSWORD, opts).then(defaultResultHandler);
}

export function registerCall(data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_REGISTER, opts).then(defaultResultHandler);
}

export function sendVerificationCall(data, localConfiguratorId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("configurator", localConfiguratorId);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_VERIFICATION, opts).then(defaultResultHandler);
}

export function deleteFamilyProductCall(token, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "DELETE",
    headers: headers,
  };
  return fetch(API_DELETE_FAMILY_PRODUCT(id), opts).then(defaultResultHandler);
}

export function getUsersCall(token) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_USERS, opts).then(defaultResultHandler);
}

export function getModelsForGroupCall(token, groupId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_MODELS_FOR_GROUP(groupId), opts).then(
    defaultResultHandler
  );
}

export function getModelsCall(token, familyProductId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_MODELS(familyProductId), opts).then(
    defaultResultHandler
  );
}

export function getUserCalculsCall(
  token,
  userId,
  sort,
  page,
  limit,
  localConfiguratorId
) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  const params = convertSortToAPIParams(sort, page, limit);

  return fetch(API_GET_CALCULS(userId, localConfiguratorId, params), opts).then(
    defaultResultHandler
  );
}

export function getCalculCall(token, calculId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };

  return fetch(API_GET_CALCUL(calculId), opts).then(defaultResultHandler);
}

export function upgradeCalculCalls(token, calculId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
  };

  return fetch(API_UPGRADE_CALCUL(calculId), opts).then(defaultResultHandler);
}

export function deleteCalculCalls(token, calculId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "DELETE",
    headers: headers,
  };

  return fetch(API_DELETE_CALCUL(calculId), opts).then(defaultResultHandler);
}

export function duplicateCalculCalls(token, calculId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
  };

  return fetch(API_DUPLICATE_CALCUL(calculId), opts).then(defaultResultHandler);
}

export function uploadFile(token, data) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: data,
  };
  return fetch(API_FILE_UPLOAD, opts).then(defaultResultHandler);
}
export function sendFileToUploader(token, data) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: data,
  };
  return fetch(API_FILE_UPLOADER, opts).then(defaultResultHandler);
}
export function sendAssetToUploader(token, data) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: data,
  };
  return fetch(API_ASSET_UPLOADER, opts).then(defaultResultHandler);
}
export function sendCsvToUploader(token, data) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: data,
  };
  return fetch(API_CSV_UPLOADER, opts).then(defaultResultHandler);
}

function defaultResultHandler(response) {
  const contentType = response.headers.get("content-type");

  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json();
  }

  return response.text();
}

export function sendEmailCall(token, localConfiguratorId, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("configurator", localConfiguratorId);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_CONNECT_CONTACT, opts).then(defaultResultHandler);
}

export function getEDECConfiguratorCall(token, configuratorId) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "GET",
    headers: headers,
  };
  return fetch(API_GET_EDEC_CONFIGURATOR(configuratorId), opts).then(
    defaultResultHandler
  );
}

export function createEDECConfiguratorCall(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_EDEC_CONFIGURATOR, opts).then(defaultResultHandler);
}

export function updateEDECConfiguratorCall(token, data, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_PATCH_EDEC_CONFIGURATOR(id), opts).then(
    defaultResultHandler
  );
}

export function deleteEDECConfiguratorCall(token, id) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "DELETE",
    headers: headers,
  };
  return fetch(API_DELETE_EDEC_CONFIGURATOR(id), opts).then(
    defaultResultHandler
  );
}

export function postEDECIntegrationProcess(token, data) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  const opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  return fetch(API_POST_EDEC_INTEGRATION_PROCESS, opts).then(defaultResultHandler);
}
