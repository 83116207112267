import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";
import ModelDetail from "../components/ModelDetail";

const ModelView = ({match, models}) => {
    const [model, setModel] = useState('');
    const [familyProductId, setFamilyProductId] = useState('');

    const params = useParams()

    useEffect(() => {
        if (params && params.modelId) {
            setModel(models.find(x => x._id === params.modelId))
        }
        setFamilyProductId(params.familyProductId);
    }, [params, models]);
    return (
        <>
            <Breadcrumb title="Voir le modèle">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/family-product">Famille de produit</Link></li>
                    <li className="breadcrumb-item"><Link to={`/family-product/edit/${familyProductId}`}>Modifier la famille de produit</Link></li>
                    <li className="breadcrumb-item active">Voir le modèle</li>
                </ol>
            </Breadcrumb>
            <ModelDetail model={model} familyProductId={familyProductId} />
        </>
    )

};
export default withPermissions(ModelView, PERMISSION_SUPER_ADMIN);

