import React, {useEffect, useState} from 'react';
import './SyntheticResult.scss';
import {checkNumberFormat, formatToExponentielNumber} from "../../../utils/transformer";

const ExposantValue = ({value, forceLiteral, unit}) => {

    const [currentExposant, setExposant] = useState('');
    const [currentNumber, setNumber] = useState('');

    useEffect(() => {
        if (forceLiteral && forceLiteral === true) {
            const {number, exposant} = checkNumberFormat(value);
            setExposant(exposant);
            setNumber(number);
        } else {
            const {number, exposant} = formatToExponentielNumber(value);
            setExposant(exposant);
            setNumber(number);
        }
    }, [value, forceLiteral]);

    return (
        <>
            {currentNumber}{currentExposant && currentExposant !== '' ? <sup>e{currentExposant}</sup> : ""} {unit}
        </>)
};

export default ExposantValue;