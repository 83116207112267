import {connect} from 'react-redux';
import AccountSettingForm from '../components/AccountSettingForm';
import {updateAccountSettingForm} from "../actions";

export default connect(
    (state) => ({
        user: state.user.informations,
    }),
    (dispatch) => ({
        updateUserForm: (values) => {
            dispatch(updateAccountSettingForm(values))
        }
    })

)(AccountSettingForm);