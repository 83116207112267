import produce from "immer";
import {
    DISABLE_CALCUL_LOADER, DISABLE_PDF_REPORT_LOADER,
    DISABLE_REPORTS_LOADER, DISABLE_XML_REPORT_LOADER, ENABLE_CALCUL_LOADER, ENABLE_PDF_REPORT_LOADER,
    ENABLE_REPORTS_LOADER, ENABLE_XML_REPORT_LOADER,
    SAVE_FAMILY_PRODUCT_FDES,
    SAVE_FAMILY_PRODUCT_FDES_FAILED,
    SAVE_FAMILY_PRODUCT_FDES_SUCCESS,
    SAVE_FAMILY_PRODUCT_FORM,
    UPDATE_FAMILY_PRODUCT_FDES,
    UPDATE_FAMILY_PRODUCT_FDES_FAILED,
    UPDATE_FAMILY_PRODUCT_FDES_SUCCESS, UPDATE_FAMILY_PRODUCT_FORM,
} from "../../modules/family-product/constants";
import {
    SAVE_GROUP_FORM, UPDATE_GROUP_FORM,
} from "../../modules/manage-group/constants"
import {DISABLE_FAMILY_PRODUCT_FORM_LOADER, DISABLE_GROUP_FORM_LOADER, SEND_RECOVER_PASSWORD_FORM} from "../constants/action-types";

const initialState = {
    manageGroupFormIsLoading: false,
    familyProductFormIsLoading: false,
    modelFormIsLoading: false,
    xmlReportIsLoading: false,
    pdfReportIsLoading: false,
    calculIsLoading: false,
    calculIdXmlReport: '',
    calculIdPdfReport: '',
    sendRecoverPasswordFormIsLoading: false
};


const loaderReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case ENABLE_PDF_REPORT_LOADER:
            draft.calculIdPdfReport = action.calculId;
            draft.pdfReportIsLoading = true;
            break;
        case DISABLE_PDF_REPORT_LOADER:
            draft.pdfReportIsLoading = false;
            break;
        case ENABLE_XML_REPORT_LOADER:
            draft.calculIdXmlReport = action.calculId;
            draft.xmlReportIsLoading = true;
            break;
        case DISABLE_XML_REPORT_LOADER:
            draft.xmlReportIsLoading = false;
            break;
        case ENABLE_CALCUL_LOADER:
            draft.calculIsLoading = true;
            break;
        case DISABLE_CALCUL_LOADER:
            draft.calculIsLoading = false;
            break;
        case ENABLE_REPORTS_LOADER:
            draft.xmlReportIsLoading = true;
            draft.pdfReportIsLoading = true;
            break;
        case DISABLE_REPORTS_LOADER:
            draft.xmlReportIsLoading = false;
            draft.pdfReportIsLoading = false;
            draft.calculIdXmlReport = '';
            draft.calculIdPdfReport = '';
            break;
        case UPDATE_FAMILY_PRODUCT_FORM:
        case SAVE_FAMILY_PRODUCT_FORM:
            draft.familyProductFormIsLoading = true;
            break;
        case DISABLE_FAMILY_PRODUCT_FORM_LOADER:
            draft.familyProductFormIsLoading = false;
            break;
        case SAVE_GROUP_FORM:
            draft.manageGroupFormIsLoading = true;
            break;
        case UPDATE_GROUP_FORM:
        case DISABLE_GROUP_FORM_LOADER:
            draft.manageGroupFormIsLoading = false;
            break;
        case UPDATE_FAMILY_PRODUCT_FDES:
        case SAVE_FAMILY_PRODUCT_FDES:
            draft.modelFormIsLoading = true;
            break;
        case SAVE_FAMILY_PRODUCT_FDES_FAILED:
        case SAVE_FAMILY_PRODUCT_FDES_SUCCESS:
        case UPDATE_FAMILY_PRODUCT_FDES_SUCCESS:
        case UPDATE_FAMILY_PRODUCT_FDES_FAILED:
            draft.modelFormIsLoading = false;
            break;
        case SEND_RECOVER_PASSWORD_FORM:
            draft.sendRecoverPasswordFormIsLoading = true;
            break;
        case "SEND_RECOVER_PASSWORD_SUCCESS":
        case "SEND_RECOVER_PASSWORD_FAILED":
            draft.sendRecoverPasswordFormIsLoading = false;
            break;
        default:
            return draft;
    }
});

export default loaderReducer;