import {createSelector} from 'reselect';
import {groupBy} from "../../../utils/transformer";

const getFormIsLoading = (state) => state.fdes.form.isLoading;
const getFormIsSaved = (state) => state.fdes.form.isSaved;
const getSteps = (state) => state.fdes.references.steps;
const getIndicators = (state) => state.fdes.references.indicators;
const getIndicatorSelected = (state) => state.fdes.synthetic.fields.indicator;
const getStepSelected = (state) => state.fdes.synthetic.fields.step;
const getResults = (state) => state.fdes.results;
const getGraphPreference = (state) => state.fdes.preferences.graph;
const getTablePreference = (state) => state.fdes.preferences.table;
const getModuleFormIndicatorSelected = (state) => state.fdes.module.fields.indicator;


export const getFdesFormIsEdited = createSelector(getFormIsSaved, getFormIsLoading, (formIsSaved, formIsLoading) =>
    formIsSaved || formIsLoading
);

export const getResultForIndicatorStep = createSelector(getIndicatorSelected, getStepSelected, getResults, (indicator, step, results) => {
    return results && (results.find(result => result.indicator === indicator.value && result.step === step.value))
}
);

export const getMeasureForIndicatorSelected = createSelector(getIndicatorSelected, getIndicators, (indicatorSelected, indicators) => {
    const indicator = indicators.find(indicator => indicatorSelected.value === indicator.id);
    return indicator ? indicator.unity : '';
}
);

export const getStepsTableForPreferenceStep = createSelector(getTablePreference, getSteps, (tablePreference, steps) => {
    const stepFiltered = [];
    if(tablePreference) {
        tablePreference.map(stepId =>
            stepFiltered.push(steps.find(x => x.id === stepId))
        );
    }
    return stepFiltered;
});

export const getIndicatorsTableForPreferenceStep = createSelector(getTablePreference, getIndicators, getResults, (tablePreference, indicators, results) => {

    const tableResult = [];
    if (!tablePreference) {
        return tableResult;
    }
    const filteredResults = results.filter(x => tablePreference.includes(x.step));
    const groupedIndicator = groupBy(indicators, 'group');

    Object.keys(groupedIndicator).map(groupName => {
        let indicatorsValues = [];

        groupedIndicator[groupName].map(indicator => {
            let currentIndicator = {indicator: indicator, values: []};

            tablePreference.map(stepId =>
                currentIndicator.values.push(filteredResults.find(result => result.indicator === indicator.id && result.step === stepId))
            );
            indicatorsValues.push(currentIndicator);
            return undefined;
        });

        tableResult.push({
            name: groupName,
            indicators: indicatorsValues
        })
        return undefined;
    }
    )

    return tableResult;

});


export const getHistogramSteps = createSelector(getGraphPreference, getModuleFormIndicatorSelected, getSteps, getResults, (graphPreference, indicator, steps, results) => {

    let labels = [];
    let datas = [];
    if (graphPreference) {
        graphPreference.map(stepId => {
            const stepFound = steps.find(step => step.id === stepId);
            const resultFound = results.find(result => result.indicator === indicator.value && result.step === stepId);
            if (stepFound) {
                labels.push(stepFound.shortTitle);
                datas.push(resultFound ? parseFloat(resultFound.value.replace(',', '.')) : 0);
            }
            return undefined;
        });
    }

    labels = labels.map(label => {
        if (label.length > 15) {
            return label.split(' ').reduce((all, one, i) => {
                const ch = Math.floor(i / 3);
                all[ch] = [].concat((all[ch] || []), one);
                return all
            }, []).map(chunk => chunk.join(' '));
        }
        return label;
    });

    return {
        labels: labels,
        datasets: [{
            data: datas,
            backgroundColor: [
                '#018d9a',
                '#ed7d31',
                '#a5a5a5',
                '#ffc000',
                '#5b9bd5',
                '#70ad47',
            ],
            borderWidth: 0
        }]
    }
});