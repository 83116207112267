import {SAVE_REGISTER_FORM, SAVE_REGISTER_FORM_SUCCESS,SEND_VERIFICATION, SAVE_VERIFICATION_SUCCESS} from "../constants/action-types";

export function saveRegisterForm(values) {
    return {
        type: SAVE_REGISTER_FORM, values
    };
}

export function saveRegisterFormSuccess(values) {
    return {
        type: SAVE_REGISTER_FORM_SUCCESS, values
    };
}
export function sendVerification(token) {
    return {
        type: SEND_VERIFICATION, token
    };
}

export function sendVerificationSuccess() {
    return {
        type: SAVE_VERIFICATION_SUCCESS
    };
}
