import React from 'react';
import {Formik} from "formik";
import * as Yup from "yup";
import {userTypeKeyToLabel} from "../../utils/transformer";

const RegisterForm = ({saveRegisterForm}) => {

    return (
                    <Formik
                        enableReinitialize
                        initialValues={{
                            lastName:  '',
                            firstName:  '',
                            type:  '',
                            email: '',
                            password: '',
                            passwordConfirmation: '',
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            setTimeout(() => {
                                setSubmitting(false);
                                saveRegisterForm(values);
                            }, 500);
                        }}
                        validationSchema={Yup.object().shape({
                            lastName: Yup.string().required("Champ Requis"),
                            firstName: Yup.string().required("Champ Requis"),
                            email: Yup.string().required("Champ Requis"),
                            type: Yup.string().required("Champ Requis"),
                            password:  Yup.string().required("Champ Requis") ,
                            passwordConfirmation: Yup.string().required("Champ Requis")
                                    .oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques')
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;

                            return (
                                <form className="form-horizontal form-simple" onSubmit={handleSubmit} >
                                    <fieldset className="form-group position-relative has-icon-left mb-1">
                                        <input type="text"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.lastName}
                                               className="form-control form-control-lg"
                                               id="lastName"
                                               placeholder="Saisissez votre nom" />
                                        <div className="form-control-position">
                                            <i className="feather icon-user"/>
                                        </div>
                                        {errors.lastName && touched.lastName &&
                                        <div className="mt-1 danger">{errors.lastName}</div>}
                                    </fieldset>
                                    <fieldset className="form-group position-relative has-icon-left mb-1">
                                        <input type="text"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.firstName}
                                               className="form-control form-control-lg"
                                               id="firstName"
                                               placeholder="Saisissez votre prénom" />
                                        <div className="form-control-position">
                                            <i className="feather icon-user"/>
                                        </div>
                                        {errors.firstName && touched.firstName &&
                                        <div className="mt-1 danger">{errors.firstName}</div>}
                                    </fieldset>
                                    <fieldset className="form-group position-relative has-icon-left mb-1">
                                        <input type="email"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.email}
                                               className="form-control form-control-lg" id="email"
                                               placeholder="Saisissez votre adresse e-mail."  />
                                        <div className="form-control-position">
                                            <i className="feather icon-mail"/>
                                        </div>
                                        {errors.email && touched.email &&
                                        <div className="mt-1 danger">{errors.email}</div>}
                                    </fieldset>
                                    <fieldset className="form-group position-relative has-icon-left mb-1">
                                        <select className="form-control form-control-lg " name="type" id="type" value={values.type} onBlur={handleBlur} onChange={handleChange}>
                                            <option value="">Choisissez votre métier</option>
                                            {Object.entries(userTypeKeyToLabel).map(value=>
                                                <option key={value[0]} value={value[0]}>{value[1]}</option>
                                            ) }
                                        </select>
                                        <div className="form-control-position">
                                            <i className="feather icon-tag"/>
                                        </div>
                                        {errors.type && touched.type &&
                                        <div className="mt-1 danger">{errors.type}</div>}
                                    </fieldset>
                                    <fieldset className="form-group position-relative has-icon-left">
                                        <input type="password"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.password}
                                               className="form-control form-control-lg"
                                               id="password" placeholder="Créez un mot de passe."  />
                                        <div className="form-control-position">
                                            <i className="fa fa-key"/>
                                        </div>
                                        {errors.password && touched.password &&
                                        <div className="mt-1 danger">{errors.password}</div>}
                                    </fieldset>
                                    <fieldset className="form-group position-relative has-icon-left">
                                        <input type="password"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.passwordConfirmation}
                                               className="form-control form-control-lg"
                                               id="passwordConfirmation" placeholder="Confirmez votre mot de passe."  />
                                        <div className="form-control-position">
                                            <i className="fa fa-key"/>
                                        </div>
                                        {errors.passwordConfirmation && touched.passwordConfirmation &&
                                        <div className="mt-1 danger">{errors.passwordConfirmation}</div>}
                                    </fieldset>
                                    <button type="submit" className="btn btn-primary btn-lg btn-block"><i
                                        className="feather icon-unlock"/> S'inscrire
                                    </button>
                                </form>)
                        }}
                    </Formik>
    );
};

export default RegisterForm;