import ManageGroupPage from "../pages/ManageGroup";
import ManageGroupCreatePage from "../pages/ManageGroupCreate";
import ManageGroupEdit from "../containers/ManageGroupEdit";

const routes = [
    {
        path: '/manage-group',
        exact: true,
        element: ManageGroupPage
    },
    {
        path: '/manage-group/create',
        exact: true,
        element: ManageGroupCreatePage
    },
    {
        path: '/manage-group/edit/:groupId',
        exact: true,
        element: ManageGroupEdit
    },

];

export default routes;
