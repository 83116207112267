import React, {useState} from 'react';
import './SyntheticResult.scss';
import IndicatorTableResult from "./IndicatorTableResult";

const IndicatorGroupedTableResult = ({groupedIndicator, defautOpen}) => {
    const [isOpen, setIsOpen] = useState(defautOpen);

    return (
        <>
            <tr key={groupedIndicator.name} onClick={() => setIsOpen(!isOpen)}>
                <td className="group" colSpan={12}>{isOpen ? <i className="feather  icon-chevron-up mr-1" /> :
                    <i className="feather  icon-chevron-down mr-1" />}{groupedIndicator.name}</td>
            </tr>
            <IndicatorTableResult groupedIndicator={groupedIndicator} isOpen={isOpen} />
        </>)
};

export default IndicatorGroupedTableResult;