import {connect} from 'react-redux';
import UserEdit from "../pages/UserEdit";

export default connect(
    (state) => ({
        users: state.user.users
    }),
    (dispatch) => ({

    })

)(UserEdit);