import produce from "immer";
import {
    CHANGE_FDES_PARAMETER_FORM,
    GET_FDES_PARAMETERS_SUCCESS,
    SAVE_FDES_FORM_FAILED,
    SAVE_FDES_FORM_SUCCESS,
    LOAD_FDES_PARAMETERS,
    SAVE_CURRENT_FDES_ID,
    SAVE_FDES_FORM,
    LOAD_REFERENCE_SUCCESS,
    CHANGE_SYNTHETIC_RESULT_FORM,
    CHANGE_MODULE_RESULT_FORM,
    CHANGE_CALCUL_NAME_FORM,
    SAVE_FDES_RESULT,
    SAVE_FDES_RESULT_SUCCESS,
    SAVE_FDES_RESULT_FAILED,
    CHANGE_PROJECT_NAME_FORM,
    LOAD_CALCUL_DATA,
    SET_LAST_SAVED_USER_FDES_ID,
    CLEAN_NAME_FORM,
    UPDATE_CALCUL_SUCCESS,
    UPDATE_CALCUL, UPDATE_CALCUL_FAILED, SAVE_FDES_FORM_GOOGLE, GET_FDES_METADATA_SUCCESS, POST_FDES_FILE_SUCCESS,
    CLEAR_CALCUL_INFORMATIONS
} from "../constants";
import {formatToCalculatedNumber, groupBy} from "../../../utils/transformer";
import {DEFAULT_INDICATOR_ID, DEFAULT_STEP_ID} from "../constants/indicators";

export const initialState = {
    calculName: '',
    projectName: '',
    resultForm: {
        isLoading: false,
        isSaved: false,
        lastSavedUserFdesId: ''
    },
    fdesParameterGroups: [],
    fdesParameters: [],
    form: {
        fields: [],
        currentFdesId: '',
        isLoading: false,
        isSaved: false
    },
    calculateId: '',
    results: [],
    references: {
        indicators: [],
        steps: []
    },
    preferences: {
        modules: [],
        graph: [],
        table: [],
        defaults: []
    },
    synthetic: {
        fields: {
            indicator: {},
            step: {}
        },
    },
    module: {
        fields: {
            indicator: {}
        }
    },
    metadata: []
};

const fdesReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case LOAD_FDES_PARAMETERS:
            draft.fdesParameterGroups = [];
            draft.form = {
                fields: [],
                isLoading: false,
                currentFdesId: '',
                isSaved: false
            };
            break;
        case GET_FDES_PARAMETERS_SUCCESS:
            formatFdesParametersResponse(draft, action.payload);
            break;
        case CHANGE_FDES_PARAMETER_FORM:
            changeFdesParametersForm(draft, action);
            break;
        case SAVE_FDES_FORM:
            draft.lastFormSave = draft.form.fields;
            draft.form.isLoading = true;
            draft.form.isSaved = true;
            break;
        case SAVE_FDES_FORM_GOOGLE:
            draft.lastFormSave = draft.form.fields;
            draft.form.isLoading = true;
            draft.form.isSaved = true;
            break;
        case SAVE_CURRENT_FDES_ID:
            draft.form.currentFdesId = action.fdesId;
            break;
        case POST_FDES_FILE_SUCCESS:
            break;
        case SAVE_FDES_FORM_SUCCESS:
            draft.form.isLoading = false;
            if (action.payload && action.payload.results && action.payload.results.length > 0) {
                draft.results = action.payload.results;
                draft.calculateId = action.payload._id;
            }
            if (action.payload && action.payload.additionalResults && action.payload.additionalResults.length > 0) {
                draft.additionalResults = action.payload.additionalResults;
            }
            if (action.payload && action.payload.settings) {
                draft.settings = action.payload.settings;
            }
            break;
        case SAVE_FDES_FORM_FAILED:
            draft.form.isLoading = false;
            draft.form.isSaved = false;
            break;
        case LOAD_REFERENCE_SUCCESS:
            formatReferenceResponse(draft, action.payload);
            break;
        case CHANGE_SYNTHETIC_RESULT_FORM:
            draft.synthetic.fields[action.field] = action.value;
            break;
        case CHANGE_MODULE_RESULT_FORM:
            draft.module.fields.indicator = action.value;
            break;
        case CHANGE_CALCUL_NAME_FORM:
            draft.resultForm.isSaved = false;
            draft.calculName = action.value;
            break;
        case CHANGE_PROJECT_NAME_FORM:
            draft.resultForm.isSaved = false;
            draft.projectName = action.value;
            break;
        case SAVE_FDES_RESULT:
            draft.resultForm.isLoading = true;
            break;
        case GET_FDES_METADATA_SUCCESS:
            draft.metadata = action.payload.data;
            break;
        case SAVE_FDES_RESULT_SUCCESS:
            draft.resultForm.isLoading = false;
            draft.resultForm.isSaved = true;
            draft.resultForm.lastSavedUserFdesId = action && action.payload ? action.payload._id : '';
            break;
        case SAVE_FDES_RESULT_FAILED:
            draft.resultForm.isLoading = false;
            break;
        case UPDATE_CALCUL:
            draft.resultForm.isLoading = true;
            break;
        case UPDATE_CALCUL_SUCCESS:
            draft.resultForm.isLoading = false;
            draft.resultForm.isSaved = true;
            break;
        case UPDATE_CALCUL_FAILED:
            draft.resultForm.isLoading = false;
            break;
        case LOAD_CALCUL_DATA:
            draft.additionalResults = [];
            draft.results = [];
            draft.settings = {};
            draft.projectName = "";
            draft.calculName = "";
            
            if (action.calcul) {
                if (action.calcul._id) {
                    draft.resultForm.lastSavedUserFdesId = action.calcul._id;
                    draft.resultForm.isSaved = true;
                }

                if (action.calcul.results) {
                    draft.results = action.calcul.results;
                }
                if (action.calcul.additionalResults) {
                    draft.additionalResults = action.calcul.additionalResults;
                }
                if (action.calcul.settings) {
                    draft.settings = action.calcul.settings;
                }

                if (action.calcul.parameters) {
                    action.calcul.parameters.forEach(parameter => {
                        let index = draft.form.fields.findIndex(x => x.paramId === parameter.paramId);
                        let type = draft.fdesParameters.find(x => x.paramId === parameter.paramId) ? draft.fdesParameters.find(x => x.paramId === parameter.paramId).type : "";
                        let values = draft.fdesParameters.find(x => x.paramId === parameter.paramId) ? draft.fdesParameters.find(x => x.paramId === parameter.paramId).values : [];
                        let valueIndex = values.findIndex(x => x === parameter.paramValue);
                        // draft.form.fields[index].value = { label: parameter.paramValue, value: type === "numeric" ? formatToCalculatedNumber(parameter.paramValue) : valueIndex || 0 };
                        draft.form.fields[index].value = {value: getFormFieldValue(type, parameter, valueIndex), label: parameter.paramValue};
                    })
                }

                if (action.calcul.title) {
                    draft.calculName = action.calcul.title;
                }
                if (action.calcul.project) {
                    draft.projectName = action.calcul.project;
                }

            }

            break;
        case SET_LAST_SAVED_USER_FDES_ID:
            draft.resultForm.isSaved = true;
            draft.resultForm.lastSavedUserFdesId = action && action.calculId ? action.calculId : '';
            break;
        case CLEAN_NAME_FORM:
            draft.calculateId = '';
            draft.resultForm.isSaved = false;
            draft.resultForm.lastSavedUserFdesId = '';
            break;
        case CLEAR_CALCUL_INFORMATIONS:
            draft.calculName = "";
            draft.projectName = "";
            draft.results = [];
            draft.settings = {};
            draft.additionalResults = [];
            break;
        default:
            return draft;
    }
});
/**
 * get the form field value when loaded.
 * 
 * @param {*} type 
 * @param {*} parameter 
 * @param {*} valueIndex 
 * @returns 
 */
function getFormFieldValue (type, parameter, valueIndex) {
    if (type === "select") {
        return valueIndex || 0;
    }
    if (type === "numeric" || type === "numericslider") {
        return formatToCalculatedNumber(parameter.paramValue)
    }
    if (type === "percent" || type === "percentslider") {
        let value = parameter.paramValue;
        if (parameter.paramValue.toString().includes('%')) {
            value = parameter.paramValue.toString().slice(0, parameter.paramValue.toString().length - 1)
        }
        return formatToCalculatedNumber(value)
    }
    return parameter.paramValue;
}
function changeFdesParametersForm (draft, action) {
    if (action && action.paramId && action.value !== undefined) {
        draft.form.isSaved = false;
        draft.resultForm.isSaved = false;
        const editIndex = draft.form.fields.findIndex(x => x.paramId === action.paramId);
        draft.form.fields[editIndex] = {...draft.form.fields[editIndex], paramId: action.paramId, value: action.value};
    }
}

function formatFdesParametersResponse (draft, payload) {
    if (payload && payload.length > 0) {
        draft.fdesParameters = payload;
        const groupedParameters = groupBy(payload, 'group');
        draft.fdesParameterGroups = Object.keys(groupedParameters).map(groupName => ({
            name: groupName,
            parameters: groupedParameters[groupName] ? groupedParameters[groupName].sort((a, b) => a.order < b.order ? -1 : 1) : [],
            isGeneralParameter: groupedParameters[groupName] && groupedParameters[groupName][0] && groupedParameters[groupName][0].isGeneralParameter,
        })
        );
        draft.fdesParameterGroups = draft.fdesParameterGroups.sort((a) => a.isGeneralParameter ? -1 : 1);
        const sUsrArg = navigator.userAgent
        if (sUsrArg.indexOf("Firefox") > -1)
            draft.fdesParameterGroups = draft.fdesParameterGroups.reverse();

        setDefaultFormValue(draft, groupedParameters);
    }
}

function formatReferenceResponse (draft, payload) {
    if (payload && payload.indicators) {
        const indicators = Object.keys(payload.indicators).map(indicatorId => payload.indicators[indicatorId]);
        const defaultIndicator = indicators.find(x => x.id === (payload.preferences.resultPage.defaults.indicator ? payload.preferences.resultPage.defaults.indicator : DEFAULT_INDICATOR_ID));

        draft.references.indicators = indicators;
        draft.synthetic.fields.indicator = {value: defaultIndicator && (defaultIndicator.id), label: defaultIndicator && (defaultIndicator.title)}
        draft.module.fields.indicator = {value: defaultIndicator && (defaultIndicator.id), label: defaultIndicator && (defaultIndicator.title)}
    }
    if (payload && payload.steps) {
        const steps = Object.keys(payload.steps).map(indicatorId => payload.steps[indicatorId]);
        const defaultStep = steps.find(x => x.id === (payload.preferences.resultPage.defaults.step ? payload.preferences.resultPage.defaults.step : DEFAULT_STEP_ID));

        draft.references.steps = steps;
        draft.synthetic.fields.step = {value: defaultStep && (defaultStep.id), label: defaultStep && (`${defaultStep.acronym} - ${defaultStep.title}`)}
    }

    if (payload && payload.preferences) {
        if (payload.preferences && payload.preferences.resultPage && payload.preferences.resultPage.modules && payload.preferences.resultPage.modules.length > 0) {
            draft.preferences.modules = payload.preferences.resultPage.modules;
        }
        if (payload.preferences && payload.preferences.resultPage && payload.preferences.resultPage.graph && payload.preferences.resultPage.graph.length > 0) {
            draft.preferences.graph = payload.preferences.resultPage.graph;
        }
        if (payload.preferences && payload.preferences.resultPage && payload.preferences.resultPage.table && payload.preferences.resultPage.table.length > 0) {
            draft.preferences.table = payload.preferences.resultPage.table;
        }
        if (payload.preferences && payload.preferences.resultPage && payload.preferences.resultPage.defaults && payload.preferences.resultPage.defaults.length > 0) {
            draft.preferences.defaults = payload.preferences.resultPage.defaults;
        }
    }
}

function setDefaultFormValue (draft, groupedParameters) {
    if (groupedParameters) {
        let grouppedFields = [];
        Object.keys(groupedParameters).map(groupName =>
            grouppedFields = [...grouppedFields, ...groupedParameters[groupName].map(parameter => parameter.paramId && (parameter.defaultValue || parameter.type === 'text' || parameter.type === 'image') &&
                ({paramId: parameter.paramId, externalId: parameter.externalId, value: {value: parameter.defaultValue ? parameter.defaultValue : '', label: parameter.defaultValue ? parameter.defaultValue : ''}, type: parameter.type, isGeneralParameter: parameter.isGeneralParameter})
            )]
        )
        draft.form.fields = grouppedFields;
    }
}

export default fdesReducer;
