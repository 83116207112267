import React, {useEffect, useState} from 'react';
import UserForm from "../containers/UserForm";
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_ORG_ADMIN} from "../constants/permissions";

const UserEdit = ({match, users}) => {
    const [user, setUser] = useState({});

    const params = useParams()

    useEffect(() => {
        if (params && params.userId) {
            setUser(users.find(x => x.id === params.userId))
        }
    }, [users, params]);


    return (<>
        <Breadcrumb title="Modifier un utilisateur">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                <li className="breadcrumb-item"><Link to="/users">Utilisateurs</Link></li>
                <li className="breadcrumb-item active">Modifier un utilisateur</li>
            </ol>
        </Breadcrumb>
        <UserForm action="UPDATE" user={user} />
    </>
    )

};
export default withPermissions(UserEdit, PERMISSION_ORG_ADMIN);
