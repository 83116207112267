import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_SUPER_ADMIN} from "../../user/constants/permissions";
import ModelForm from "../containers/ModelForm";

const ModelCreate = ({match}) => {
    const [familyProductId, setFamilyProductId] = useState('');

    const params = useParams()

    useEffect(() => {
        if (params && params.familyProductId) {
            setFamilyProductId(params.familyProductId)
        }
    }, [params]);
    return (
        <>
            <Breadcrumb title="Créer un nouveau modèle">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
                    <li className="breadcrumb-item"><Link to="/family-product">Famille de produit</Link></li>
                    <li className="breadcrumb-item"><Link to={`/family-product/edit/${familyProductId}`}>Modifier la famille de produit</Link></li>
                    <li className="breadcrumb-item active">Créer un nouveau modèle</li>
                </ol>
            </Breadcrumb>
            <ModelForm familyProductId={familyProductId} action="CREATE" />
        </>
    )

};
export default withPermissions(ModelCreate, PERMISSION_SUPER_ADMIN);

