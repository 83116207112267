import {connect} from 'react-redux';
import Models from "../components/Models";

export default connect(
    (state) => ({
        models: state.familyProduct.models
    }),
    (dispatch) => ({
    })

)(Models);