import React from 'react';
import './Register.scss';
import RegisterForm from "../containers/RegisterForm";
import {Link} from "react-router-dom";
import {history} from './../../store/index';

const redirect = () => {
    history.push('/')
}

const Register = ({step, localConfigurator, message}) => {
    return (
        localConfigurator?.security?.allowRegister === true ? <div className="content-body">
            <section className="row flexbox-container">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                        <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                            <div className="card-header border-0">
                                <div className="card-title text-center">
                                    <Link to="/"><img src={localConfigurator.logo}
                                        className="branding logo" alt="Esteana" /></Link>
                                </div>
                                <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                    <span>Créer un compte</span></h6>
                            </div>
                            <div className="card-content">
                                <div className="card-body">
                                    {step === 'STEP_1' ?
                                        <RegisterForm /> :
                                        <p>{message}</p>
                                    }
                                </div>
                                <p className="text-center">Vous avez déjà un compte ? <Link to="/"
                                    className="card-link">Connectez-vous</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div> :
            {redirect}
    )

};

export default Register;
