import {connect} from 'react-redux';
import {changeCalculNameForm, changeProjectName} from "../actions";
import FdesInformations from "../components/FdesInformations";

export default connect(
    (state) => ({
        calculName: state.fdes.calculName,
        projectName: state.fdes.projectName,
    }),
    (dispatch) => ({
        onChangeCalculName: (value) => {
            dispatch(changeCalculNameForm(value))
        },
        onChangeProjectName: (value) => {
            dispatch(changeProjectName(value))
        },
    })

)(FdesInformations);