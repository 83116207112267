import {connect} from 'react-redux';
import FamilyProductEdit from '../pages/FamilyProductEdit';
import {loadModelForFamilyProduct} from "../actions";

export default connect(
    (state) => ({
        familyProducts: state.familyProduct.familyProducts
    }),
    (dispatch) => ({
        loadModelForFamilyProduct: (familyProductId) => {
            dispatch(loadModelForFamilyProduct(familyProductId))
        }
    })

)(FamilyProductEdit);