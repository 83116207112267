import produce from "immer";
import { SEND_EMAIL_SUCCESS, RESET_CONTACT_PAGE } from "../constants";

const initialState = {
    message: '',
}

const contextReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case SEND_EMAIL_SUCCESS:
            draft.message = "Votre message a bien été envoyé, nous vous recontacterons au plus vite"
            break;
        case RESET_CONTACT_PAGE:
            draft.message = ""
            break;
        default:
            return draft;
    }
});


export default contextReducer;