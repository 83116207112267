import { 
    GET_USERS_SEND, 
    GET_USERS_SUCCESS, 
    USER_FORM_SEND, 
    UPDATE_USER_FORM, 
    USER_FORM_DELETE, 
    UPDATE_ACCOUNT_SETTING_FORM, 
    UPDATE_PASSWORD_FORM,
    UPDATE_USER_EDEC_INTEGRATION
} from "../constants";

export function getUsersSend() {
    return {
        type: GET_USERS_SEND
    };
}

export function getUsersSuccess(payload) {
    return {
        type: GET_USERS_SUCCESS, payload
    };
}

export function userFormSend(values) {
    return {
        type: USER_FORM_SEND, values
    };
}

export function updateUserForm(values) {
    return {
        type: UPDATE_USER_FORM, values
    };
}

export function deleteUser(userId) {
    return {
        type: USER_FORM_DELETE, userId
    };
}
export function updateAccountSettingForm(values) {
    return {
        type: UPDATE_ACCOUNT_SETTING_FORM, values
    };
}
export function updatePasswordForm(values) {
    return {
        type: UPDATE_PASSWORD_FORM, values
    };
}

export function updateUserEDECIntegration(values) {
    return {
        type: UPDATE_USER_EDEC_INTEGRATION, values
    }
}