import {connect} from 'react-redux';
import FamilyProductView from '../components/FamilyProductView';
import {getAvailableFamilyProductForConfigurator} from "../selectors";
import {getAggregatedFamilyProducts} from '../actions'

export default connect(
    (state) => ({
        familyProducts: state.familyProduct.familyProducts,
        localConfigurator: state.configurator.localConfigurator,
        familyProductsFilters: getAvailableFamilyProductForConfigurator(state),
        aggregatedFamilyProducts: state.familyProduct.aggregatedFamilyProducts
    }),
    (dispatch) => ({
        getAggregatedFamilyProducts: (values) => {
            dispatch(getAggregatedFamilyProducts(values))
        },
    })
)(FamilyProductView);