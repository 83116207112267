import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userHasPermission} from "../modules/user/constants/permissions";
import {loginRequired, permissionRefused} from "../common/actions/user";
import {ANONYMOUS_MODE_DISABLED, ANONYMOUS_MODE_ONLY_HOMEPAGE} from "../utils/transformer";
import {history} from '../store';

export const withPermissions = (WrappedComponent, permissionRequired) => {
    class HOComponent extends Component {

        userHasPermission = () => {
            const {userInformations} = this.props;
            
            if (!permissionRequired) {
                return true;
            }
            return userHasPermission(userInformations.permissions, permissionRequired);
        };

        render () {
            const {isAuthenticated, loginRequired, permissionRefused, localConfigurator, location} = this.props;
            const anonymousMode = localConfigurator?.security?.accessControl?.anonymousMode;

            if (!isAuthenticated && anonymousMode === ANONYMOUS_MODE_DISABLED) {
                loginRequired();
                return history.push('/login')
            }

            if (location.pathname !== "/") {
                if (!isAuthenticated && anonymousMode === ANONYMOUS_MODE_ONLY_HOMEPAGE) {
                    loginRequired();
                    return history.push('/')
                }

                if (!this.userHasPermission()) {
                    permissionRefused();
                    return history.push('/')
                }
            }
            return (
                <React.Fragment>
                    <WrappedComponent {...this.props} />
                </React.Fragment>
            );
        }
    }
    const mapStateToProps = state => {
        return {
            location: state.router.location,
            isAuthenticated: state.user.isAuthenticated,
            userInformations: state.user.informations,
            localConfigurator: state.configurator.localConfigurator
        };
    };
    const mapDispatchToProps = dispatch => {
        return {
            loginRequired: () => {
                dispatch(loginRequired());
            },
            permissionRefused: () => {
                dispatch(permissionRefused())
            }
        };
    };
    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(HOComponent);
};




