import React from 'react';
import './ThermoGaugeChart.scss';
import ExposantValue from "./ExposantValue";
import ReactTooltip from "react-tooltip";
import {formatToCalculatedNumber} from "../../../utils/transformer";

const ThermoGaugeChart = ({value, measure, measurements, helpText}) => {
    const valueJauged = value > formatToCalculatedNumber(measurements.MAX) ? formatToCalculatedNumber(measurements.MAX) : value < formatToCalculatedNumber(measurements.MIN) ? formatToCalculatedNumber(measurements.MIN) : value;
    const topPourcent = ((formatToCalculatedNumber(measurements.MAX) - formatToCalculatedNumber(measurements.AVG)) / (formatToCalculatedNumber(measurements.MAX) - formatToCalculatedNumber(measurements.MIN))) *100;
    const barPourcent = ((formatToCalculatedNumber(measurements.MAX) - formatToCalculatedNumber(valueJauged)) / (formatToCalculatedNumber(measurements.MAX) - formatToCalculatedNumber(measurements.MIN))) *100;

    return (
        <div className="thermometer">
            <div className="colorBlocks">
                <div className="red" style={{flex:`1 1 ${topPourcent/3}%`}}/>
                <div className="orange" style={{flex:`1 1 ${(topPourcent/3)*2}%`}}/>
                <div className="green" style={{flex:`1 1 ${100 - topPourcent}%`}}/>
                <p className="bar" style={{bottom:`${100 - barPourcent}%`}}>
                    <span className="bar-content">
                        <span className="value"><ExposantValue value={value} forceLiteral /></span>
                        <span className="measure">{measure}</span>
                    </span>
                </p>
                <p className="jaugeMax"><ExposantValue value={measurements.MAX} forceLiteral/></p>
                <p className="jaugeMin"><ExposantValue value={measurements.MIN} forceLiteral/></p>
                <p className="jaugeAvg"  style={{bottom:`${100 - topPourcent}%`}}><ExposantValue value={measurements.AVG} forceLiteral/></p>
            </div>
            <div className="help-info">
                <i className="feather icon-info"
                    data-tip={helpText || "Le témoin ci-contre illustre la position relative de votre résultat par rapport aux valeurs couramment rencontrées pour cette famille de produits. La valeur centrale est celle de la FDES mère que vous êtes en train de personnaliser. Attention à la signification de cette position relative ! En termes d’indicateurs environnementaux, avoir un résultat inférieur à la moyenne est en général un signe positif, puisque vous avez moins d’impact sur l’environnement que la moyenne. Toutefois pour certains indicateurs c’est l’inverse, comme par exemple pour les indicateurs présentant l’utilisation de matières secondaires, l’export d'énergie, etc…"}
                    data-for="indicators"/>
                <ReactTooltip
                    id="indicators"
                    place="top"
                    type="info"
                    className="basic-tooltip"
                />
            </div>
        </div>
    )
};

export default ThermoGaugeChart;